
import axios from "axios"
import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"


export const onFetchApiKey = (data, setGoogleKeys) => (dispatch, getState) => {
    commonAxios("fetch-google-keys", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setGoogleKeys(res.data.data.api_key)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onDownloadYoutubeVideo = (data, setLoader) => async (dispatch, getState) => {
    try {
        let options = {
            headers: {
                'Authorization': getState().auth.token
            }
        }
        await axios.get(`https://backend.aisocials.io/download-youtube-video?url=https://www.youtube.com/watch?v=${data.id}`, options).then(res => {
            if (res.data.status === true) {
                window.location = 'https://backend.aisocials.io/download-file?url=' + res.data.url;
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        })
    } catch (err) {
        console.log(err)
        setLoader(false);
        dispatch(setAlert(err.message, 'danger'));
    }
}
import React from 'react'
import VolumeControl from './VolumeControl'
import { useSelector } from 'react-redux'
import Animation from './Animation'
import EditorSummerNote from './EditorSummerNote'

const Setting = () => {
  const campaign = useSelector(state => state.campaign.data)

  return (
    <>
      <Animation
        slideData={campaign.slides}
      />
      <VolumeControl
        campaign={campaign}
      />
      <EditorSummerNote
        promo={campaign.promo}
      />

    </>
  )
}

export default Setting
import React from 'react'

const PlacesCard = ({ curElem, index, handleScrapping, openWebsite, scrappingLoader }) => {
    let moreData = {
        title: curElem.title ? curElem.title : "",
        address: curElem.address ? curElem.address : "",
        category: curElem.category ? curElem.category : ""
    }
    return (
        <div className="col-12 col-lg-6 mb-3" >
            <div className="add-video-data">
                <div className="col-12 col-lg-2">
                    <div className="data_img mx-auto">
                        <img src={curElem.thumbnail} />
                    </div>
                </div>
                <div className="col-12 col-lg-7 text-center text-lg-start p-0">
                    <div className="Country_data">
                        <h4>{curElem.title}</h4>
                        {curElem.category !== "" ? <span>Category: {curElem.category}</span> : ""}
                        {curElem.rating !== "" ? <span>Rating: {curElem.rating}</span> : ""}
                        {curElem.address !== "" ? <span>Address: {curElem.address}</span> : ""}
                        {curElem.website !== "" ? <span>Website: {curElem.website}</span> : ""}
                    </div>
                </div>
                <div className="col-12 col-lg-3 text-center text-lg-start p-0">
                    {curElem.website ?
                        <div className="web_btn">
                            <button className="fbtn btn-block demoLink" style={{ background: scrappingLoader.enable ? "gray" : "" }} disabled={scrappingLoader.enable} onClick={(e) => openWebsite(e, curElem.website)}>Open Website</button>
                        </div>
                        : ""
                    }
                    <div className="web_btn">
                        <button className="fbtn btn-block demoLink" style={{ background: scrappingLoader.enable ? "gray" : "" }} disabled={scrappingLoader.enable} onClick={() => handleScrapping(curElem.website, curElem.mapLink, curElem.placeId, index, moreData)}>Select This</button>
                    </div>
                </div>
                {scrappingLoader.enable && scrappingLoader.index === index ?
                    <i className="fa fa-spinner fa-spin mx-1" style={{ fontSize: "32px", color: "#e0712c", textAlign: "center", position: "absolute" }} />
                    : ""
                }
            </div>
        </div>
    )
}

export default PlacesCard

import produce from "immer"
const initalState = {
    data: false
}

export const CampaignReducer = (state = initalState, action) => {
    switch (action.type) {


        case "SET_CAMPAIGN_DATA":
            return produce(state, (draft) => {
                let obj = { ...action.payload }
                draft.data = obj
                draft.data.undoRedo = []
                if ((obj.slides.findIndex(({ isSelected }) => +isSelected === 1)) === -1) {
                    obj.slides[0].isSelected = "1"
                    obj.slides[0].layers[0].isSelected = true
                    obj.slides[0].background.isSelected = 1
                }

                obj.slides.forEach((curElem) => {
                    draft.data.undoRedo.push({
                        slideId: curElem.id,
                        past: [],
                        present: curElem,
                        future: []
                    })

                    // curElem.layers.forEach((layer) => {
                    //     if (!layer.textToHighlight && layer.type === "text") {
                    //         layer.textToHighlight = []
                    //     }
                    //     if (layer.type === "video" && layer.mute === undefined) {
                    //         layer.mute = true
                    //     }
                    //     if (layer.type === "text") {
                    //         if (layer.style.lineHeight === "") {
                    //             layer.style.lineHeight = 1.4
                    //         }
                    //     }
                    // })
                })

                // draft.data = action.payload
            })

        case "CHANGE_CAMP_NAME":
            return produce(state, (draft) => {
                draft.data.campaign.name = action.payload
            })

        case "CHANGE_BG_AUDIO":
            return produce(state, (draft) => {
                draft.data.backgroundAudio.name = action.payload
            })

        case "SELECT_TEMPLATE":
            return produce(state, (draft) => {
                draft.data.templateId = action.payload
            })

        case "CHANGE_SLIDE_TEXT":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.index].src = action.payload.value
            })

        case "CHANGE_TEXT_COLOR":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.index].style.color = action.payload.value
            })

        case "CHANGE_THEME_COLOR":
            return produce(state, (draft) => {
                draft.data.theme = action.payload.hex
                action.payload.campaign.slides.forEach((ele, slideIndx) => {
                    ele.layers.forEach((layer, ind) => {
                        if (layer.type === "shape") {
                            draft.data.slides[slideIndx].layers[ind].style.background = action.payload.hex
                        }
                    })
                });
            })

        case "CHANGE_TEXT_FONT":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.index].style.fontFamily = action.payload.value
            })

        case "ON_TEXT_FONTSIZE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.index].style.fontSize = +action.payload.value
            })


        case "ON_TEXT_FONTSTYLE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.fontFamily = action.payload.data
            })
        case "ON_TEXT_FONTBOLD":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.index].style.fontWeight = action.payload.value
            })
        case "ON_TEXT_FONTITALIC":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.index].style.fontStyle = action.payload.value
            })
        case "ON_TEXT_FONTTRANSFORM":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.index].style.textTransform = action.payload.value
            })
        case "ON_TEXT_FONTDECO":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.index].style.textDecoration = action.payload.value
            })
        case "ON_TEXT_FONTALIGN":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.index].style.textAlign = action.payload.value
            })
        case "ON_TEXT_FONTLINEHEIGHT":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.index].style.lineHeight = action.payload.value
            })

        case "UPDATE_LAYER_TEXT":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src = action.payload.text
            })

        case "ADD_TEXT_LAYER":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers.forEach((layer) => {
                    layer.isSelected = false
                })
                draft.data.slides[action.payload.slideIndex].layers.push(action.payload.layer)
            })

        case "UPDATE_TEXT_FONTBGCOLOR":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.selectedSlideIndex].layers[action.payload.selectedTextIndex].style.background = action.payload.data
            })
        case "REMOVE_TEXT_FONTBGCOLOR":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.selectedSlideIndex].layers[action.payload.selectedTextIndex].style.background = ""
            })


        case "CHANGE_SLIDE_MEDIA":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.index].background.type = action.payload.data.type
                draft.data.slides[action.payload.index].background.src = action.payload.data.src
            })
        case "CHANGE_SLIDE_BG_AUDIO":
            return produce(state, (draft) => {
                draft.data.backgroundAudio.name = action.payload.name
                draft.data.backgroundAudio.source = action.payload.url
                draft.data.backgroundAudio.duration = action.payload.duration
            })

        case "APPLY_TO_ALL":
            return produce(state, (draft) => {
                draft.data.slides.forEach((slide) => {
                    slide.layers.forEach((layer) => {
                        if (layer.type === "text") {
                            layer.style = action.payload
                        }
                    })
                })
            })

        case 'UPDATE_STATUS_L&W':
            return produce(state, (draft) => {
                if (action.payload.type === 'logo') {
                    draft.data.logo.enable = action.payload.enable
                }
                else if (action.payload.type === 'watermark') {
                    draft.data.watermark.enable = action.payload.enable
                }
            })

        case 'ADD_LOGO':
            return produce(state, (draft) => {
                if (draft.data.logo.src === "") {
                    draft.data.logo.src = action.payload.data
                    let logoObj = {
                        id: parseInt(Math.random() * 1000000000000),
                        type: "logo",
                        layerType: "custom",
                        style: {
                            color: "#FFFFFF",
                            fontFamily: "Noto Sans",
                            background: "",
                            fontSize: "17",
                            textAlign: "left",
                            fontWeight: false,
                            fontStyle: false,
                            textTransform: "lowercase",
                            textDecoration: "none",
                            lineHeight: 1,
                            opacity: 1
                        },
                        position: {
                            left: 30,
                            top: 30
                        },
                        animation: {
                            name: "",
                            delay: "0.5s",
                            duration: "1s",
                            playAnimation: false
                        },
                        size: {
                            width: 100,
                            height: 70
                        },
                        isSelected: false,
                        src: action.payload.data,
                        meta: "",
                    }
                    draft.data.slides.forEach((curElem) => {
                        curElem.layers.push(logoObj)
                    })
                }
                else {
                    draft.data.logo.src = action.payload.data
                    draft.data.slides.forEach((curElem) => {
                        curElem.layers.forEach((curLayer) => {
                            if (curLayer.type === "logo") {
                                curLayer.src = action.payload.data
                            }
                        })
                    })
                }
            })

        case 'ADD_WATERMARK':
            return produce(state, (draft) => {
                draft.data.watermark.src = action.payload.data
                if (!draft.data.watermark.pos) {
                    draft.data.watermark.pos = "top-left"
                }
            })

        case 'REMOVE_LOGO_LAYER':
            return produce(state, (draft) => {
                draft.data.slides.forEach((curElem) => {
                    const logoIndex = curElem.layers.findIndex(({ type }) => type === "logo")
                    if (logoIndex !== -1) {
                        curElem.layers.splice(logoIndex, 1)
                    }
                })
                draft.data.logo.src = ""
            })

        case 'REMOVE_WATERMARK_LAYER':
            return produce(state, (draft) => {
                draft.data.watermark.src = ''
                draft.data.watermark.pos = 'top-left'
            })

        case 'UPDATE_LOGO_POSITION':
            return produce(state, (draft) => {
                draft.data.logo.pos = action.payload.data
            })

        case 'UPDATE_WATERMARK_POSITION':
            return produce(state, (draft) => {
                draft.data.watermark.pos = action.payload.data
            })

        case 'SET_INTRO_STATUS':
            return produce(state, (draft) => {
                draft.data.intro.enable = action.payload
            })

        case 'SET_OUTRO_STATUS':
            return produce(state, (draft) => {
                draft.data.outro.enable = action.payload
            })

        case 'REMOVE_INTRO':
            return produce(state, (draft) => {
                draft.data.intro.src = ""
                draft.data.intro.thumbnail = ""
                draft.data.intro.name = ""
                draft.data.intro.duration = 0
            })

        case 'REMOVE_OUTRO':
            return produce(state, (draft) => {
                draft.data.outro.src = ""
                draft.data.outro.thumbnail = ""
                draft.data.outro.name = ""
                draft.data.outro.duration = 0
            })

        case 'UPDATE_INTRO':
            return produce(state, (draft) => {
                draft.data.intro.src = action.payload.url
                draft.data.intro.thumbnail = action.payload.thumbData
                draft.data.intro.name = action.payload.nameData
                draft.data.intro.duration = action.payload.duration
            })


        case 'UPDATE_OUTRO':
            return produce(state, (draft) => {
                draft.data.outro.src = action.payload.url
                draft.data.outro.thumbnail = action.payload.thumbData
                draft.data.outro.name = action.payload.nameData
                draft.data.outro.duration = action.payload.duration
            })

        case 'CHANGE_BG_VOLUME':
            return produce(state, (draft) => {
                draft.data.backgroundAudio.volume = parseInt(action.payload)
            })

        case 'CHANGE_VOICE_VOLUME':
            return produce(state, (draft) => {
                draft.data.slides[action.payload.selectedSlideIndex].audio.volume = parseInt(action.payload.value)
            })

        case "UNSELECT_LAYERS":
            return produce(state, (draft) => {
                draft.data.slides[action.payload].layers.forEach((curElem) => {
                    curElem.isSelected = false
                })
            })

        case "END_ANIMATION":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.playAnimation = false
                draft.data.lastAction = "END_ANIMATION"
            })

        case "UPDATE_LOGO_HW":
            return produce(state, (draft) => {
                draft.data.logo.height = action.payload.height + "px"
                draft.data.logo.width = action.payload.width + "px"
                draft.data.lastAction = "UPDATE_LOGO_HW"
            })

        case "COPY_LAYER":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].isSelected = false
                if (!action.payload.layerData.layerType) {
                    action.payload.layerData = {
                        ...action.payload.layerData,
                        layerType: "custom"
                    }
                }
                draft.data.slides[action.payload.slideIndex].layers.push(action.payload.layerData)

            })

        case "UNDO":
            return produce(state, (draft) => {
                const slideIndex = draft.data.undoRedo.findIndex(({ slideId }) => slideId === action.payload)
                if (slideIndex !== -1 && draft.data.undoRedo[slideIndex].past.length > 0) {
                    let obj = draft.data.undoRedo[slideIndex].past.pop()
                    draft.data.undoRedo[slideIndex].future.push(draft.data.undoRedo[slideIndex].present)
                    draft.data.undoRedo[slideIndex].present = obj
                    let slideIndForSlide = draft.data.slides.findIndex(({ id }) => id === obj.id)
                    draft.data.slides[slideIndForSlide] = obj
                    draft.data.lastAction = "UNDO"
                }
            })

        case "REDO":
            return produce(state, (draft) => {
                const slideIndex = draft.data.undoRedo.findIndex(({ slideId }) => slideId === action.payload)
                if (slideIndex !== -1 && draft.data.undoRedo[slideIndex].future.length > 0) {
                    let obj = draft.data.undoRedo[slideIndex].future.pop()
                    draft.data.undoRedo[slideIndex].past.push(draft.data.undoRedo[slideIndex].present)
                    draft.data.undoRedo[slideIndex].present = obj
                    let slideIndForSlide = draft.data.slides.findIndex(({ id }) => id === obj.id)
                    draft.data.slides[slideIndForSlide] = obj
                    draft.data.lastAction = "REDO"
                }
            })

        case "REMOVE_LAYER":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers.splice(action.payload.layerIndex, 1)
                if (draft.data.slides[action.payload.slideIndex].layers.length > 0) {
                    draft.data.slides[action.payload.slideIndex].layers[0].isSelected = true
                }
            })

        case "UPDATE_LAST_ACTION":
            return produce(state, (draft) => {
                draft.data.lastAction = ""
            })


        case "SET_PRESENT":
            return produce(state, (draft) => {
                const slideIndex = draft.data.undoRedo.findIndex(({ slideId }) => slideId === action.payload.id)
                if (slideIndex !== -1) {
                    let temp = draft.data.undoRedo[slideIndex].present
                    temp.isSelected = "1"
                    draft.data.undoRedo[slideIndex].present = action.payload
                    draft.data.undoRedo[slideIndex].past.push(temp)
                    if (draft.data.undoRedo[slideIndex].future.length > 0) {
                        draft.data.undoRedo[slideIndex].future = []
                    }
                    draft.data.lastAction = ""
                }
                else {
                    draft.data.undoRedo.push({
                        slideId: action.payload.id,
                        past: [],
                        present: action.payload,
                        future: [],
                    })
                }
                draft.data.lastAction = ""
            })


        case "REPOSITION_LAYER":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].position = {
                    left: action.payload.l,
                    top: action.payload.t
                }
            })


        case "RESIZE_LAYER":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].size = {
                    width: action.payload.w,
                    height: action.payload.h
                }
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].position = {
                    left: action.payload.x,
                    top: action.payload.y
                }
            })

        case "ONPLAY_SLIDE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload].layers.forEach(curElem => {
                    curElem.animation.playAnimation = true
                })
                draft.data.slides[action.payload].isPlaying = true
                draft.data.lastAction = "ONPLAY_SLIDE"
            })

        case "STOP_PLAYING_SLIDE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload].isPlaying = false
            })

        case 'UPDATE_SELECTED_TILES':
            return produce(state, (draft) => {
                let index = draft.data.slides.findIndex(({ isSelected }) => isSelected === "1");
                draft.data.slides[index].isSelected = "0";
                draft.data.slides[action.payload].isSelected = "1";
            })

        case "UPDATE_TEXT_HEIGHT":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].size.height = action.payload.height
            })

        case "UPDATE_WATERMARK_HW":
            return produce(state, (draft) => {
                draft.data.watermark.height = action.payload.height + "px"
                draft.data.watermark.width = action.payload.width + "px"
                draft.data.lastAction = "UPDATE_WATERMARK_HW"
            })

        case "UPDATE_LAYER":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers.forEach((curElem, index) => {
                    if (index === action.payload.index) {
                        curElem.isSelected = true
                    } else {
                        curElem.isSelected = false
                    }
                })
            })

        case "LAYER_DOWN":
            return produce(state, (draft) => {
                let temp = draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex]
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex] = draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex + 1]
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex + 1] = temp
            })

        case "LAYER_UP":
            return produce(state, (draft) => {
                let temp = draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex]
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex] = draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex - 1]
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex - 1] = temp
            })

        case "REARRANGE TILES":
            return produce(state, (draft) => {
                draft.data.slides = action.payload
            })

        case "ADD_NEW_SLIDE":
            return produce(state, (draft) => {
                let data = { ...action.payload }
                // draft.data.audio = JSON.parse(data.audio)
                // draft.data.background = JSON.parse(data.background)
                // draft.data.layers = JSON.parse(data.layers)
                // data.videoTiles = JSON.parse(data.videoTiles)
                draft.data.slides.push(data)
            })

        case 'UPDATE_SLIDES_DATA':
            return produce(state, (draft) => {
                draft.data.lastAction = "UPDATE_SLIDES_DATA"
                draft.data.slides[action.payload.index].isSelected = "1"
                draft.data.slides[action.payload.slideIndex].isSelected = "0"
                if (draft.data.slides[action.payload.index].layers) {
                    draft.data.slides[action.payload.index].layers.forEach((curElem) => {
                        curElem.animation.playAnimation = true
                    })
                }

            })

        case "DELETE_SLIDE":
            return produce(state, (draft) => {
                if (draft.data.slides[action.payload].isSelected === "0") {
                    draft.data.slides.splice(action.payload, 1)
                } else {
                    if (action.payload === 0) {
                        draft.data.slides[action.payload + 1].isSelected = "1"
                        draft.data.slides.splice(action.payload, 1)
                    }
                    else {
                        draft.data.slides[action.payload - 1].isSelected = "1"
                        draft.data.slides.splice(action.payload, 1)
                    }
                }
            })

        case 'UPDATE_BG_IMAGE':
            return produce(state, (draft) => {
                draft.data.slides[action.payload.index].background[0].type = "image"
                draft.data.slides[action.payload.index].background[0].src = action.payload.file.url
                draft.data.slides[action.payload.index].background[0].thumbnail = action.payload.file.thumbnail ? action.payload.file.thumbnail : action.payload.file.url
            })

        case 'UPDATE_BG_VIDEO':
            return produce(state, (draft) => {

                draft.data.slides[action.payload.index].background[0].type = "video"
                draft.data.slides[action.payload.index].background[0].src = action.payload.file.url
                draft.data.slides[action.payload.index].background[0].thumbnail = action.payload.file.thumbnail ? action.payload.file.thumbnail : action.payload.file.poster
                draft.data.slides[action.payload.index].background[0].bgDuration = action.payload.file.bgDuration
            })

        case 'UPDATE_BG_COLOR':
            return produce(state, (draft) => {
                draft.data.slides[action.payload.index].background[0].type = "color"
                draft.data.slides[action.payload.index].background[0].src = action.payload.file
                draft.data.slides[action.payload.index].background[0].thumbnail = action.payload.file
            })

        case "UPDATE_ANIMTION":
            return produce(state, (draft) => {
                if (action.payload.name === "animation") {
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.name = action.payload.val
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.playAnimation = true
                } else if (action.payload.name === "delay") {
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.delay = action.payload.val
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.playAnimation = true
                } else if (action.payload.name === "duration") {
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.duration = action.payload.val
                    draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.playAnimation = true
                }
            })

        case "REMOVE_TRANSITION":
            return produce(state, (draft) => {
                draft.data.slides[action.payload].transitionName = ""
            })

        case "ADD_TRANSITION":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.index].transitionName = action.payload.name
            })

        case "ADD_MEDIA_LAYER":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers.forEach((curElem) => {
                    curElem.isSelected = false
                })
                draft.data.slides[action.payload.slideIndex].layers.push(action.payload.objData)

            })

        case "UPDATE_LAYER_IMAGE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src = action.payload.val
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].type = "image"
            })

        case "UPDATE_LAYER_VIDEO":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src = action.payload.val.url
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].type = "video"
                draft.data.slides[action.payload.slideIndex].layers[action.payload.layerIndex].mute = true
            })

        case "SET_LANG_CODE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].audio.languageCode = action.payload.langCode
            })

        case "SET_VOICE_CODE":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].audio.voiceId = action.payload.vCode
                draft.data.slides[action.payload.slideIndex].audio.engine = action.payload.vType
                draft.data.slides[action.payload.slideIndex].audio.vId = action.payload.vId
            })
        case "SET_TTS_TEXT":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].audio.text = action.payload.text
            })

        case 'UPDATE_GENERATED_TTS':
            return produce(state, (draft) => {
                draft.data.slides[action.payload.index].audio.enable = true
                draft.data.slides[action.payload.index].audio.duration = action.payload.val.duration
                draft.data.slides[action.payload.index].audio.src = action.payload.val.url
            })

        case 'REMOVE_TTSAUDIO_SLIDES':
            return produce(state, (draft) => {
                draft.data.slides[action.payload].audio.enable = false
                draft.data.slides[action.payload].audio.duration = 0
                draft.data.slides[action.payload].audio.src = ''
            })

        case 'UPDATE_SLIDE_DURATION':
            return produce(state, (draft) => {
                const selectedTile = draft.data.slides.findIndex(({ isSelected }) => isSelected === "1")
                draft.data.slides[selectedTile].duration = parseInt(action.payload)
            })

        case 'REMOVE_BGAUDIO_SLIDES':
            return produce(state, (draft) => {
                draft.data.backgroundAudio = {
                    ...draft.data.backgroundAudio,
                    enable: false,
                    type: "",
                    source: "",
                    duration: 0
                }
            })

        case "ON_SELECT_INNERBACK":
            return produce(state, (draft) => {
                let selectedBack = draft.data.slides[action.payload.slideIndex].background.findIndex(({ isSelected }) => +isSelected === 1)
                if (selectedBack !== -1) {
                    draft.data.slides[action.payload.slideIndex].background[selectedBack].isSelected = 0
                    draft.data.slides[action.payload.slideIndex].background[action.payload.backgroundIndex].isSelected = 1
                } else {
                    draft.data.slides[action.payload.slideIndex].background[action.payload.backgroundIndex].isSelected = 1
                }
            })

        case "CHANGE_VARIATION_DATA":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex] = action.payload.obj
            })


        case "CHANGE_BG_INTERVAL":
            return produce(state, (draft) => {
                let background = draft.data.slides[action.payload].background
                let bgSelectedIndex = background.findIndex(({ isSelected }) => +isSelected === 1)

                bgSelectedIndex = bgSelectedIndex === -1 ? 0 : bgSelectedIndex
                background[bgSelectedIndex].isSelected = 0
                if (bgSelectedIndex === background.length - 1) {
                    background[0].isSelected = 1
                } else {
                    background[bgSelectedIndex + 1].isSelected = 1
                }
            })

        case "UPDATE_POSITION_PROMO":
            return produce(state, (draft) => {
                draft.data.promo.position = action.payload
            })

        case "ENABLE_PROMO":
            return produce(state, (draft) => {
                draft.data.promo.enable = action.payload
            })

        case "UPDATE_CTA_PROMO":
            return produce(state, (draft) => {
                draft.data.promo.data = action.payload
            })

        case "ADD_TTS_IN_ALL":
            return produce(state, (draft) => {
                draft.data.slides[action.payload.slideIndex].audio.enable = true
                draft.data.slides[action.payload.slideIndex].audio.type = "mp3"
                draft.data.slides[action.payload.slideIndex].audio.duration = action.payload.duration
                draft.data.slides[action.payload.slideIndex].audio.src = action.payload.url
                draft.data.slides[action.payload.slideIndex].audio.languageCode = action.payload.ttsData.language_id
                draft.data.slides[action.payload.slideIndex].audio.voiceId = action.payload.ttsData.voice_id
                draft.data.slides[action.payload.slideIndex].audio.engine = action.payload.ttsData.engine

                if (draft.data.slides[action.payload.slideIndex].duration < action.payload.duration) {
                    draft.data.slides[action.payload.slideIndex].duration = action.payload.duration
                }
            })
        default:
            return state
    }
}


import React, { useMemo, useState } from 'react'
import { AbsoluteFill, Video, Img, Sequence } from 'remotion';
import 'animate.css/animate.min.css';
import { Animation } from 'remotion-animation';

const VideoLayer = ({ index, src, layer, multiplyNum }) => {

    const increaseSize = (size) => {
        if (size) {
            let matches = size.match(/(\d+)/);
            let actualSize = parseInt(matches[0]) * multiplyNum;
            return `${actualSize}px`
        }
    }

    return (
        <Sequence from={Number(layer.animation.delay.toString().replace("s", "")) * 30 + 30}>
            <AbsoluteFill>
                <Animation style={{ position: "absolute", left: layer.position.left * multiplyNum, top: layer.position.top * multiplyNum }} duration={Number(layer.animation.duration.toString().replace("s", ""))} animateName={layer.animation.name.replace("animate__", "")} >
                    {layer.type === "video" ?
                        < Video
                            style={{ objectFit: "contain", height: layer.size.height * multiplyNum, width: layer.size.width * multiplyNum }}
                            src={src}
                            // startFrom={0}
                            // endAt={duration}
                            volume={1}
                            playbackRate={2} //speed
                            quality="draft"
                            muted={true}
                            loop={true}
                            onError={(event) => {
                                // Handle image loading error here
                                console.log("error")
                            }}
                        />
                        :
                        <Img
                            style={{
                                height: layer.size.height * multiplyNum,
                                width: layer.size.width * multiplyNum,
                                borderRadius: `${layer.style.borderRadius?.includes("%") ? layer.style.borderRadius : increaseSize(layer.style.borderRadius)}`,
                                border: `${layer.style.border}`,
                                borderWidth: `${increaseSize(layer.style.border)}`,
                            }}
                            src={src}
                            onError={(event) => {
                                // Handle image loading error here
                                console.log("error")
                            }}
                        />
                    }
                </Animation>
            </AbsoluteFill >
        </Sequence>
    )
}

export default VideoLayer

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { onEnableCtaData, onUpdateCtaPos } from '../../../../Actions/CampaignAction'
import SummerNote from './SummerNote'

const EditorSummerNote = ({ promo }) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        promo: {
            position: "",
            data: "",
            enable: false
        }
    })


    const handleEnable = (e) => {
        setState({
            ...state,
            promo: {
                ...state.promo,
                enable: e.target.checked
            }
        })
        dispatch(onEnableCtaData(e.target.checked))
    }

    const handlePos = (val) => {
        setState({
            ...state,
            promo: {
                ...state.promo,
                position: val
            }
        })
        dispatch(onUpdateCtaPos(val))
    }

    useEffect(() => {
        if (promo) {
            setState({
                ...state,
                promo: promo
            })
        }
    }, [promo])

    return (
        <div className="add-block-single">
            <div className="add-block-main">
                <div className="add-block-title  mb-1">
                    <h2>CTA</h2>
                    <div className="switch-single">
                        <label className="switch" htmlFor="cta">
                            <input
                                type="checkbox"
                                checked={state.promo.enable}
                                id="cta"
                                onChange={(e) => handleEnable(e)}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                {state.promo.enable ?
                    <>
                        <hr className='mb-1 p-0'/>

                        <p className='my-2' style={{fontSize : 15, color : "#fff"}}>CTA Text</p>
                        <div style={{ color: "#000000" }}>

                            <SummerNote
                                state={state}
                                setState={setState}
                                type="inEditor"
                            />
                             <p className='my-2' style={{fontSize : 15, color : "#fff"}}>CTA Positions</p>
                            <div className="itemDisp-right mt-2">

                                <select
                                    onChange={(e) => handlePos(e.target.value)}
                                    className='cursor-pointer'
                                    value={state.promo.position}
                                    style={{ width: "100%" }}
                                >
                                    <option value={'top-left'}>Top Left</option>
                                    <option value={'top-center'}>Top Center</option>
                                    <option value={'top-right'}>Top Right</option>
                                    <option value={'bottom-left'}>Bottom Left</option>
                                    <option value={'bottom-center'}>Bottom Center</option>
                                    <option value={'bottom-right'}>Bottom Right</option>
                                </select>
                            </div>
                        </div>
                    </>
                    : ''}
            </div>
        </div>
    )
}

export default EditorSummerNote
import React, { useState } from 'react'
import { onUploadUserData } from '../../../../../Actions/EditorAction'
import { useDispatch } from 'react-redux'
import Swal from "sweetalert2";
import AudioPlayer from './AudioPlayer';
import { onDeleteMedia } from '../../../../../Actions/CampaignAction';

const YourAudio = ({ currentUrl, setCurrentUrl, userAudio, handleChangeBgAudio, fetchUserAudio }) => {
    const dispatch = useDispatch()
    const [uploadLoader, setUploadLoader] = useState(false)
    let allowedExt = ['audio/mpeg']
    const [play, setPlay] = useState(false);


    const removeMedia = (id, Swal) => {
        let formData = new FormData();
        formData.append('id', id);
        dispatch(onDeleteMedia(formData, fetchUserAudio, Swal))
    }



    const onInputFile = (e) => {
        let allowedSize = 5000000
        if (allowedExt.includes(e.target.files[0].type)) {
            if (e.target.files[0].size < allowedSize) {
                setUploadLoader(true)
                const formData = new FormData()
                formData.append('upload_type', "music")
                formData.append('file', e.target.files[0])
                dispatch(onUploadUserData(formData, setUploadLoader, fetchUserAudio))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max allowed size for Music File is 5MB',
                    confirmButtonColor: "#e0712c"
                })
            }
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have Selected Invalid File Type',
                confirmButtonColor: "#e0712c"
            })
        }
    }



    return (
        <div className="tab-outer Your_audio_wrap">
            <div className="drag-area">

                {uploadLoader ? <i className="fa fa-spinner fa-spin" style={{ color: "#e0712c", fontSize: "34px" }} /> :
                    <>
                        <i class="fa fa-upload" aria-hidden="true"></i>
                        <p>Drag Audio Files Here</p>
                        <input
                            type="file"
                            accept='audio/*'
                            onChange={(e) => onInputFile(e)}
                        />
                    </>}
            </div>

            <div className="audio-library" style={{ height: 400 }}>
                <ul>
                    {userAudio.length > 0 ?
                        userAudio.map((curElem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <AudioPlayer
                                        play={play}
                                        setPlay={setPlay}
                                        url={curElem.url}
                                        title={curElem.name}
                                        currentUrl={currentUrl}
                                        setCurrentUrl={setCurrentUrl}
                                        handleChangeAudio={handleChangeBgAudio}
                                        type="upload"
                                        id={curElem.id}
                                        removeMedia={removeMedia}
                                    />
                                </React.Fragment>
                            )
                        })
                        : ""
                    }
                </ul>
            </div>
        </div>
    )
}

export default YourAudio

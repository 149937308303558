import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTempVarient, onUpdateTemplate, onVariationChange } from '../../../../Actions/CampaignAction';
import { useEffect } from 'react';
import { onFetchTemplate } from '../../../../Actions/EditorAction';
import { useNavigate } from 'react-router-dom';

const Template = ({ selectedTemp, campaign, setLoader, setPreLoader }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const [tempLoader, setTempLoader] = useState(false);
    const [varLoader, setVarLoader] = useState(false);
    const [template, setTemplate] = useState([]);
    const [variation, setVariation] = useState([])
    const [state, setState] = useState({
        selectdId: -1,
    })
    const [presetLoader, setPresetLoader] = useState({
        loader: false,
        index: -1
    })
    const [variationPresetLoader, setVariationPresetLoader] = useState({
        loader: false,
        index: -1
    })

    const handleSelectTemplate = (id, index) => {
        if (campaign.templateId !== id) {
            let data = {
                videoId: campaign.id,
                newTemplateId: id,
            }
            setPresetLoader({
                ...presetLoader,
                index: index,
                loader: true
            })
            dispatch(onUpdateTemplate(data, navigate, setLoader, campaign.cId, presetLoader, setPresetLoader))
        }
    }

    const handleSelectTemplateVar = (id, index) => {
        if (selectedSlide.variationId !== id) {
            setState({
                ...state,
                selectdId: id
            })
            let data = {
                slideId: selectedSlide.id,
                newVariationId: id
            }
            setVariationPresetLoader({
                ...variationPresetLoader,
                index: index,
                loader: true
            })
            dispatch(onVariationChange(data, selectedSlideIndex, setPreLoader, variationPresetLoader, setVariationPresetLoader))
        }
    }

    const fetchTemplate = () => {
        setTempLoader(true)
        dispatch(onFetchTemplate(setTemplate, setTempLoader))
    }

    const onGetVariation = () => {
        setVarLoader(true)
        dispatch(getTempVarient(selectedTemp, setVariation, setVarLoader))
    }

    useEffect(() => {
        onGetVariation()
    }, [selectedTemp])

    useEffect(() => {
        fetchTemplate();
    }, [])

    return (
        <div className="innertab-scroll">
            <div className="mediaList my-3" >
                <h6 className='mb-2'>Template</h6>
                <ul>
                    {template.length > 0 ?
                        template.map((curElem, index) => {
                            {/* uncomment for dynamic editor */ }
                            let imageData = campaign.campaign.emotion.dimention === "9:16" ? curElem.perviews_long : campaign.campaign.emotion.dimention === "16:9" ? curElem.previewImage : curElem.previewImage
                            return (
                                <li key={index} className='cursor-pointer' onClick={() => handleSelectTemplate(curElem.id, index)} >
                                    <div className="mediaList-single" style={{ height: 109 }}>
                                        <img className="mediaImg" src={imageData} alt={curElem.name} style={{ overflow: "cover" }} />
                                        {selectedTemp === curElem.id ?
                                            <div className="slide-active">
                                                <i className="fa fa-check" aria-hidden="true"></i>
                                            </div>
                                            : ''
                                        }
                                        {presetLoader.loader && presetLoader.index === index ?
                                            <div className='text-center' style={{ height: "100%", width: "100%", position: "absolute", top: 0, display: "flex", justifyContent: 'center', alignItems: "center" }}>
                                                <i className='fa fa-spinner fa-spin' style={{ fontSize: "26px", color: "#ff6f00", }} />
                                            </div>
                                            : ""}
                                    </div>
                                    <p style={{ color: "white", textTransform: "capitalize", textAlign: "center" }}>{curElem.name}</p>
                                </li>
                            )
                        })
                        : ""
                    }
                </ul>
                {

                    tempLoader ?
                        <div className='text-center'>
                            <i className="fa fa-spinner fa-spin" style={{ color: "#e0712c", fontSize: 25 }} />
                        </div>
                        : ""
                }
            </div>

            <div className="mediaList my-3" >
                <h6 className='mb-2'>Template Variation</h6>
                <ul>
                    {variation.length > 0 ?
                        variation.filter((obj) => {
                            {/* uncomment for dynamic editor */ }
                            let dimension = campaign.campaign.emotion.dimention === "9:16" ? "1080X1920" : campaign.campaign.emotion.dimention === "16:9" ? "1920X1080" : "1920X1080"
                            return dimension === obj.dimention
                        }).map((curElem, index) => {
                            return (
                                <li key={index} className='cursor-pointer' onClick={() => handleSelectTemplateVar(curElem.id, index)} >
                                    <div className="mediaList-single" style={{ height: 109 }}>
                                        <img className="mediaImg" src={curElem.previewImage} alt={curElem.name} style={{ overflow: "cover" }} />
                                        {curElem.id === selectedSlide.variationId ?
                                            <div className="slide-active">
                                                <i className="fa fa-check" aria-hidden="true"></i>
                                            </div>
                                            : ''
                                        }
                                        {variationPresetLoader.loader && variationPresetLoader.index === index ?
                                            <div className='text-center' style={{ height: "100%", width: "100%", position: "absolute", top: 0, display: "flex", justifyContent: 'center', alignItems: "center" }}>
                                                <i className='fa fa-spinner fa-spin' style={{ fontSize: "26px", color: "#ff6f00", }} />
                                            </div>
                                            : ""}
                                    </div>
                                    <p style={{ color: "white", textTransform: "capitalize", textAlign: "center" }}>{curElem.name}</p>
                                </li>
                            )
                        })
                        : ""
                    }
                </ul>
                {

                    varLoader ?
                        <div className='text-center'>
                            <i className="fa fa-spinner fa-spin" style={{ color: "#e0712c", fontSize: 25 }} />
                        </div>
                        : ""
                }
            </div>
        </div>
    )
}

export default Template

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { onFetchAnim, updateAnimation } from '../../../../Actions/CampaignAction';

const Animation = ({ slideData }) => {

    const dispatch = useDispatch()
    const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === '1')
    const selectedSlide = slideData.find(({ isSelected }) => isSelected === '1')

    const selectedLayer = selectedSlide.layers.find(({ isSelected }) => isSelected === true)
    const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)

    const [anim, setAnim] = useState([])
    const [state, setState] = useState({
        delay: 0,
        duration: 0.5
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        if (selectedLayerIndex !== -1) {
            if (name === "animation") {
                dispatch(updateAnimation(value, selectedSlideIndex, selectedLayerIndex, name))
            } else {
                if (value === "") {
                    dispatch(updateAnimation("0s", selectedSlideIndex, selectedLayerIndex, name))
                } else {
                    dispatch(updateAnimation(value + "s", selectedSlideIndex, selectedLayerIndex, name))
                }

            }
        }
    }

    const fetchAnimation = () => {
        dispatch(onFetchAnim(setAnim))
    }

    useEffect(() => {
        fetchAnimation()
    }, [])

    useEffect(() => {
        if (selectedLayer) {
            setState({
                ...state,
                duration: typeof (selectedLayer.animation.duration) === "number" ? selectedLayer.animation.duration : selectedLayer.animation.duration.split("s")[0],
                delay: typeof (selectedLayer.animation.delay) === "number" ? selectedLayer.animation.delay : selectedLayer.animation.delay.split("s")[0]
            })
        }
    }, [selectedLayer])


    return (
        <div className="tabInner mt-2">
            <div className="media-block">
                <div className="add-block-single">
                    <h2>Animation</h2>
                    <div className="add-block-main">
                        <div className="motionlist">
                            <div className='motionlist-inp'>
                                <div className='mediaList mt-3'>

                                    <label>Animation Type</label>
                                    <select
                                        className='inpField'
                                        name='animation'
                                        onChange={(e) => handleChange(e)}
                                        value={selectedLayer ? selectedLayer.animation.name : ""}
                                    >
                                        <option value="">Select Animation</option>
                                        {anim.length > 0 ?
                                            anim.sort((a, b) => {
                                                if (a.name.replace("animate__", "") > b.name.replace("animate__", "")) {
                                                    return 1
                                                } else {
                                                    return -1
                                                }
                                            }).map((curElem, index) => {
                                                return (
                                                    <option className='text-capitalize' key={index} value={curElem.name}>{curElem.name.replace("animate__", "")}</option>
                                                )
                                            }) : ""
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='motionlist-inp mt-3'>
                                <label>Duration (s)</label>
                                <input
                                    name='duration'
                                    className='inpField'
                                    type="number"
                                    min={0.5}
                                    value={state.duration}
                                    step={0.5}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className='motionlist-inp mt-3'>
                                <label>Start Delay</label>
                                <input
                                    name='delay'
                                    className='inpField'
                                    type="number"
                                    min={0}
                                    value={state.delay}
                                    step={0.5}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Animation



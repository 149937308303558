import React, { useEffect, useState } from 'react'
import modalCloseIcon from "../../images/modal-close.png";
import { Modal } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { connectSocial } from '../../Actions/SocialAction';
import { useDispatch } from 'react-redux';

const SocialConnectModal = (props) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState(false)
    const [appDetails, setAppDetails] = useState({
        user_id: auth.user.id,
        app_name: props.type,
        email: '',
        password: ''
    });

    const onInputChange = (e) => {
        setAppDetails({ ...appDetails, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        setAppDetails({
            ...appDetails,
            app_name: props.type
        })
    }, [props.type])
    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(connectSocial(appDetails, setLoader, props.handleClose))
    }
    return (
        <Modal className="VideoModal Syvid_modal" show={props.show} centered >
            <div onClick={props.handleClose} className="vidClose">
                <img alt="" src={modalCloseIcon} />
            </div>
            <h5 className="modal-title mt-3 text-light" style={{ margin: "auto" }}>
                <img src={props.icon} style={{ width: 56 }} alt={props.name} className="mr-2" /> {`Connect ${props.name}`}
            </h5>
            <hr style={{ border: "1px solid #9b9595", opacity: 1 }} />
            <Modal.Body style={{ width: "100%" }}>
                <form className="add-account-box mt-2" method="post" onSubmit={(e) => onFormSubmit(e)} style={{ width: "100%", background: "#000" }}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <small className="form-text" style={{ color: "#fff" }}>Email Address</small>
                                <input type="email" className="form-control" placeholder="Enter Email"
                                    required name="email"
                                    onChange={(e) => onInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <small className="form-text " style={{ color: "#fff" }}>Password</small>
                                <input type="password" className="form-control"
                                    placeholder="********" required name="password"
                                    onChange={(e) => onInputChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mt-3 d-flex justify-content-center align-items-center ">
                            <div className="add-account-save-btn">
                                <button type="submit" className="btn-change7" disabled={loader}>
                                    {loader ? <>Connecting <i className="fa fa-spinner fa-spin mr-2" /> </> : "Connect"}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal >
    )
}

export default SocialConnectModal
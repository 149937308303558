import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Select from "react-select"
import Swal from 'sweetalert2'
import GoogleTrend from './GoogleTrend'
import RedditCard from './RedditCard'
import YoutubeCard from './YoutubeCard';

const Trends = () => {

    const auth = useSelector(state => state.auth);
    const baseURL = "https://backend.aisocials.io/"

    const [trendState, setTrendState] = useState({
        social: 'reddit',
        connection: null,
        type: 'relevance',
        keyword: null,
        location: null
    });

    const [youtubeLoader, setYoutubeLoader] = useState(false);
    const [redditResponse, setRedditResponse] = useState([]);
    const [youtubeResponse, setYoutubeResponse] = useState([]);
    const [googleResponse, setGoogleResponse] = useState([]);
    const [googleLocation, setGoogleLocations] = useState([]);
    const [loader, setLoader] = useState(false);
    const [googleLoc, setGoogleLoc] = useState('');

    const [redditToken, setRedditToken] = useState('');
    const [redditLoad, setRedditLoad] = useState(false);

    const [youtubeLoad, setYoutubeLoad] = useState(false);
    const [youtubeToken, setYoutubeToken] = useState('');

    const [commonLic, setCommonLic] = useState(false);
    const [timezone, setTimezone] = useState('-60');


    const onInputChange = (e) => {
        setTrendState({ ...trendState, [e.target.name]: e.target.value });

        if (e.target.name === "keyword") {
            setYoutubeResponse([]);
            setRedditResponse([]);
        }
    }

    const onInputTimezone = (e) => {
        setTimezone(e.target.value);
    }

    const onLocationChange = (e) => {
        setGoogleLoc(e);
        setTrendState({ ...trendState, location: e.value });
    }

    const onCheckCommon = (e) => {
        if (commonLic === true) {
            setCommonLic(false);
        } else {
            setCommonLic(true);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        if (trendState.social === 'reddit') {
            searchRedditSocial();
        }
        else if (trendState.social === 'youtube') {
            searchYoutubeSocial()
        }
        else {
            searchGoogleSocial();
        }
    }

    const searchGoogleSocial = async () => {
        if (trendState.location !== '') {
            let options = {
                headers: {
                    'Authorization': auth.token
                }
            }

            let data = {
                "country": trendState.location,
                "timezone": timezone
            }

            await axios.post(`${baseURL}google-trends`, data, options).then(res => {
                if (res.data.status === true) {
                    let arr = []
                    if (res.data.data.trendingSearchesDays.length > 0) {
                        res.data.data.trendingSearchesDays.forEach(ele => {
                            arr = [...arr, ...ele.trendingSearches];
                        });
                    }
                    setGoogleResponse(arr);
                } else {
                    setGoogleResponse([])
                }
                setLoader(false);
            })
        } else {
            setLoader(false);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select location first',
                confirmButtonColor: "#618FCA"
            })

        }
    }

    const searchRedditSocial = async () => {
        let response = await fetch(`https://www.reddit.com/search.json?q=${trendState.keyword}&sort=${trendState.type}&show=all`);

        let jsonData = await response.json();
        setRedditToken(jsonData.data.after);
        setRedditResponse(jsonData.data.children);
        setLoader(false);
    }

    const loadMoreReddit = async () => {
        setRedditLoad(true);
        let response = await fetch(`https://www.reddit.com/search.json?q=${trendState.keyword}&sort=${trendState.type}&show=all&after=${redditToken}`);

        let jsonData = await response.json();
        setRedditToken(jsonData.data.after);

        let arr = [...redditResponse];
        setRedditResponse(arr.concat(jsonData.data.children));
        setRedditLoad(false);
    }

    const downloadYoutube = async (id) => {
        setYoutubeLoader(true);
        let options = {
            headers: {
                'Authorization': auth.token
            }
        }
        await axios.get(`${baseURL}download-youtube-video?url=https://www.youtube.com/watch?v=${id}`, options).then(res => {
            if (res.data.status === true) {
                window.location = 'https://backend.agencyreel.io/download-file?url=' + res.data.url;
            }
            setYoutubeLoader(false);
        })
    }


    const searchYoutubeSocial = async () => {
        setYoutubeLoad(true);

        let options = {
            headers: {
                'Authorization': auth.token
            }
        }
        let data = {
            keyword: trendState.keyword,
            no_of_videos: 10,
            next_page_token: youtubeToken,
            license: commonLic
        }

        await axios.post(`${baseURL}search-youtube`, data, options).then(res => {
            if (res.data.status === true) {
                setYoutubeToken(res.data.data.next_page_token);
                let arr = [...youtubeResponse];

                setYoutubeResponse(arr.concat(res.data.data.videos));
            }
            setLoader(false);
            setYoutubeLoad(false);
        })
    }

    const sortYoutubeLike = () => {
        let sortData = [...youtubeResponse].sort((a, b) => b.stats.like - a.stats.like)

        setYoutubeResponse(sortData);
    }

    const sortYoutubeView = () => {
        let sortData = [...youtubeResponse].sort((a, b) => b.stats.views - a.stats.views)

        setYoutubeResponse(sortData);
    }

    const sortYoutubeComments = () => {
        let sortData = [...youtubeResponse].sort((a, b) => b.stats.comments - a.stats.comments)

        setYoutubeResponse(sortData);
    }

    const sortYoutubeDislike = () => {
        let sortData = [...youtubeResponse].sort((a, b) => b.stats.dislike - a.stats.dislike)

        setYoutubeResponse(sortData);
    }

    const searchGoogleLocation = async () => {
        let options = {
            headers: {
                'Authorization': auth.token
            }
        }

        await axios.post(`${baseURL}geo-locations`, {}, options).then(res => {
            if (res.data.status === true) {
                let tempArr = res.data.data.map((item, index) => {
                    return (
                        { label: item.name, value: item.code }
                    )
                })

                setGoogleLocations(tempArr);

            }
            setLoader(false);
        })
    }

    useEffect(() => {
        searchGoogleLocation();
    }, []);

    return (
        <>
            <div className='siteWrapnh'>
                <div className='tabTitle outreach d-flex align-content-center justify-content-between'>
                    <h3>Trends</h3>
                </div>

                <div className='tabepaneIn-main mt-3'>
                    <div className='tabepaneIn-cont'>
                        <div className='add-video-wrapnx'>
                            <div className="container">
                                <form action="" onSubmit={(e) => handleSubmit(e)}>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group form-group-alt">
                                                <label htmlFor="form-text text-muted">Select Social Platform</label>
                                                <select
                                                    class="form-select form-control"
                                                    aria-label="Default select example"
                                                    name="social" onChange={(e) => onInputChange(e)}
                                                    required
                                                >
                                                    <option selected value="reddit">Reddit</option>
                                                    <option value="youtube">Youtube</option>
                                                    <option value="google">Google</option>
                                                </select>
                                            </div>
                                        </div>
                                        {
                                            trendState.social === "reddit" ?
                                                <div className="col-lg-6">
                                                    <div className="form-group form-group-alt">
                                                        <div className="form-group">
                                                            <label htmlFor="form-text text-muted">Type</label>
                                                            <select className="form-control" id="exampleFormControlSelect1"
                                                                name="type" onChange={(e) => onInputChange(e)}
                                                                required={trendState.social === 'reddit' || trendState.social === 'google' ? true : false}
                                                            >
                                                                <option disabled>Select</option>
                                                                {
                                                                    trendState.social === 'reddit' || trendState.social === 'google' ?
                                                                        <>
                                                                            <option value="relevance">Relevance</option>
                                                                            <option value="hot">Hot</option>
                                                                            <option value="top">Top</option>
                                                                            <option value="new">New</option>
                                                                        </>
                                                                        : ''
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div> : ''
                                        }

                                        {
                                            trendState.social === 'google' ?
                                                <div className="col-lg-6">
                                                    <div className="form-group form-group-alt">
                                                        <div className="form-group">
                                                            <label htmlFor="form-text text-muted">Enter Location</label>
                                                            <Select
                                                                options={googleLocation}
                                                                value={googleLoc}
                                                                onChange={(value) => onLocationChange(value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                        {
                                            trendState.social !== "google" ?
                                                <div className="col-lg-6">
                                                    <div className="form-group form-group-alt">
                                                        <div className="form-group">
                                                            <label htmlFor="form-text text-muted">Enter A Keyword</label>
                                                            <input
                                                                type="text"
                                                                name="keyword"
                                                                className="form-control"
                                                                placeholder="Enter Keyword"
                                                                pattern="[a-z A-Z 0-9]*"
                                                                title="Input String Value"
                                                                onChange={(e) => onInputChange(e)}
                                                                required
                                                            >
                                                            </input>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                        {
                                            trendState.social === "googles" ?
                                                <div className="col-lg-6">
                                                    <div className="form-group form-group-alt">
                                                        <div className="form-group">
                                                            <label htmlFor="form-text text-muted">Timezone</label>
                                                            <select className="form-control" name="timezone"
                                                                onChange={(e) => onInputTimezone(e)}
                                                            >
                                                                <option value="-60">Last 1 Hour</option>
                                                                <option value="-180">Last 3 Hour</option>
                                                                <option value="-360">Last 6 Hour</option>
                                                                <option value="2-d">Last 1 Day</option>
                                                                <option value="3-d">Last 3 Day</option>
                                                                <option value="7-d">Last Week</option>
                                                                <option value="1-m">Last Month</option>
                                                                <option value="3-m">Last 3 Month</option>
                                                                <option value="6-m">Last 1 Month</option>
                                                                <option value="1-y">Last 1 Year</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                        {
                                            trendState.social === "youtube" ?
                                                <div className="col-lg-12">
                                                    <div className="form-groupv form-group-altv d-flex align-items-center pt-3">

                                                        <input className="form-check-input" style={{ margin: "0" }} name="send" type="checkbox" value="1" checked={commonLic} onChange={(e) => onCheckCommon(e)} />
                                                        <label style={{ margin: "0 0 0 25px" }}>Creative Common License</label>

                                                    </div>
                                                </div>
                                                : ''
                                        }
                                    </div>
                                    <button type="submit" className="fbtn btn-block demoLink">{loader ? <>Submitting<i className="fa fa-spinner fa-spin mx-1" /> </> : `Submit`}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='tabTitle outreach d-flex align-content-center justify-content-between pt-5'>
                    <h3>Trending Result</h3>
                </div>



                <div className='tabepaneIn-main mt-3'>
                    <div className='tabepaneIn-cont' style={{ paddingTop: "30px" }}>
                        <div className="projectTitle-wrapn">
                            <div className="containern">
                                {
                                    trendState.social === "youtube" ?
                                        youtubeResponse.length > 0 ?
                                            <div className="table-responsive">
                                                <table className="table businessTable youtube-businessTable">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ fontWeight: "400" }} >Youtube Videos</th>
                                                            <th></th>
                                                            <th style={{ fontWeight: "400", cursor: "pointer" }}
                                                                onClick={(e) => sortYoutubeView()} title="Most Views">View <i className="fa fa-sort"></i></th>
                                                            <th style={{ fontWeight: "400", cursor: "pointer" }}
                                                                onClick={(e) => sortYoutubeLike()} title="Most Likes">Like <i className="fa fa-sort"></i></th>
                                                            <th style={{ fontWeight: "400", cursor: "pointer" }}
                                                                onClick={(e) => sortYoutubeComments()} title="Most Comments">Comments <i className="fa fa-sort"></i></th>
                                                            <th style={{ fontWeight: "400", cursor: "pointer" }}
                                                                onClick={(e) => sortYoutubeDislike()} title="Most Dislikes">Dislike <i className="fa fa-sort"></i></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            youtubeResponse.length > 0 ?
                                                                youtubeResponse.map((curElem, index) => {
                                                                    return (
                                                                        <React.Fragment>
                                                                            <YoutubeCard curElem={curElem} />
                                                                        </React.Fragment>
                                                                    )
                                                                }) : ''
                                                        }
                                                    </tbody>
                                                </table>
                                                {
                                                    youtubeLoader === true ?
                                                        <div className="loaderbox">
                                                            <div className="loader"></div>
                                                        </div>
                                                        : ''
                                                }
                                                {
                                                    trendState.social === 'youtube' ?
                                                        youtubeResponse.length > 0 ?
                                                            youtubeToken !== '' ?
                                                                <button style={{ margin: "auto" }}
                                                                    disabled={youtubeLoad}
                                                                    onClick={(e) => searchYoutubeSocial()}
                                                                    className="btn-submit fbtn btn-block demoLink mt-4">
                                                                    {youtubeLoad ?
                                                                        <i className="fa fa-spinner fa-spin mr-2" /> : ''} Load
                                                                    more
                                                                </button>
                                                                : ''
                                                            : ''
                                                        : ''
                                                }
                                            </div>
                                            : ''
                                        : ''
                                }
                                {

                                    trendState.social === 'google' ?
                                        googleResponse.length > 0 ?
                                            <section className="py-5">
                                                <div className="container">
                                                    <div className="row">
                                                        {
                                                            googleResponse.map((curElem, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <GoogleTrend curElem={curElem} />
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </section>
                                            : ''
                                        : ''
                                }
                                {
                                    trendState.social === 'reddit' ?
                                        redditResponse.length > 0 ?
                                            <section className="py-5">
                                                <div className="container">
                                                    <div className="row">
                                                        {redditResponse.length > 0 ?
                                                            redditResponse.map((curElem, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <RedditCard
                                                                            curElem={curElem}
                                                                        />
                                                                    </React.Fragment>

                                                                )
                                                            })
                                                            : ""

                                                        }

                                                    </div>
                                                </div>
                                                {
                                                    trendState.social === 'reddit' ?
                                                        redditResponse.length > 0 ?
                                                            redditToken !== '' ?
                                                                <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "20px" }}>
                                                                    <button style={{ margin: "auto", color: "white", background: "linear-gradient(to right, #e0712c 0%, #e5a046 100%)", padding: "5px 15px", borderRadius: "20px", border: "none", fontWeight: "500" }}
                                                                        disabled={redditLoad}
                                                                        onClick={(e) => loadMoreReddit()}
                                                                        className="btn-submit">
                                                                        {redditLoad ?
                                                                            <i className="fa fa-spinner fa-spin mr-2" /> : ''} Load
                                                                        more
                                                                    </button>
                                                                </div>
                                                                : ''
                                                            : ''
                                                        : ''
                                                }
                                            </section>
                                            : ''
                                        : ''
                                }
                            </div>
                        </div>
                        <div className='fetch-loader-block mt-5 text-center'>
                            {
                                youtubeResponse.length === 0 && redditResponse.length === 0 && googleResponse.length === 0 ?
                                    < span style={{ fontSize: 20, color: "#00000" }}>
                                        "No Data Found"
                                    </span>
                                    :
                                    ""
                            }
                        </div>

                    </div>
                </div>
            </div >
        </>
    )
}

export default Trends
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import SweetAlertTwo from "../SweetAlertTwo";
import Swal from 'sweetalert2';
import { onDeleteOutreach } from "../../Actions/OutreachAction";

const SmtpClient = ({ row, index, handleShow, fetchOutreach }) => {
    const dispatch = useDispatch()
    const [info, setInfo] = useState({
        id: -1,
        enable: false
    })


    const handleDelete = (idToDete) => {
        setInfo({
            ...info,
            id: idToDete,
            enable: true
        })
    }

    const handleCancle = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }

    const onConfirm = () => {
        dispatch(onDeleteOutreach(info, fetchOutreach, Swal))
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }


    return (
        <>
            <tr>
                <td scope="row"><h6
                    className="text-wrapper  m-0">{index + 1}</h6></td>
                <td><h6 className="text-wrapper m-0">{row.name}</h6></td>
                <td><h6 className="text-wrapper m-0">{row.username}</h6></td>
                <td className="expand-button">
                    <span
                        className="action-icon delete-icon remove mb-1 mt-md-1 cursor-pointer mx-1"
                        title="Edit"
                        aria-expanded="true"
                        onClick={() => handleShow(row.id)}

                    >
                        <i className="fas fa-edit"></i>
                    </span>

                    <span
                        className="action-icon delete-icon remove mb-1 pl-xl-2  mt-md-1 cursor-pointer"
                        style={{ cursor: "pointer", color: "rgb(240, 83, 89)" }}
                        title="Delete"
                        onClick={() => handleDelete(row.id)}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </span>
                </td>
            </tr>

            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#b32f3d'
                cancelButtonColor='#2a2c3a'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={onConfirm}
                onCancel={handleCancle}
            />
        </>
    )

}
export default SmtpClient;
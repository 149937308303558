import React, { useEffect, useState } from 'react'
import { FaDownload, FaGlobe, FaTrash } from 'react-icons/fa'
import { useDispatch } from 'react-redux';
import { onDeleteMergeCampaign, onCheckMergeStatus } from '../../Actions/CampaignAction';
import SweetAlertTwo from '../SweetAlertTwo';
import defaultImage from '../../images/fav-new.png'
import "react-tooltip/dist/react-tooltip.css";
import { useSelector } from 'react-redux';
import PublishModal from '../Projects/PublishModal';

const MergeCard = ({ curElem, fetchData }) => {

    let rendCheck = false
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false)
    const [state, setState] = useState(false)

    const [info, setInfo] = useState({
        enable: false,
        id: -1
    })

    const handleCancel = () => {
        setInfo({
            ...info,
            enable: false,
            id: -1
        })
    }

    const handleDelete = () => {
        setInfo({
            ...info,
            enable: true
        })
    }

    const handlePerform = (Swal) => {
        let data = {
            id: curElem.id,
            user_id: auth.user.id
        }
        dispatch(onDeleteMergeCampaign(data, fetchData, Swal))
        setInfo({
            ...info,
            enable: false,
            id: -1
        })
    }

    const handleDownload = (url) => {
        let a = document.createElement('a')
        a.href = url
        a.download = "download.mp4"
        a.target = "_blank"
        a.click()
    }

    const checkStatus = () => {
        let data = {
            id: curElem.id,
        }
        dispatch(onCheckMergeStatus(data, setState, rendCheck, clearInterval, fetchData))

    }

    useEffect(() => {
        if (state === "Merging Videos" || state === "Downloading Files") {
            rendCheck = setInterval(() => {
                checkStatus()
            }, 5000)
        }
        return () => {
            clearInterval(rendCheck)
        }
    }, [state])


    useEffect(() => {
        setState(curElem.status)
    }, [curElem])

    return (
        <>
            <tr className="cursor-pointer">
                <td>
                    <span className="proSpace">
                        {curElem.preview_image ?
                            <img src={defaultImage} alt="" />
                            :
                            <video src={curElem.preview_image} />
                        }

                    </span>
                </td>
                <td>{curElem.name}</td>
                <td className="text-capitalize">{"Merge Video"}<div></div></td>

                <td className='text-center' >
                    {state !== "" ?
                        <span
                            className="stat text-left"
                            style={{
                                background:
                                    state === "Finished" ? "#05772b" :
                                        state === "Failed" ? "#e92437" :
                                            state === "Downloading Files" ? "#5d00ff" :
                                                state === "Merging Videos" ? "#a4a427" :
                                                    "3424",
                                display: "inline-flex", width: "auto", alignItems: "center"
                            }}
                            data-tooltip-id={`my-tooltip${curElem.id}`}
                        >
                            {
                                state === "Finished"
                                    ? "Render Successful" :
                                    state === "Failed"
                                        ? "Render Failed" :
                                        state === "Downloading Files" ?
                                            <>
                                                <div className="typing me-1" >
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                                Downloading Resources
                                            </>
                                            : state === "Merging Videos"
                                                ?
                                                <>
                                                    <div className="typing me-1" >
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                    Merging Videos
                                                </>
                                                : ""
                            }
                        </span>
                        : ""
                    }
                </td>
                <td>{curElem.created}</td>
                <td>
                    {
                        (state === "Finished" || state === "Failed") && (curElem.output_video && curElem.output_video !== "") ?
                            <>
                                <span className="roundBox" title='Download' onClick={() => handleDownload(curElem.output_video)} ><FaDownload /></span>
                                {+auth.user.is_client_account === 1 ? "" :
                                    <span className="roundBox" title='Publish' onClick={() => setShow(true)}><FaGlobe /></span>}
                            </>
                            : ""
                    }
                    {+auth.user.is_client_account === 1 ? "" :
                        <span className="roundBox" title='Delete' onClick={handleDelete} ><FaTrash /></span>
                    }
                </td>




                <SweetAlertTwo
                    show={info.enable}
                    title="Are You Sure?"
                    text={`Do you want to delete this video!`}
                    icon="warning"
                    showCancelButton={true}
                    confirmButtonColor='#b32f3d'
                    cancelButtonColor='#2a2c3a'
                    confirmButtonText='Yes, Delete!'
                    confirmTitle='Deleting...'
                    confirmText="This won't be revert!"
                    confirmIcon='success'
                    handlePerform={handlePerform}
                    onCancel={handleCancel}
                />
            </tr >
            <PublishModal
                curElem={curElem}
                show={show}
                setShow={setShow}
                videoUrl={curElem.output_video}
            />
        </>
    )
}

export default MergeCard
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gdprAction } from "../../Actions/AuthAction";
import { appName } from "../../Global/Global";
import SweetAlertTwo from "../SweetAlertTwo";


const PrivacyGdpr = () => {

    const dispatch = useDispatch();
    const rebrand = useSelector(state => state.rebrand.data)

    const [button, setButton] = useState('Delete Data');
    const [msgData, setMsgData] = useState('You will not be able to recover this file');
    const [showDelete, setShowDelete] = useState(false);
    const [type, setType] = useState('delete');
   

    const modalData = [
        {
            message: 'Are you sure you want to delete your data? This action is permanent and cannot be reversed.'
        },
        {
            message: 'Are you sure you want to stop processing your data? Doing so will disable your account and in order to reactivate your'
        },
        {
            message: `${rebrand ? rebrand.name : appName} stores your private information. If you wish to download these data, you can do that by clicking Download My Data button.`
        }
    ]

    const onConfirm = (Swal) => {
        setButton('Please Wait...');
        if (type === 'delete') {
            dispatch(gdprAction('', 'delete-account', setShowDelete, Swal))
        }
        else if (type === 'stop') {
            dispatch(gdprAction('', 'disable-account', setShowDelete, Swal));
        }
        else if (type === 'download') {
            dispatch(gdprAction('', 'download-user-data', setShowDelete, Swal));
        }

    }

    const onButtonClick = (e) => {
        setShowDelete(true);

        if (e === 'delete') {
            setMsgData(modalData[0].message);
            setButton('Delete Data');
            setType('delete');
        }
        else if (e === 'stop') {
            setMsgData(modalData[1].message);
            setButton('Stop Processing');
            setType('stop');
        }
        else if (e === 'download') {
            setMsgData(modalData[2].message);
            setButton('Download');
            setType('download');
        }
    }

    const onCancel = (e) => {
        setShowDelete(false);
    }

    return (
        <>


            <div className="profileBlock-wrap profileBlock-wrap-last">
                <h2 className="infoTitle mt-3">Manage Your Acccount Data</h2>
                <div className="profileBlock mt-0">
                    <div className="manage-block">
                        <div className="manage-block-left">
                            <p>{rebrand ? rebrand.name : appName} stores your private information like your name, email. It also stores your uploaded graphics such as images, videos and your music tiles along with TTS Text to speech. If you wish to download these data, you can do that -by clicking" download your data. </p>
                        </div>
                        <div className="manage-block-right">
                            <button className="btn btn-primary ml-2" onClick={() => onButtonClick('download')}>Download My Data</button>
                        </div>
                    </div>
                    <div className="manage-block">
                        <div className="manage-block-left">
                            <p>If you wish to stop your private data from getting processed on {rebrand ? rebrand.name : appName}  you can click the button to do so. Please note that doing this will disable your account and you will not be able to use {rebrand ? rebrand.name : appName} further. In order to reactivate your account, you would have to contact our support team at support@vineasx.com. Please <span>Proceed With Caution</span>.  </p>
                        </div>
                        <div className="manage-block-right">
                            <button className="btn btn-warning ml-2" onClick={() => onButtonClick('stop')}>Stop Processing My Data</button>
                        </div>
                    </div>
                    <div className="manage-block">
                        <div className="manage-block-left">
                            <p>If you wish to delete all your private data, you can do so by clicking this button. Please note that this will permanently delete all the data of yours from our server. This step is not reversible and you cannot use {rebrand ? rebrand.name : appName} further. You cannot undo this step from support also. So if you wish to re-use {rebrand ? rebrand.name : appName} again, then you will have to start all over again from scratch. Please <span>Proceed With Caution</span>.  </p>
                        </div>
                        <div className="manage-block-right">
                            <button className="btn btn-danger ml-2" onClick={() => onButtonClick('delete')}>Delete My Data</button>
                        </div>
                    </div>
                </div>
            </div>


            <SweetAlertTwo
                show={showDelete}
                title="Are You Sure?"
                text={msgData}
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#3085d6'
                cancelButtonColor='#d33'
                confirmButtonText={button}
                confirmTitle='Please Wait...'
                confirmText={msgData}
                confirmIcon='warning'
                handlePerform={onConfirm}
                onCancel={onCancel}
            />
        </>
    )
}

export default PrivacyGdpr;
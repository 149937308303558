import React from "react";
import youtubeIcon from "../../images/YT-DEFAULT.png"
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";

import { saveGoogle } from "../../Actions/SocialAction";

const ConnectGoogle = (props) => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const responseGoogle = (response) => {
        if (response.code) {
            connectYoutube(response.code);
        } else {
            console.log(response);
        }
    }


    const connectYoutube = (code) => {
        let data = { code: code, user_id: auth.user.id }
        dispatch(saveGoogle(data, props.fetchIntegration))

    }

    return (
        <>
            <div className="integr-single" id="youtube">

                <div className="connection-box-main">
                    <div className={`connection-img disable-img`}>
                        <img src={youtubeIcon} width="56px" alt="youtube" />
                    </div>

                    <div className="connection-txt">
                        <div className="noConnect">
                            <div className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                <div className="youtube-content">
                                    <h6>Youtube</h6>
                                    <p className="m-0">Connect Youtube channel</p>
                                </div>
                            </div>
                            <div className="facebook-connect-account">
                                <GoogleLogin
                                    clientId="385524880993-kb22h3hvlooun9i0908bsm29bkeafhio.apps.googleusercontent.com"
                                    render={(renderProps) => (
                                        <button onClick={renderProps.onClick} type="button" className="btn-change7">Connect</button>
                                    )}
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                    scope="https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl"
                                    responseType="code"
                                    accessType="offline"
                                    prompt="consent"
                                    cookiePolicy={"single_host_origin"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConnectGoogle;
import React from "react";

const SocialIcons = (props) => {

    return (
        <>
            <li className={`${props.status ? '' : 'disable'} cursor-pointer ${props.selectIcon}`}  >
                {/* <a href={`#${props.name}_new`}> */}
                <div className="img-wrraper">
                    <img src={props.icon} alt={props.name} style={{ width: "56px" }} />
                </div>
                <p className="mt-1 m-0">{props.name}</p>
                {/* </a> */}
            </li>
        </>
    )
}

export default SocialIcons;
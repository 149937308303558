import React, { useEffect, useMemo } from 'react'
import { useState } from 'react';
import { FiEdit } from 'react-icons/fi'
import AudioModal from './AudioModal/AudioModal';
import { SketchPicker } from 'react-color';
import { useDispatch } from 'react-redux';
import { getUploadData, onChangeBackgroundAudio, onChangeCampName, onChangeThemeColor, onUploadLW, removeLogoWatermark, updateLogoWatermark, updateStatus } from '../../../../Actions/CampaignAction';
import IntroOutro from './IntroOutro';
import LogoComp from './LogoComp';
import WaterComp from './WaterComp';
import Swal from 'sweetalert2';
import BackgroundAudioModal from './AudioModal/BackgroundAudioModal';

const General = ({ campaign, setDeactivateButton }) => {
    const dispatch = useDispatch();
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1");
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const logoData = campaign.logo
    const waterMarkData = campaign.watermark

    const [introArr, setIntroArr] = useState([])
    const [outroArr, setOutroArr] = useState([])
    const [loadLogo, setLoadLogo] = useState(false);
    const [loadWatermark, setLoadWatermark] = useState(false);
    const [rgba, setRgba] = useState({
        r: "",
        g: "",
        b: ""
    });

    const [name, setName] = useState({
        logoName: 'Upload Your Logo',
        waterMarkName: 'Upload Your Watermark'
    });

    const logo = useMemo(() => {
        return logoData
    }, [logoData])

    const waterMark = useMemo(() => {
        return waterMarkData
    }, [waterMarkData])


    const selectImage = (url, type) => {
        let uploadType = 'ADD_LOGO';
        if (type === "watermark") {
            uploadType = 'ADD_WATERMARK';
        }
        dispatch(updateLogoWatermark(uploadType, url));
    }

    const handleLogoWaterMark = (e, type) => {
        if (type === 'logo') {
            dispatch(updateStatus(e.target.checked, 'logo'))
            if (e.target.checked === false) {
                setName({
                    ...name,
                    logoName: 'Upload Your Logo'
                })
                dispatch(removeLogoWatermark('REMOVE_LOGO_LAYER'))
            }
        }

        if (type === 'watermark') {
            dispatch(updateStatus(e.target.checked, 'watermark'))
            if (e.target.checked === false) {
                dispatch(removeLogoWatermark('REMOVE_WATERMARK_LAYER'))
                setName({
                    ...name,
                    waterMarkName: 'Upload Your Watermark'
                })
            }
        }
    }

    const onLoadFile = (file, type) => {
        if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
            if (file.size < 5000000) {
                let formData = new FormData();
                formData.append('file', file);
                formData.append('upload_type', type);

                type === "logo" ?
                    setLoadLogo(true)
                    :
                    setLoadWatermark(true)
                dispatch(onUploadLW(formData, selectImage, name, setName, setLoadLogo, setLoadWatermark, type))

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max allowed size 5MB!',
                    confirmButtonColor: "#d03e3e"
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have Selected Invalid File Type!',
                confirmButtonColor: "#d03e3e"
            })
        }
    }

    const logoWaterPos = (position, type) => {
        let fileType = 'UPDATE_LOGO_POSITION';
        if (type === 'watermark') {
            fileType = 'UPDATE_WATERMARK_POSITION';
        }
        dispatch(updateLogoWatermark(fileType, position));
    }


    const fetchIntro = () => {
        const video = { type: "intro" }
        dispatch(getUploadData(video, setIntroArr))

    }

    const fetchOutro = () => {
        const video = { type: "outro" }
        dispatch(getUploadData(video, setOutroArr))

    }


    const ChangeColor = (e) => {
        const { hex } = e
        dispatch(onChangeThemeColor(campaign, hex))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "name") {
            dispatch(onChangeCampName(value))
        } else {
            dispatch(onChangeBackgroundAudio(value))
        }
    }

    function hexToRgb(hex) {
        hex = hex.replace("#", "");
        const red = parseInt(hex.substring(0, 2), 16);
        const green = parseInt(hex.substring(2, 4), 16);
        const blue = parseInt(hex.substring(4, 6), 16);
        setRgba({ ...rgba, r: red, g: green, b: blue });
    }

    useEffect(() => {
        if (campaign.theme) {
            hexToRgb(campaign.theme);
        }
    }, [campaign.theme])

    useEffect(() => {
        fetchIntro()
        fetchOutro()
    }, [])


    return (
        <div className="innertab-scroll">
            <div className="add-block-single">
                <div className="add-block-main mt-0">
                    <h2>Campaign Name</h2>
                    <input
                        type="text"
                        className="inPut mt-2"
                        name="name"
                        placeholder="Add Campaign Name"
                        value={campaign?.campaign?.name}
                        readOnly
                        onChange={handleChange}
                    ></input>
                </div>
            </div>
            <div className="add-block-single">
                <div className="add-block-main">
                    <h2>Background Audio</h2>
                    <div className="inpIcon-wrap mt-2">
                        <input
                            type="text"
                            className="inPut"
                            placeholder="Audio Name Here"
                            value={campaign?.backgroundAudio?.source}
                            readOnly
                        />
                        <span onClick={handleShow2} className="inp-iconl"><FiEdit /></span>
                    </div>
                </div>

                <div className="add-block-main">
                    <h2>Voiceover</h2>
                    <div className="inpIcon-wrap mt-2">
                        <input
                            type="text"
                            className="inPut"
                            placeholder="Audio Name Here"
                            value={selectedSlide?.audio?.src}
                            readOnly
                        />
                        <span onClick={handleShow} className="inp-iconl"><FiEdit /></span>
                    </div>
                </div>
            </div>

            <LogoComp
                handleLogoWaterMark={handleLogoWaterMark}
                name={name}
                logoWaterPos={logoWaterPos}
                onLoadFile={onLoadFile}
                loadLogo={loadLogo}
                logo={logo}
            />

            <WaterComp
                handleLogoWaterMark={handleLogoWaterMark}
                name={name}
                logoWaterPos={logoWaterPos}
                onLoadFile={onLoadFile}
                loadWatermark={loadWatermark}
                waterMark={waterMark}
            />

            <IntroOutro
                type="intro"
                data={campaign.intro}
                uploadedData={introArr}
                fetchIR={fetchIntro}
            />
            <IntroOutro
                type="outro"
                data={campaign.outro}
                uploadedData={outroArr}
                fetchIR={fetchOutro}
            />

            <AudioModal
                show={show}
                handleClose={handleClose}
                selectedSlide={selectedSlide}
                selectedSlideIndex={selectedSlideIndex}
                setDeactivateButton={setDeactivateButton}
            />
            <BackgroundAudioModal
                show={show2}
                handleClose={handleClose2}
                campaign={campaign}
            />
        </div>
    )
}

export default General

import React from 'react'

const KnowledgeGraphCard = ({ curElem, index, handleScrapping, openWebsite, scrappingLoader }) => {
    let moreData = {
        title: curElem.title,
        address: curElem.address,
        category: curElem.category
    }
    return (
        <div className="col-md-6 mb-3" >
            <div className="add-video-data">
                <div className="col-md-2">
                    <div className="data_img">
                        <img src={curElem.thumbnail} />
                    </div>
                </div>
                <div className="col-md-7 p-0">
                    <div className="Country_data">
                        <h4>{curElem.title}</h4>
                        {curElem.type !== "" ? <span>Business type:{curElem.type}</span> : ""}
                        {curElem.address !== "" ? <span>Address:{curElem.address}</span> : ""}
                        {curElem.Phone !== "" ? <span>Phone:{curElem.Phone}</span> : ""}
                    </div>
                </div>
                <div className="col-md-3 p-0">
                    {curElem.website ?
                        <div className="web_btn">
                            <button className="fbtn btn-block demoLink" style={{ background: scrappingLoader.enable ? "gray" : "" }} disabled={scrappingLoader.enable} onClick={(e) => openWebsite(e, curElem.website)}>Open Website</button>
                        </div>
                        : ""
                    }
                    <div className="web_btn">
                        <button className="fbtn btn-block demoLink" style={{ background: scrappingLoader.enable ? "gray" : "" }} disabled={scrappingLoader.enable} onClick={() => handleScrapping(curElem.url, curElem.mapLink, curElem.placeId, index, moreData)}>Select This</button>
                    </div>
                </div>
                {scrappingLoader.enable && scrappingLoader.index === index ?
                    <i className="fa fa-spinner fa-spin mx-1" style={{ fontSize: "32px", color: "#e0712c", textAlign: "center", position: "absolute" }} />
                    : ""
                }
            </div>
        </div>
    )
}

export default KnowledgeGraphCard

import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"

export const onGetLibraryAudio = (data, libraryAudio, setLibraryAudio, loader, setLoader, setPageCount, setError, pageNo) => (dispatch, getState) => {
    commonAxios("load-library-audio", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setLibraryAudio(libraryAudio.concat(res.data))
                setPageCount(pageNo)
            } else {
                setError(res.data.message);
            }
            setLoader({ ...loader, audioLibrary: false })
        }).catch((err) => {
            console.log(err.msg)
            setLoader({ ...loader, audioLibrary: false })
        })
}

export const onGetUserUploadData = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-user-uploaded-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            } else {
                setState([])
            }
            setLoader({ ...loader, userAudio: false })
        }).catch((err) => {
            console.log(err.msg)
            setLoader({ ...loader, userAudio: false })
        })
}

export const onDeleteMedia = (formData, fetchImgMedia, fetchVidMedia, Swal) => (dispatch, getState) => {
    commonAxios("delete-user-uploaded-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchImgMedia()
                fetchVidMedia()
                dispatch(setAlert(res.msg, 'success'));
            }
            Swal.close()
        }).catch((error) => {
            Swal.close()
            console.log(error)
        })
}

export const onUploadUserData = (data, setLoader, fetchData) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch(() => {
            setLoader(false)
        })
}

export const onFetchVoice = (value, pollyVoices, setPollyVoices) => (dispatch, getState) => {

    let formData = new FormData();
    formData.append('lang_code', value);
    let id = ["96"]
    commonAxios("get-artists", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = [...res.data]
                obj = obj.filter((curElem) => {
                    return !id.includes(curElem.id)
                })
                setPollyVoices({ ...pollyVoices, data: obj });
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onGenerateTts = (data, generateData, setGenerateData, setLoader, setTtsStatus) => (dispatch, getState) => {
    commonAxios("generate-tts-new", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTtsStatus(false)
                setGenerateData({
                    ...generateData,
                    url: res.data.file_url,
                    duration: res.data.duration - 3
                })
            } else {
                dispatch(setAlert(res.msg, 'danger'));
                setTtsStatus(true)
            }
            setLoader(false);
        }).catch((error) => {
            setTtsStatus(true)
            setLoader(false);
            console.log(error)
        })
}

export const onFetchLang = (pollyLanguages, setPollyLanguages, memberShip) => (dispatch, getState) => {
    commonAxios("get-polly-languages", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data.reverse()]
                if (memberShip[memberShip.length - 1] === "professional") {
                    arr = arr.splice(0, 21)
                } else if (memberShip[memberShip.length - 1] === "professional-lite") {
                    arr = arr.splice(0, 11)
                } else if (memberShip[memberShip.length - 1] === "bundle") {

                } else {
                    arr = res.data.filter((curElem) => {
                        return (curElem.name.includes("English") || curElem.name.includes("French") || curElem.name.includes("Hindi") || curElem.name.includes("Spanish"))
                    })
                }
                arr.sort(function (a, b) {
                    return a.name.localeCompare(b.name)
                });
                setPollyLanguages({ ...pollyLanguages, data: arr });
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onGoogleLangFetch = (setTransLang, memberShip) => (dispatch, getState) => {
    commonAxios("fetch-google-language", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data.reverse()]
                arr = arr.filter((curElem) => {
                    return parseInt(curElem.id) !== 40
                })

                if (memberShip[memberShip.length - 1] === "professional-lite") {
                    arr = arr.splice(0, 10)
                }
                arr.sort(function (a, b) {
                    return a.name.localeCompare(b.name)
                });
                setTransLang(arr);
            }
        }).catch((error) => {
            console.log(error)
        })
}

export const onFetchTemplate = (setTemplate, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-template", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTemplate(res.data)
            }
            setLoader(false)
        }).catch((error) => {
            console.log(error)
            setLoader(false)
        })
}

export const onSearchMedia = (data, state, setState, setLoader, search, setSearch, page, setError) => (dispatch, getState) => {
    commonAxios("load-library-images", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...state];
                setState(arr.concat(res.data));

            } else {
                setError(`Oops, No data found for the Keyword "${data.keyword}"`);
            }
            setLoader(false);
            setSearch({ ...search, page_number: page });
        }).catch(err => {
            setLoader(false);
            console.log(err)
        })
}

export const onGenerateFile = (data, setLoader, setOutput, setSelectedMedia) => (dispatch, getState) => {
    commonAxios("generate-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setOutput(res.data);
                setSelectedMedia(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false);
        }).catch(err => {
            console.log(err)
            setLoader(false);
        })
}

export const onVideoCrop = (formData, setLoader, setOutputVideo, setOutputThumb, setSelectedMedia) => (dispatch, getState) => {
    commonAxios("video-crop", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setOutputVideo(res.data.video);
                setOutputThumb(res.data.thumbnail);
                setSelectedMedia(res.data.video)
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })
}
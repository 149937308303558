import './App.css';
import './css/design-fonts.css'
import 'react-calendar/dist/Calendar.css';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Login from './Components/Auth/Login';
import ForgetPassword from './Components/Auth/ForgetPassword';
import ResetPassword from './Components/Auth/ResetPassword';
import MasterLogin from './Components/Auth/MasterLogin';
import { PrivateRoute } from './Components/PrivateRoute';
import { HelmetProvider } from 'react-helmet-async';
import Dashboard from './Components/Dashboard/Dashboard';
import Profile from './Components/User/Profile';
import Privacy from './Components/User/Privacy';
import AccountManagement from './Components/User/AccountManagement';
import Upgrades from './Components/User/Upgrades';
import HelpSupport from './Components/Support/HelpSupport';
import Training from './Components/Support/Training';
import TrainingArticles from './Components/Support/TrainingArticles';
import Integration from './Components/Integration/Integration';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from './Actions/AuthAction';
import { removeAlert } from './Actions/AlertAction';
import Alert from './Components/Alert';
import Projects from './Components/Projects/Projects';
import CreateAddVideo from './Components/CreateAddVideo/CreateAddVideo';
import AddVideoStep2 from './Components/CreateAddVideo/AddVideoStep2';
import EditVideo from './Components/CreateAddVideo/EditVideo';
import Scrape from './Components/CreateAddVideo/Scrape/Scrape';
import Emotion from './Components/CreateAddVideo/Scrape/Emotion';
import ReelMerge from './Components/ReelMerge/ReelMerge';
import { useState } from 'react';
import { onFetchFont } from './Actions/CampaignAction';
import { fontNotSet } from './Global/FontFamilyData';
import Trends from './Components/Trends/Trends';
import Settings from './Components/outreach/Settings';
function App() {

  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)
  const auth = useSelector(state => state.auth)
  const [memberShip, setMemberShip] = useState([])

  useEffect(() => {
    if (auth.user) {
      setMemberShip(auth.user.membership.split("__"))
    }
  }, [auth.user])


  useEffect(() => {
    dispatch(loadUser())
  }, [])

  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])

  useEffect(() => {
    dispatch(onFetchFont(fontNotSet))
  }, [])

  return (
    <div className="App">
      <Alert />
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/forget-password' element={<ForgetPassword />} />
            <Route exact path='/reset-password' element={<ResetPassword />} />
            <Route exact path='/master-login' element={<MasterLogin />} />

            <Route exact path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route exact path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route exact path="/privacy" element={<PrivateRoute><Privacy /></PrivateRoute>} />
            <Route exact path="/account-management" element={<PrivateRoute><AccountManagement /></PrivateRoute>} />
            <Route exact path="/upgrades" element={<PrivateRoute><Upgrades /></PrivateRoute>} />
            <Route exact path="/help-and-support" element={<PrivateRoute><HelpSupport /></PrivateRoute>} />
            <Route exact path="/training" element={<PrivateRoute><Training /></PrivateRoute>} />
            <Route exact path="/support-article" element={<PrivateRoute><TrainingArticles /></PrivateRoute>} />
            <Route exact path="/integration" element={<PrivateRoute><Integration /></PrivateRoute>} />
            <Route exact path="/projects" element={<PrivateRoute><Projects /></PrivateRoute>} />
            <Route exact path="/create-video" element={<PrivateRoute><CreateAddVideo /></PrivateRoute>} />
            <Route exact path="/create-video-2" element={<PrivateRoute><AddVideoStep2 /></PrivateRoute>} />
            <Route exact path="/editor" element={<PrivateRoute><EditVideo /></PrivateRoute>} />
            <Route exact path="/scrape" element={<PrivateRoute><Scrape /></PrivateRoute>} />
            <Route exact path="/emotion" element={<PrivateRoute><Emotion /></PrivateRoute>} />
            <Route exact path="/trends" element={<PrivateRoute><Trends /></PrivateRoute>} />
            {memberShip.includes("enterprise") || memberShip.includes("bundle") ?
              <Route exact path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} /> : ""}
            {memberShip.includes("unlimited") || memberShip.includes("bundle") ?
              <Route exact path="/reel-merge" element={<PrivateRoute><ReelMerge /></PrivateRoute>} />

              : ''}
            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;

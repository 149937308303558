import React from 'react'
import { NavLink } from 'react-router-dom';
const settingMenu = () => {
    return (
        <>
            <div className="nav flex-column" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                <a className="nav-link active" data-toggle="pill" href="#tab-1" role="tab">Outreach</a>
                <a className="nav-link" data-toggle="pill" href="#tab-2" role="tab">Trends</a>

            </div>

        </>
    )
}
export default settingMenu;
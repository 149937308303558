import React from 'react'
import { AbsoluteFill, Img } from 'remotion';

const Logo = ({ src, position, type, multiplyNum }) => {

    let mystyle = {
        position: "absolute",
        height: "auto",
        width: "100%",
        maxWidth: `${100 * multiplyNum}px`,
        padding: `${10 * multiplyNum}px`,
        opacity: type === "logo" ? 1 : 0.5
    }

    if (position === "top-left") {
        mystyle.top = 0;
        mystyle.left = 0;
    } else if (position === "top-center") {
        mystyle.top = 0;
        mystyle.left = "50%";
        mystyle.transform = "translateX(-50%)"
    } else if (position === "top-right") {
        mystyle.top = 0;
        mystyle.right = 0;
    }
    else if (position === "bottom-center") {
        mystyle.bottom = 0;
        mystyle.left = "50%";
        mystyle.transform = "translateX(-50%)"
    } else if (position === "bottom-right") {
        mystyle.bottom = 0;
        mystyle.right = 0;
    } else {
        mystyle.bottom = 0;
        mystyle.left = 0;
    }

    return (
        <AbsoluteFill >
            < Img
                style={mystyle}
                src={src}
            />
        </AbsoluteFill >
    )
}

export default Logo

import React from 'react'
import redditImg from "../../images/reddit_new.png"
import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    EmailIcon,
    FacebookIcon,
    InstapaperIcon,
    LinkedinIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WhatsappIcon,
    WorkplaceIcon
} from 'react-share';

function RedditCard({ curElem }) {
    return (
        <>
            <div className='col-xl-12' >
                <div className='trending-details-single'>
                    <div className='trending-left'>
                        <div className='trending-img'><img alt='' src={curElem.data?.thumbnail && curElem.data?.thumbnail !== "self" ? curElem.data?.thumbnail : redditImg} /></div>
                    </div>
                    <div className='trending-right'>
                        <div className='trending-title'>
                            <h4>{curElem.data.title}</h4>
                            <ul className='trend-social'>
                                {
                                    <>
                                        <FacebookShareButton
                                            url="www.google.com"
                                            quote={`https://www.reddit.com/${curElem.data.permalink}`}>
                                            <FacebookIcon />
                                        </FacebookShareButton>

                                        <EmailShareButton
                                            url={`https://www.reddit.com/${curElem.data.permalink}`}
                                            quote={`https://www.reddit.com/${curElem.data.permalink}`}>
                                            <EmailIcon />
                                        </EmailShareButton>

                                        <LinkedinShareButton
                                            url={`https://www.reddit.com/${curElem.data.permalink}`}
                                            quote={`https://www.reddit.com/${curElem.data.permalink}`}>
                                            <LinkedinIcon />
                                        </LinkedinShareButton>

                                        <PinterestShareButton
                                            url={`https://www.reddit.com/${curElem.data.permalink}`}
                                            quote={`https://www.reddit.com/${curElem.data.permalink}`}>
                                            <PinterestIcon />
                                        </PinterestShareButton>

                                        <RedditShareButton
                                            url={`https://www.reddit.com/${curElem.data.permalink}`}
                                            quote={`https://www.reddit.com/${curElem.data.permalink}`}>
                                            <RedditIcon />
                                        </RedditShareButton>

                                        <TelegramShareButton
                                            url={`https://www.reddit.com/${curElem.data.permalink}`}
                                            quote={`https://www.reddit.com/${curElem.data.permalink}`}>
                                            <TelegramIcon />
                                        </TelegramShareButton>

                                        <PocketShareButton
                                            url={`https://www.reddit.com/${curElem.data.permalink}`}
                                            quote={`https://www.reddit.com/${curElem.data.permalink}`}>
                                            <PocketIcon />
                                        </PocketShareButton>

                                        <TwitterShareButton
                                            url={`https://www.reddit.com/${curElem.data.permalink}`}
                                            quote={`https://www.reddit.com/${curElem.data.permalink}`}>
                                            <TwitterIcon />
                                        </TwitterShareButton>

                                        <WhatsappShareButton
                                            url={`https://www.reddit.com/${curElem.data.permalink}`}
                                            quote={`https://www.reddit.com/${curElem.data.permalink}`}>
                                            <WhatsappIcon />
                                        </WhatsappShareButton>

                                        <TumblrShareButton
                                            url={`https://www.reddit.com/${curElem.data.permalink}`}
                                            quote={`https://www.reddit.com/${curElem.data.permalink}`}>
                                            <TumblrIcon />
                                        </TumblrShareButton>

                                        <InstapaperShareButton
                                            url={`https://www.reddit.com/${curElem.data.permalink}`}
                                            quote={`https://www.reddit.com/${curElem.data.permalink}`}>
                                            <InstapaperIcon />
                                        </InstapaperShareButton>

                                        <ViberShareButton
                                            url={`https://www.reddit.com/${curElem.data.permalink}`}
                                            quote={`https://www.reddit.com/${curElem.data.permalink}`}>
                                            <ViberIcon />
                                        </ViberShareButton>

                                        <VKShareButton
                                            url={`https://www.reddit.com/${curElem.data.permalink}`}
                                            quote={`https://www.reddit.com/${curElem.data.permalink}`}>
                                            <VKIcon />
                                        </VKShareButton>

                                        <WorkplaceShareButton
                                            url={`https://www.reddit.com/${curElem.data.permalink}`}
                                            quote={`https://www.reddit.com/${curElem.data.permalink}`}>
                                            <WorkplaceIcon />
                                        </WorkplaceShareButton>
                                    </>
                                }
                            </ul>
                        </div>
                        <div className='trending-meta'>
                            <ul>
                                <li>
                                    <p>Comments</p>
                                    <h4>{curElem.data.num_comments}</h4>
                                </li>
                                <li>
                                    <p>Votes</p>
                                    <h4>{curElem.data.upvote_ratio}</h4>
                                </li>
                                <li>
                                    <p>Subscribers</p>
                                    <h4>{curElem.data.subreddit_subscribers}</h4>
                                </li>
                            </ul>
                        </div>
                        <a className='fbtn btn-block demoLink' target='_blank' href={curElem.data.url}><span>Visit</span></a>
                    </div>
                </div>
            </div>
        </>

    )
}

export default RedditCard

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { BsFillMicFill, BsFillMicMuteFill } from "react-icons/bs"
import { FiTrash2 } from "react-icons/fi"
import { useDispatch } from 'react-redux'
import { deleteTile, onAddSlide, onRemovePlaySlide, rearrangeTiles, removeTtsFromSlides, updateScript } from '../../../../Actions/CampaignAction';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Swal from 'sweetalert2';
import SweetAlertTwo from '../../../SweetAlertTwo';
import { useRef } from 'react'
import { ReactComponent as TransitionIcon } from '../../../../images/transition.svg'
import { AiFillPlusCircle } from "react-icons/ai"


const VideoTiles = ({ slideData }) => {
    const dispatch = useDispatch()
    const fRef = useRef(null)
    const oRef = useRef(null)
    const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1")
    const [loader, setLoader] = useState(false)
    const [videoPlay, setVideoPlay] = useState(true)
    const [info, setInfo] = useState({
        enable: false,
        id: -1,
        index: -1
    })

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result;
    }

    const onSetRecorder = (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(slideData, result.source.index, result.destination.index);
        dispatch(rearrangeTiles(items));
    }


    // const handleAddSlide = (data) => {
    // console.log(data)
    // let obj = {
    //     id: data.id,
    // }
    // setLoader(true)
    // dispatch(onAddSlide(obj, setLoader))
    // }

    const onSelectTile = (index) => {
        if (index !== selectedSlideIndex) {
            dispatch(updateScript(index, selectedSlideIndex))
            dispatch(onRemovePlaySlide(selectedSlideIndex))
        }
    }
    const handleTts = (slideIndex, e) => {
        e.stopPropagation()
        dispatch(removeTtsFromSlides(slideIndex))
    }


    const onCancel = () => {
        setInfo({
            enable: false,
            id: -1,
            index: -1
        })
    }

    const handleDeleteAlert = (e, tId, index) => {
        e.stopPropagation()
        if (slideData.length === 1) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You cannot delete last slide!',
                confirmButtonColor: "#d03e3e"
            })
        } else {
            setInfo({
                ...info,
                enable: true,
                id: tId,
                index: index

            })
        }
    }


    const onConfirm = (Swal1) => {
        dispatch(deleteTile(info.id, info.index, Swal1))
        setInfo({
            enable: false,
            id: -1,
            index: -1
        })

    }


    // const selectInnerBackground = (index, ind) => {
    //     dispatch(onSelectInnerBackground(index, ind))
    // }

    return (
        <>
            <div className="editorReel-scroll">
                <DragDropContext onDragEnd={onSetRecorder}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided) => (
                            <ul
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {slideData.length > 0 ?
                                    slideData.map((curElem, index) => {
                                        return (
                                            <>
                                                <Draggable key={index} draggableId={`id_${index}`} index={index}>
                                                    {(provided) => (
                                                        <li
                                                            className={`cursor-pointer`}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            key={index}
                                                            onClick={() => onSelectTile(index)}
                                                        >
                                                            <div
                                                                className={`editorReel-single ${+curElem.isSelected ? "active-tile-border" : "deactive-tile-border"}`}
                                                                style={{ background: curElem.background[0].type === "color" ? curElem.background[0].src : "" }}
                                                            >
                                                                <div className='micON'>
                                                                    <div className="d-flex">
                                                                        {curElem.audio.enable ?
                                                                            <BsFillMicFill
                                                                                style={{
                                                                                    fontSize: "20px",
                                                                                    cursor: 'pointer',
                                                                                    color: `${+curElem.isSelected === 1 ? '#ff6f00' : '#fff'}`
                                                                                }}
                                                                                onClick={(e) => handleTts(index, e)}
                                                                            />
                                                                            :
                                                                            <BsFillMicMuteFill
                                                                                className="voiceIcn-off"
                                                                                style={{
                                                                                    color: `${+curElem.isSelected === 1 ? '#ff6f00' : '#fff'}`,
                                                                                    fontSize: "20px",
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {curElem.background[0].type !== "color" ?
                                                                    curElem.background[0].type === "image" ?
                                                                        <img src={curElem.background[0].src} alt="" />
                                                                        :
                                                                        <video
                                                                            src={curElem.background[0].src}
                                                                            muted
                                                                            onSeeked={2}
                                                                            height={"100%"}
                                                                            ref={fRef}
                                                                        /> : ""
                                                                }
                                                                <div className="IconDel">
                                                                    <FiTrash2
                                                                        style={{
                                                                            fontSize: "19px",
                                                                            cursor: "pointer",
                                                                            color: "#fff",
                                                                        }}
                                                                        onClick={(e) => handleDeleteAlert(e, curElem.id, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )}
                                                </Draggable >
                                                {curElem.transitionName !== "" && curElem.transitionName ?
                                                    <div className="transition-wrapper mx-2" title={curElem.transitionName}>
                                                        <svg width="22px" height="22px" viewBox="0 0 22 22" >
                                                            <TransitionIcon style={{ color: '#fff' }} />
                                                        </svg>
                                                    </div> : ""}
                                                {/* <div className="addReelBt" onClick={() => handleAddSlide(curElem)}>
                                                    {!loader ? <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#e0712c", fontSize: '25px' }} /> :
                                                        <AiFillPlusCircle />}
                                                </div> */}
                                            </>
                                        )
                                    }) : ""}
                                {provided.placeholder}
                            </ul>
                        )
                        }
                    </Droppable>
                </DragDropContext>


            </div >

            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#b32f3d'
                cancelButtonColor='#2a2c3a'
                confirmButtonText='Yes, delete!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={onConfirm}
                onCancel={onCancel}
            />
        </>
    )
}

export default VideoTiles
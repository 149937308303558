import React, { useEffect, useState, useRef } from 'react'
import ContentEditable from 'react-contenteditable';
import { useDispatch } from 'react-redux';
import { Rnd } from "react-rnd";
import { endAnimation, updateTextHeight } from '../../../../Actions/CampaignAction';


const TextLayer = (props) => {
    const textRef = useRef(null)
    const dispatch = useDispatch()
    const [text, setText] = useState(props.layer.src)
    const [myStyle, setMyStyle] = useState({
        color: props.layer.style.color,
        fontFamily: props.layer.style.fontFamily,
        background: props.layer.style.background ? props.layer.style.background : props.layer.style.backgroundColor,
        fontSize: parseInt(props.layer.style.fontSize),
        textAlign: props.layer.style.textAlign,
        fontWeight: props.layer.style.fontWeight,
        fontStyle: props.layer.style.fontStyle,
        textTransform: props.layer.style.textTransform,
        textDecoration: props.layer.style.textDecoration,
        lineHeight: props.layer.style.lineHeight,
        opacity: parseInt(props.layer.style.opacity),
        left: props.layer.position.left,
        top: props.layer.position.top,
        width: props.layer.size.width,
        height: props.layer.size.height,
        clipPath: props.layer.style.clipPath,
        border: props.layer.style.border,
        boxShadow: props.layer.style.boxShadow,
        transform: props.layer.style.transform,
        display: props.layer.style.display,
        alignItems: props.layer.style.alignItems,
        justifyContent: props.layer.style.justifyContent,
        borderRadius: props.layer.style.borderRadius,
        textShadow: props.layer.style.textShadow,
        writingMode: props.layer.style.writingMode,
        borderWidth: props.layer.style.borderWidth,
        borderStyle: props.layer.style.borderStyle,
        borderImage: props.layer.style.borderImage,
        borderImageSlice: props.layer.style.borderImageSlice,
        borderTopRightRadius: props.layer.style.borderTopRightRadius,
        borderBottomRightRadius: props.layer.style.borderBottomRightRadius,
        borderTopLeftRadius: props.layer.style.borderTopLeftRadius,
        borderBottomLeftRadius: props.layer.style.borderBottomLeftRadius,
        letterSpacing: props.layer.style.letterSpacing,
        padding: props.layer.style.padding,
        position: "absolute",
        overflow: "hidden"
    })

    useEffect(() => {
        dispatch(updateTextHeight(textRef.current.clientHeight, props.selectedSlideIndex, props.layerIndex))
    }, [props.layer.src])


    useEffect(() => {
        const obj = {
            color: props.layer.style.color,
            fontFamily: props.layer.style.fontFamily,
            background: props.layer.style.background ? props.layer.style.background : props.layer.style.backgroundColor,
            fontSize: parseInt(props.layer.style.fontSize),
            textAlign: props.layer.style.textAlign,
            fontWeight: props.layer.style.fontWeight,
            fontStyle: props.layer.style.fontStyle,
            textTransform: props.layer.style.textTransform,
            textDecoration: props.layer.style.textDecoration,
            lineHeight: props.layer.style.lineHeight,
            opacity: parseInt(props.layer.style.opacity),
            left: props.layer.position.left,
            top: props.layer.position.top,
            width: props.layer.size.width,
            height: props.layer.size.height,
            clipPath: props.layer.style.clipPath,
            border: props.layer.style.border,
            boxShadow: props.layer.style.boxShadow,
            transform: props.layer.style.transform,
            display: props.layer.style.display,
            alignItems: props.layer.style.alignItems,
            justifyContent: props.layer.style.justifyContent,
            borderRadius: props.layer.style.borderRadius,
            textShadow: props.layer.style.textShadow,
            writingMode: props.layer.style.writingMode,
            borderWidth: props.layer.style.borderWidth,
            borderStyle: props.layer.style.borderStyle,
            borderImage: props.layer.style.borderImage,
            borderImageSlice: props.layer.style.borderImageSlice,
            borderTopRightRadius: props.layer.style.borderTopRightRadius,
            borderBottomRightRadius: props.layer.style.borderBottomRightRadius,
            borderTopLeftRadius: props.layer.style.borderTopLeftRadius,
            borderBottomLeftRadius: props.layer.style.borderBottomLeftRadius,
            letterSpacing: props.layer.style.letterSpacing,
            padding: props.layer.style.padding,
            position: "absolute",
            overflow: "hidden"

        }
        setMyStyle(obj)
    }, [props.layer.style, props.layer.position, props.layer.size])

    let localStyle = {
        color: myStyle.color,
        fontFamily: myStyle.fontFamily,
        fontSize: parseInt(myStyle.fontSize),
        textAlign: myStyle.textAlign,
        fontWeight: myStyle.fontWeight,
        fontStyle: myStyle.fontStyle,
        textTransform: myStyle.textTransform,
        textDecoration: myStyle.textDecoration,
        lineHeight: myStyle.lineHeight,
        opacity: parseInt(myStyle.opacity),
        position: "absolute",
        paddingLeft: '8px',
        overflow: "hidden",
        clipPath: props.layer.style.clipPath,
        boxShadow: props.layer.style.boxShadow,
        transform: props.layer.style.transform,
        display: props.layer.style.display,
        alignItems: props.layer.style.alignItems,
        justifyContent: props.layer.style.justifyContent,
        borderRadius: props.layer.style.borderRadius,
        textShadow: props.layer.style.textShadow,
        writingMode: props.layer.style.writingMode,
        border: props.layer.style.border,
        borderWidth: props.layer.style.borderWidth,
        borderStyle: props.layer.style.borderStyle,
        borderImage: props.layer.style.borderImage,
        borderImageSlice: props.layer.style.borderImageSlice,
        borderTopRightRadius: props.layer.style.borderTopRightRadius,
        borderBottomRightRadius: props.layer.style.borderBottomRightRadius,
        borderTopLeftRadius: props.layer.style.borderTopLeftRadius,
        borderBottomLeftRadius: props.layer.style.borderBottomLeftRadius,
        letterSpacing: props.layer.style.letterSpacing,
        padding: props.layer.style.padding
    }

    let innerTextStyle = {
        animationDuration: props.layer.animation.duration,
        animationDelay: props.layer.animation.delay,
        background: myStyle.background,
        borderTop: props.layer.style.borderTop,
        borderBottom: props.layer.style.borderBottom,
        borderLeft: props.layer.style.borderLeft,
        borderRight: props.layer.style.borderRight,
    }

    let innerCss = {
        display: props.layer.innerCss ? props.layer.innerCss.display ? props.layer.innerCss.display : "block" : "block",
        background: props.layer.innerCss ? props.layer.innerCss.background ? props.layer.innerCss.background : "" : "",
        lineHeight: props.layer.innerCss ? props.layer.innerCss.lineHeight ? props.layer.innerCss.lineHeight : "" : "",
        padding: props.layer.innerCss ? props.layer.innerCss.padding ? props.layer.innerCss.padding : "" : "",
    }

    if (localStyle.borderRadius === "" || localStyle.borderRadius === undefined) {
        delete localStyle.borderRadius
        localStyle = {
            ...localStyle,
            borderTopRightRadius: props.layer.style.borderTopRightRadius,
            borderBottomRightRadius: props.layer.style.borderBottomRightRadius,
            borderTopLeftRadius: props.layer.style.borderTopLeftRadius,
            borderBottomLeftRadius: props.layer.style.borderBottomLeftRadius,
        }
    }


    const handleAnimEnd = () => {
        dispatch(endAnimation(props.selectedSlideIndex, props.layerIndex))
    }

    // This is neccesary for Add Space Between Text

    useEffect(() => {
        setText(props.layer.src)
    }, [props.layer.id, props.layer])

    return (
        <Rnd
            dragGrid={[1, 1]}
            className={`${props.layer.isSelected ? "layer-border" : ""} `}
            style={localStyle}
            enableResizing={props.layer.isSelected ? true : false}
            disableDragging={props.layer.isSelected ? false : true}
            onClick={() => props.handleSelectLayer(props.layerIndex)}
            default={{ x: 0, y: 0, width: 100, height: 100 }}
            size={{ width: myStyle.width }}
            position={{ x: myStyle.left, y: myStyle.top }}
            onDragStop={(e, d) => {
                setMyStyle({ ...myStyle, left: d.x, top: d.y })
                props.handleRePosition(d.y, d.x, props.layerIndex)
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
                setMyStyle({ ...myStyle, width: ref.style.width, height: ref.style.height, top: position.x, left: position.y });
                props.handleResize(ref.style.width, ref.style.height, position.x, position.y, props.layerIndex)
            }}
            bounds=".canvasEditor-main"

        >
            <div
                className={`animate__animated ${props.layer.animation.playAnimation ? props.layer.animation.name : ""} css-for-svgs`}
                onAnimationEnd={() => handleAnimEnd()}
                style={innerTextStyle}
                ref={textRef}
            >
                <span
                    style={innerCss}
                    id={props.layer.id}
                >
                    <ContentEditable
                        html={text}
                        disabled={true}
                        tagName='span'
                    />
                </span>
            </div>
        </Rnd>
    )
}

export default TextLayer
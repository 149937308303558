import produce from "immer"
const initialState = {
    data: {
        facebook: [],
        youtube: [],
        scriptreel: [],
        livereel: [],
        trimreel: [],
        syvid: [],
        sonority: [],
        videoreel: []
    }
}

export const SocialReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_SOCIAL_ACCOUNTS":
            return produce(state, (draft) => {
                draft.data.facebook = action.payload.facebook ? action.payload.facebook : []
                draft.data.youtube = action.payload.youtube ? action.payload.youtube : []
                draft.data.scriptreel = action.payload.scriptreel ? action.payload.scriptreel : []
                draft.data.livereel = action.payload.livereel ? action.payload.livereel : []
                draft.data.trimreel = action.payload.trimreel ? action.payload.trimreel : []
                draft.data.syvid = action.payload.syvid ? action.payload.syvid : []
                draft.data.sonority = action.payload.sonority ? action.payload.sonority : []
                draft.data.videoreel = action.payload.videoreel ? action.payload.videoreel : []
            })
        default:
            return state
    }
}
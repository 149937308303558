import axios from "axios"
import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"
import WebFont from "webfontloader";

export const onUpdateEmotion = (data, navigate, setLoader) => (dispatch, getState) => {
    commonAxios("emotion", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/projects`)
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err.msg)
            setLoader(false)
        })
}

export const onFetchBusinessDetails = (data, state, navigate, setScrappingLoader) => (dispatch, getState) => {
    commonAxios("fetch-business-details", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/emotion?cId=${data.cId}&name=${state.campaignName}`)
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setScrappingLoader({ enable: false, index: -1 })
        }).catch((err) => {
            console.log(err.msg)
            setScrappingLoader({ enable: false, index: -1 })
        })
}

export const onFetchScrapeData = (data, setScrapeData) => (dispatch, getState) => {
    commonAxios("fetch-scrap-data", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setScrapeData({
                    siteData: res.data[0]?.siteData ? res.data[0].siteData : [],
                    gmapsReviews: res.data[0]?.gmapsData?.gmapsReviews ? res.data[0].gmapsData.gmapsReviews : [],
                    gmapsPhotos: res.data[0]?.gmapsData?.gmapsPhotos ? res.data[0].gmapsData.gmapsReviews : [],
                    gmapsVideos: res.data[0]?.gmapsData?.gmapsVideos ? res.data[0].gmapsData.gmapsVideos : []
                })
            }

        }).catch((err) => {
            console.log(err.msg)
        })
}

export const onFetchVideo = (data, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = { ...res.data }
                obj.slides.forEach((curElem) => {
                    if (curElem.layers.length > 0) {
                        curElem.layers.forEach((layer) => {
                            if (layer.type === "text" || layer.type === "shape") {
                                if (layer.style.backgroundColor) {
                                    let bgData = layer.style.backgroundColor
                                    layer = {
                                        ...layer,
                                        style: {
                                            ...layer.style,
                                            background: bgData
                                        }
                                    }
                                    delete layer.style.backgroundColor
                                }
                            }
                        })
                    }
                    delete curElem.keywords
                })
                dispatch({ type: "SET_CAMPAIGN_DATA", payload: obj })
            }
            setLoader(false)

        }).catch((err) => {
            console.log(err.msg)
        })
}

export const onRenderVideo = (data, navigate, setLoader) => (dispatch, getState) => {
    commonAxios("render", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                navigate("/projects")
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err.msg)
        })
}

export const saveVideo = (data) => (dispatch, getState) => {
    commonAxios("save-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
            }
        }).catch((err) => {
            console.log(err.msg)
        })
}

export const onCreateCampaign = (data, state, setState, setScrapeData, navigate, loader, setLoader, setError) => (dispatch, getState) => {
    commonAxios("create-campaign", data, dispatch, getState().auth.token)

        .then((res) => {

            if (res.status) {
                setState({ ...state, cId: res.data, campaignType: "campaignUpdate" })
                if (data.type === "search" || data.type === "places") {
                    dispatch(onFetchCampaign({ cId: res.data }, setScrapeData, loader, setLoader, setError))
                } else {
                    navigate(`/emotion?cId=${res.data}&name=${data.campaignName}`)
                }
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader({ ...loader, submitLoader: false });
        }).catch((err) => {
            console.log(err.msg)
            setLoader({ ...loader, submitLoader: false });
        })
}

export const onFetchCampaign = (data, setScrapeData, loader, setLoader, setError) => (dispatch, getState) => {
    commonAxios("fetch-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                let places = res.data?.business[0]?.data?.places ? res.data?.business[0]?.data?.places : []
                let updateLinks = res.data?.business[0]?.data?.updatedLinks ? res.data?.business[0]?.data?.updatedLinks : []
                let knowledgeGraph = res.data?.business[0]?.data?.knowledgeGraph ? res?.data?.business[0]?.data?.knowledgeGraph : []
                console.log(places, knowledgeGraph, updateLinks, "....")
                setScrapeData({
                    places: places,
                    updateLinks: updateLinks,
                    knowledgeGraph: knowledgeGraph,
                })

                if (setError && places.error && updateLinks.length === 0 && knowledgeGraph.length === 0) {
                    setError("No data found")
                }
            } else {
                if (setError) {
                    setError("No data found")
                }
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader({ ...loader, detailsLoader: false });
        }).catch((err) => {
            console.log(err.msg)
            setLoader({ ...loader, detailsLoader: false });
        })
}

export const onGetAllCampaign = (setCampaigns, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-all-campaign", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setCampaigns(res.data)
            } else {
                setCampaigns([])
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err.msg)
            setLoader(false)
        })
}

export const onFetchMergeCampaigns = (setMergeVideo, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-merge-campaigns", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setMergeVideo(res.data);
            } else {
                setMergeVideo([]);
            }
            setLoader(false)
        }).catch(err => {
            setLoader(false)
            console.log(err)

        })
}

// export const onFetchVideoStatus = (data, setStatus) => (dispatch, getState) => {
//     commonAxios("fetch-video", data, dispatch, getState().auth.token)
//         .then((res) => {
//             if (res.status) {
//                 setStatus(res.data.status)
//             }
//         }).catch((err) => {
//             console.log(err.msg)
//         })
// }

export const onDeleteCampaign = (data, fetchData, swal) => (dispatch, getState) => {
    commonAxios("delete-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            swal.close()
        }).catch((err) => {
            console.log(err.msg)
            swal.close()
        })
}

export const onDeleteMergeCampaign = (data, fetchData, swal) => (dispatch, getState) => {
    commonAxios("delete-merge", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            swal.close()
        }).catch((err) => {
            console.log(err.msg)
            swal.close()
        })
}

export const onCheckMergeStatus = (data, setState, rendCheck, clearInterval, fetchResource) => (dispatch, getState) => {
    commonAxios("fetch-status-merge", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (res.data[0].status === "Merging Videos") {
                    setState(res.data[0].status)
                }
                else if (res.data[0].status === "Finished") {
                    clearInterval(rendCheck)
                    fetchResource()
                }
                else if (res.data[0].status === "Failed") {
                    clearInterval(rendCheck)
                    fetchResource()
                }
                else if (res.data[0].status === "Downloading Files") {
                    setState(res.data[0].status)
                }
                else {
                    setState(res.data[0].status)
                }
            } else {
                clearInterval(rendCheck)
            }
        }).catch((err) => {
            clearInterval(rendCheck)
            console.log(err)
        })
}

export const onFetchCountry = (setCountryData) => (dispatch, getState) => {
    commonAxios("fetch-country", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data]
                arr.sort((a, b) => {
                    if (a.name > b.name) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setCountryData(arr);
            } else {
                setCountryData([]);
            }
        }).catch((err) => {
            console.log(err.msg)
            setCountryData([]);
        })
}

export const onFetchState = (data, setStateData) => (dispatch, getState) => {
    commonAxios("fetch-state", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data]
                arr.sort((a, b) => {
                    if (a.name > b.name) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setStateData(arr);
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setStateData([]);
            }
        }).catch((err) => {
            console.log(err.msg)
            setStateData([]);
        })
}

export const onFetchCity = (data, setCityData) => (dispatch, getState) => {
    commonAxios("fetch-city", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data]
                arr.sort((a, b) => {
                    if (a.name > b.name) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setCityData(arr);
            } else {
                setCityData([]);
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err.msg)
            setCityData([]);
        })
}

export const onFetchFont = (fontNotSet) => (dispatch) => {
    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/fetch-fonts',
        data: {},
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            let arr = res.data.data.filter(curElem => {
                return !fontNotSet.includes(curElem.value)
            }).map((item) => {
                return (
                    item.value
                )
            })
            WebFont.load({
                google: {
                    families: arr
                }
            });
        }
    }).catch(err => {
        console.log(err)
    })
}

export const onFetchFontEditor = (setFonts, fontNotSet) => (dispatch) => {
    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/fetch-fonts',
        data: {},
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            let arr = res.data.data.filter(curElem => {
                return !fontNotSet.includes(curElem.value)
            }).map((item) => {
                return (
                    item.value
                )
            })
            setFonts(arr);
        }
    }).catch(err => {
        console.log(err)
    })
}

export const uploadBusinessLogo = (formData, state, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("upload-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    ...state,
                    logo: res.data.path,
                })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                logoLoader: false
            })
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                logoLoader: false
            })
        })
}

export const uploadMultipleMedia = (formData, type, state, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("upload-images", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (type === "images") {
                    setState({
                        ...state,
                        images: state.images.concat(res.data)
                    })
                } else {
                    setState({
                        ...state,
                        videos: state.videos.concat(res.data)
                    })
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                imageLoader: false,
                videoLoader: false
            })
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                imageLoader: false,
                videoLoader: false
            })
        })
}

export const onFetchBusinessGraphic = (data, setImages, setVideos, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-business-graphic", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let siteVideo = []
                let siteImg = []
                if (res.data?.siteData?.length > 0) {
                    res.data.siteData.forEach(ele => {
                        if (ele.type === "video") {
                            siteVideo.push(ele.src)
                        } else {
                            siteImg.push(ele.src)
                        }
                    });
                }

                if (res.data?.gmapData?.length > 0) {
                    res.data.gmapData.forEach(ele => {
                        if (ele.type === "video") {
                            siteVideo.push(ele.src)
                        } else {
                            siteImg.push(ele.src)
                        }
                    });
                }


                setImages(siteImg)
                setVideos(siteVideo)
            } else {
                setImages([])
                setVideos([])
            }
            setLoader(false)
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
        })
}

export const onCheckStatus = (data, setStatus, checkData, setCheckData) => (dispatch, getState) => {
    commonAxios("fetch-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setStatus(+res.data.status)
                if (+res.data.status === 2 || +res.data.status === 3) {
                    setCheckData({
                        ...checkData,
                        errMessage: res.data.logFile.errorMessage,
                        videoUrl: res.data.videoUrl,
                        failedButton: res.data?.statusMessage
                    })
                }
            } else {
                setStatus(0)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onCheckStatus1 = (data, setStatus, checkData, rendCheck, setCheckData, clearInterval, fetchResource) => (dispatch, getState) => {
    commonAxios("fetch-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (res.data.status === "0" || res.data.status === "5") {
                    setStatus(+res.data.status)
                }
                else if (res.data.status === "2" || res.data.status === "3") {
                    fetchResource()
                    clearInterval(rendCheck)
                }
                else if (res.data.status === "6") {
                    setStatus(+res.data.status)
                    setCheckData({
                        ...checkData,
                        renderProgress: res.data.renderProgress,
                        renderStatus: res.data.renderStatus,
                    })
                }
                else {
                    setStatus(+res.data.status)
                    clearInterval(rendCheck)
                }
            } else {
                setStatus(0)
            }
        }).catch((err) => {
            clearInterval(rendCheck)
            console.log(err)
        })
}

export const onUploadIR = (data, fetchData, setLoader, type) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let data = {
                    url: res.data.path,
                    nameData: res.data.name,
                    thumbData: res.data.thumbnail
                }
                if (type === "intro") {
                    dispatch(updateIntroOutro('UPDATE_INTRO', data, setLoader))
                }
                else {
                    dispatch(updateIntroOutro('UPDATE_OUTRO', data, setLoader))
                }
                fetchData()
            }
            else {
                dispatch(setAlert(res.msg, 'danger'));
                setLoader(false)
            }
        }).catch((err) => {
            console.log(err)
            setLoader(false)

        })
}

export const onUpload = (data, fetchMedia, setLoader, setPercent) => (dispatch, getState) => {
    const config = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            setPercent(percent);
        },
        headers: {
            // "Content-Type": "application/json",
            'Authorization': getState().auth.token
        },
    }
    setLoader(true)
    commonAxios("upload-file", data, dispatch, false, config)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchMedia()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setPercent(0)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
}

export const onDeleteMedia = (formData, fetchIntro, Swal) => (dispatch, getState) => {
    commonAxios("delete-user-uploaded-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchIntro()
                dispatch(setAlert(res.msg, 'success'));
            }
            Swal.close()
        }).catch((error) => {
            Swal.close()
            console.log(error)
        })
}

export const getUploadData = (data, setData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-user-uploaded-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data)
            }
            else {
                setData([])
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetchLoader: false
                })
            }
        }).catch((error) => {
            if (loader) {
                setLoader({
                    ...loader,
                    fetchLoader: false
                })
            }
            console.log(error)
        })
}
export const uploadRecording = (data, fetchRecording, setAudioURL, loader, setLoader) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchRecording()
                setAudioURL('')
                setLoader({
                    ...loader,
                    uploadLoader: false
                })
            }
            else {
                setLoader({
                    ...loader,
                    uploadLoader: false
                })
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                uploadLoader: false
            })
        })
}

export const getTempVarient = (id, setState, setLoader) => (dispatch, getState) => {
    let data = {
        templateId: id
    }
    commonAxios("fetch-variation", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}

export const onVariationChange = (data, slideIndex, setPreLoader, variationPresetLoader, setVariationPresetLoader) => (dispatch, getState) => {
    setPreLoader(true)
    commonAxios("variation-change", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                let obj = { ...res.data }
                obj.audio = JSON.parse(obj.audio)
                obj.background = JSON.parse(obj.background)
                obj.layers = JSON.parse(obj.layers)
                obj.layers.forEach((layer) => {
                    if (layer.type === "text") {
                        if (!layer.textToHighlight) {
                            layer.textToHighlight = []
                        }
                    }
                    layer.animation.playAnimation = true
                })
                obj.variationId = data.newVariationId
                let dataObj = { obj, slideIndex }

                dispatch({ type: "CHANGE_VARIATION_DATA", payload: dataObj })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setVariationPresetLoader({
                ...variationPresetLoader,
                index: -1,
                loader: false
            })
            setPreLoader(false)
        }).catch((err) => {
            console.log(err)
            setVariationPresetLoader({
                ...variationPresetLoader,
                index: -1,
                loader: false
            })
            setPreLoader(false)
        })
}


export const onUploadLW = (formData, selectImage, name, setName, setLoadLogo, setLoadWatermark, type) => (dispatch, getState) => {
    commonAxios("upload-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                selectImage(res.data.path, type)
                res.data.type === "logo" ?
                    setName({
                        ...name,
                        logoName: res.data.name
                    }) :
                    setName({
                        ...name,
                        waterMarkName: res.data.name
                    })

            } else {
                dispatch(setAlert(res.msg, 'danger'))
            }

            setLoadLogo(false)
            setLoadWatermark(false)
        }).catch(() => {
            setLoadLogo(false);
            setLoadWatermark(false);
        })
}

export const onPublishVideo = (type, data, setShow, setLoader) => (dispatch, getState) => {
    commonAxios(type, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setShow(false);
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setShow(false);
            setLoader(false);
        })
}

export const deleteTile = (id, index, Swal) => (dispatch, getState) => {
    let data = {
        slideId: id
    }
    commonAxios("delete-slide", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "DELETE_SLIDE", payload: index })
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            console.log(err)
            Swal.close()
        })
}

export const onAddSlide = (data, setLoader) => (dispatch, getState) => {
    commonAxios("add-slide", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_NEW_SLIDE", payload: res.data })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)

        })
}

export const onUpdateTemplate = (data, history, setLoader, cid, presetLoader, setPresetLoader) => (dispatch, getState) => {
    commonAxios("preset-update", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = {
                    id: cid
                }
                dispatch(onFetchVideo(obj, setLoader))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(true)
            setPresetLoader({
                ...presetLoader,
                loader: false,
                index: -1
            })
        }).catch((err) => {
            console.log(err)
            setLoader(false)
            setPresetLoader({
                ...presetLoader,
                loader: false,
                index: -1
            })
        })
}

export const onFetchAnim = (setState) => (dispatch, getState) => {
    commonAxios("fetch-animation", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onFetchTransition = (setState, setLoader, memberShip) => (dispatch, getState) => {
    commonAxios("fetch-transition", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let data = [...res.data]
                // if (memberShip[memberShip.length - 1] === "unlimited") {
                //     data = data.splice(0, 70)
                // }
                // if (memberShip[memberShip.length - 1] === "unlimited-lite") {
                //     data = data.splice(0, 30)
                // }
                setState(data)
            }
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
            console.log(error)
        })
}

export const onGenerateTtsAll = (slideData, ttsLang, setDeactivateButton) => async (dispatch, getState) => {
    dispatch(setAlert("Please wait, We will inform you once the voiceover generated for all the slides", "warning"))
    let arr = [...slideData]
    let i = 0
    for (const curElem of arr) {
        // let text = ""
        // if (curElem.layers) {
        //     for (const layer of curElem.layers) {
        //         if (layer.src !== "" && layer.type === "text") {
        //             text += await layer.src
        //             text += "\n"
        //         }
        //     }
        // }
        const data = {
            engine: ttsLang.engine,
            language_id: ttsLang.language_id,
            voice_id: ttsLang.voice_id,
            text: curElem.audio.text
        }
        if (data.text !== "") {
            await commonAxios("generate-tts-new", data, dispatch, getState().auth.token)
                .then(async (res) => {
                    if (res.status) {
                        let obj = {
                            slideIndex: i,
                            url: res.data.file_url,
                            duration: res.data.duration - 3,
                            ttsData: data
                        }
                        await dispatch({ type: "ADD_TTS_IN_ALL", payload: obj })
                    }
                    if (i === slideData.length - 1) {
                        dispatch(setAlert("Voiceover generated for all slides", "success"))
                        setDeactivateButton(false)
                    }

                }).catch((error) => {
                    console.log(error)
                })
        }
        i++
    }
}

//No Axios Here

export const onSelectTemplate = (id) => (dispatch) => {
    dispatch({ type: "SELECT_TEMPLATE", payload: id })
}


// Editor 

export const onChangeCampName = (value) => (dispatch) => {
    dispatch({ type: "CHANGE_CAMP_NAME", payload: value })
}

export const onChangeBackgroundAudio = (value) => (dispatch) => {
    dispatch({ type: "CHANGE_BG_AUDIO", payload: value })
}

export const onChangeSlideText = (slideIndex, index, value) => (dispatch) => {
    dispatch({ type: "CHANGE_SLIDE_TEXT", payload: { slideIndex, index, value } })
}
export const changeColor = (value, slideIndex, index) => (dispatch) => {
    dispatch({ type: "CHANGE_TEXT_COLOR", payload: { slideIndex, index, value } })
}
export const onSelectImage = (index, data) => (dispatch) => {
    dispatch({ type: "CHANGE_SLIDE_MEDIA", payload: { index, data } })
}
export const onChangeBgAudio = (data) => (dispatch) => {
    dispatch({ type: "CHANGE_SLIDE_BG_AUDIO", payload: data })
}
export const onChangeThemeColor = (campaign, hex) => (dispatch) => {
    dispatch({ type: "CHANGE_THEME_COLOR", payload: { campaign, hex } })
}

export const updateScript = (index, slideIndex) => (dispatch) => {
    const data = { index, slideIndex }
    dispatch({ type: 'UPDATE_SLIDES_DATA', payload: data });
}
export const rearrangeTiles = (slides) => (dispatch) => {
    dispatch({ type: "REARRANGE TILES", payload: slides })
}

export const onChangeFontFamily = (slideIndex, index, value) => (dispatch) => {
    dispatch({ type: "CHANGE_TEXT_FONT", payload: { slideIndex, index, value } })
}

export const onFontFamily = (data, slideIndex, textIndex) => (dispatch) => {
    let temp = { data, slideIndex, textIndex }
    dispatch({ type: 'ON_TEXT_FONTSTYLE', payload: temp });
}

export const onFontSize = (value, slideIndex, index) => (dispatch) => {
    dispatch({ type: 'ON_TEXT_FONTSIZE', payload: { slideIndex, index, value } });
}

export const onTextBold = (value, slideIndex, index) => (dispatch) => {
    dispatch({ type: 'ON_TEXT_FONTBOLD', payload: { slideIndex, index, value } });
}

export const onTextItalic = (value, slideIndex, index) => (dispatch) => {
    dispatch({ type: 'ON_TEXT_FONTITALIC', payload: { slideIndex, index, value } });
}

export const onTextUpper = (value, slideIndex, index) => (dispatch) => {
    dispatch({ type: 'ON_TEXT_FONTTRANSFORM', payload: { slideIndex, index, value } });
}

export const onTextLower = (value, slideIndex, index) => (dispatch) => {
    dispatch({ type: 'ON_TEXT_FONTTRANSFORM', payload: { slideIndex, index, value } });
}

export const onTextUnderline = (value, slideIndex, index) => (dispatch) => {
    dispatch({ type: 'ON_TEXT_FONTDECO', payload: { slideIndex, index, value } });
}

export const onTextStrike = (value, slideIndex, index) => (dispatch) => {
    dispatch({ type: 'ON_TEXT_FONTDECO', payload: { slideIndex, index, value } });
}

export const applyToAllSlide = (data) => (dispatch) => {
    dispatch({ type: 'APPLY_TO_ALL', payload: data });
}

export const onLayerTextAlign = (value, slideIndex, index) => (dispatch) => {
    dispatch({ type: 'ON_TEXT_FONTALIGN', payload: { slideIndex, index, value } });
}

export const onTextLineHeight = (value, slideIndex, index) => (dispatch) => {
    dispatch({ type: 'ON_TEXT_FONTLINEHEIGHT', payload: { slideIndex, index, value } });
}

export const textLayerUpdate = (text, slideIndex, layerIndex) => (dispatch) => {
    const data = { text, slideIndex, layerIndex }
    dispatch({ type: "UPDATE_LAYER_TEXT", payload: data })
}

export const updateFontBgColor = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTBGCOLOR', payload: temp });
}

export const removeFontBgColor = (selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let data = { selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'REMOVE_TEXT_FONTBGCOLOR', payload: data });
}

export const textLayerAdd = (slideIndex) => (dispatch) => {
    let randomId = Math.floor(Math.random() * 1000000000000000).toString()
    let layer = {
        id: randomId,
        type: "text",
        src: "You Can Edit Text Here",
        style: {
            fontColor: "#FFFFFF",
            fontFamily: "Noto Sans",
            background: "",
            fontSize: "22",
            textAlign: "center",
            fontWeight: false,
            fontStyle: false,
            textTransform: "",
            textDecoration: "none",
            lineHeight: 1,
            opacity: 1
        },
        position: {
            left: 150,
            top: 200
        },
        animation: {
            name: "",
            delay: "",
            duration: "",
            playAnimation: false
        },
        size: {
            width: 300,
            height: 50
        },
        isSelected: true,
        meta: ""
    }
    const data = { layer, slideIndex }
    dispatch({ type: "ADD_TEXT_LAYER", payload: data })
}

export const updateIOStatus = (status, type) => (dispatch) => {
    if (type === "intro") {
        dispatch({ type: 'SET_INTRO_STATUS', payload: status })
    } else {
        dispatch({ type: 'SET_OUTRO_STATUS', payload: status })
    }
}

export const removeIO = (type) => (dispatch) => {
    if (type === "intro") {
        dispatch({ type: 'REMOVE_INTRO' })
    } else {
        dispatch({ type: 'REMOVE_OUTRO' })
    }
}

export const updateIntroOutro = (type, data, setLoader) => (dispatch) => {

    let au = document.createElement("video")
    au.src = data.url
    au.addEventListener('loadedmetadata', function () {
        let duration = Number((au.duration).toFixed(1));
        data = {
            ...data,
            duration: duration
        }
        setLoader(false)
        dispatch({ type: type, payload: data });
    }, false);

}
export const updateStatus = (enable, type) => (dispatch) => {
    const data = { enable, type }
    dispatch({ type: 'UPDATE_STATUS_L&W', payload: data })
}

export const removeLogoWatermark = (type) => (dispatch) => {
    dispatch({ type: type })
}

export const updateLogoWatermark = (type, data, dimension) => (dispatch) => {
    const val = { data, dimension }
    dispatch({ type: type, payload: val });
}

export const bgVolumeChange = (type, value) => (dispatch) => {
    dispatch({ type: type, payload: value });
}
export const aiVolumeChange = (type, value, selectedSlideIndex) => (dispatch) => {
    const data = { value, selectedSlideIndex }
    dispatch({ type: type, payload: data });
}

export const unselectLayers = (slideIndex) => (dispatch) => {
    dispatch({ type: "UNSELECT_LAYERS", payload: slideIndex })
}

export const endAnimation = (slideIndex, layerIndex) => (dispatch) => {
    const data = { slideIndex, layerIndex }
    dispatch({ type: "END_ANIMATION", payload: data })
}

export const onUpdateLogoInfo = (height, width) => (dispatch) => {
    let data = { height, width }
    dispatch({ type: "UPDATE_LOGO_HW", payload: data })
}

export const layerDuplicate = (slideIndex, layerData, layerIndex) => (dispatch) => {
    layerData.id = Math.floor(Math.random() * 1000000000000000).toString()
    const data = { slideIndex, layerData, layerIndex }
    dispatch({ type: "COPY_LAYER", payload: data })
}
export const updateLayerPos = (slideIndex, layerIndex, place) => (dispatch) => {
    const data = { slideIndex, layerIndex }
    if (place === "down") {
        dispatch({ type: "LAYER_DOWN", payload: data })
    } else {
        dispatch({ type: "LAYER_UP", payload: data })
    }
}

export const onUndo = (slideId) => (dispatch) => {
    dispatch({ type: "UNDO", payload: slideId })
}
export const onRedo = (slideId) => (dispatch) => {
    dispatch({ type: "REDO", payload: slideId })
}

export const removeLayer = (selectedSlide, slideIndex) => (dispatch) => {
    const layerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
    const data = { layerIndex, slideIndex }
    dispatch({ type: "REMOVE_LAYER", payload: data })
}

export const updateLastAction = (data) => (dispatch) => {
    dispatch({ type: "UPDATE_LAST_ACTION", payload: data })
}

export const updatePresent = (data) => (dispatch) => {
    dispatch({ type: "SET_PRESENT", payload: data })
}

export const rePositionLayer = (t, l, slideIndex, layerIndex) => (dispatch) => {
    const data = { t, l, slideIndex, layerIndex }
    dispatch({ type: "REPOSITION_LAYER", payload: data })
}

export const resizeLayer = (w, h, x, y, slideIndex, layerIndex) => (dispatch) => {
    const data = { w, h, x, y, slideIndex, layerIndex }
    dispatch({ type: "RESIZE_LAYER", payload: data })
}

export const updateSelectedLayer = (index, slideIndex) => (dispatch) => {
    const data = { index, slideIndex }
    dispatch({ type: "UPDATE_LAYER", payload: data })
}

export const onPlay = (slideIndex) => (dispatch) => {
    dispatch({ type: "ONPLAY_SLIDE", payload: slideIndex })
}

export const onRemovePlaySlide = (slideIndex) => (dispatch) => {
    dispatch({ type: "STOP_PLAYING_SLIDE", payload: slideIndex })
}

export const updateSelectedTiles = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_SELECTED_TILES', payload: data });
}

export const updateTextHeight = (height, slideIndex, layerIndex) => (dispatch) => {
    let data = { height, layerIndex, slideIndex }
    dispatch({ type: "UPDATE_TEXT_HEIGHT", payload: data })
}

export const onUpdateWatermarkInfo = (height, width) => (dispatch) => {
    let data = { height, width }
    dispatch({ type: "UPDATE_WATERMARK_HW", payload: data })
}

export const onGenerateFile = (data, setOutputImage, setCropLoader) => (dispatch, getState) => {
    commonAxios("generate-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setOutputImage(res.data);
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setCropLoader(false);
        }).catch(err => {
            console.log(err)
            setCropLoader(false);
        })
}

export const onVideoCrop = (formData, setOutputVideo, setOutputThumb, setCropLoader) => (dispatch, getState) => {
    commonAxios("video-crop", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setOutputVideo(res.data.video);
                setOutputThumb(res.data.thumbnail);
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setCropLoader(false);
        }).catch((err) => {
            console.log(err)
            setCropLoader(false);
        })
}

export const onLoadMedia = (data, imageData, setImageData, search, setSearch, page, setError, setLoader) => (dispatch, getState) => {
    commonAxios("load-library-images", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...imageData];
                if (page === 1) {
                    arr = []
                }
                setImageData(arr.concat(res.data));

            } else {
                setError(`Oops, No data found for the Keyword "${search.keyword}"`);
            }
            setLoader(false);
            setSearch({ ...search, page: page });
        }).catch(err => {
            setLoader(false);
            console.log(err)
        })
}

export const updateBackground = (file, index, type, hex) => (dispatch) => {
    let data = { file, index }
    if (type === "image") {
        dispatch({ type: 'UPDATE_BG_IMAGE', payload: data })
    }
    else if (type === "video") {
        dispatch({ type: "UPDATE_BG_VIDEO", payload: data })
    }
    else {
        data = { file, hex, index }
        dispatch({ type: "UPDATE_BG_COLOR", payload: data })
    }
}


export const onFetchFiles = (data, type, setUploadImages, setUploadVideos, setDataFiles) => (dispatch, getState) => {
    commonAxios("fetch-user-uploaded-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let val = res.data
                // val = val.reverse()
                if (type === "images") {
                    setUploadImages(val)
                }
                else if (type === "video") {
                    setUploadVideos(val)
                }
                else {
                    setDataFiles(val)
                }
            }
            else {
                if (type === "images") {
                    setUploadImages([])
                }
                else if (type === "video") {
                    setUploadVideos([])
                }
                else {
                    setDataFiles([])
                }
            }
        }).catch((error) => {
            console.log(error)
        })
}

export const onUploadFileVideo = (data, fetchFiles, type, setPercent, setLoader) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchFiles(type)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setPercent(0)
            setLoader(false)
        }).catch(() => {
            setPercent(0)
            setLoader(false)
        })
}


export const addBgMusic = (url, selectedSlideIndex, dur) => (dispatch) => {
    const data = { url, selectedSlideIndex, dur }
    dispatch({ type: 'ADD_BGAUDIO_DATA', payload: data })
    dispatch(setAlert("Music applied successfully!", "success"))
}


export const removeBgAudioFromSlides = (slideIndex) => (dispatch) => {
    dispatch({ type: 'REMOVE_BGAUDIO_SLIDES', payload: slideIndex })
    dispatch(setAlert("Music removed successfully!", "success"))
}

export const removeTtsFromSlides = (slideIndex) => (dispatch) => {

    dispatch({ type: 'REMOVE_TTSAUDIO_SLIDES', payload: slideIndex })
    dispatch(setAlert("Voiceover removed successfully", "success"))
}


export const setTtsData = (meta, selectedSlideIndex, enable) => (dispatch) => {
    const data = { meta, selectedSlideIndex, enable }
    dispatch({ type: 'ADD_TTS_DATA', payload: data });
    if (meta.src !== "") {
        dispatch(setAlert('Voiceover applied successfully', 'success'));
        dispatch(addSlideDuration(meta.duration))
    }
}

export const addSlideDuration = (value) => (dispatch) => {
    dispatch({ type: 'UPDATE_SLIDE_DURATION', payload: value })
}


export const addMediaLayer = (val, slideIndex, type, dimension) => (dispatch) => {
    let randomId = Math.floor(Math.random() * 1000000000000000).toString()
    let width = 280, height = 150
    if (type === "video") {
        if (val.width > 720 || val.height > 720) {
            width = Math.floor(val.width / 9)
            height = Math.floor(val.height / 9)
        } else {
            width = Math.floor(val.width / 3)
            height = Math.floor(val.height / 3)
        }
    }
    if (dimension === "1080x1080") {
        width = 280
        height = 280
    } else if (dimension === "1080x1920") {
        width = 150
        height = 280
    }

    const data = {
        objData: {
            id: randomId,
            type: type,
            layerType: "custom",
            style: {
                color: "#FFFFFF",
                fontFamily: "Noto Sans",
                background: "",
                fontSize: "17",
                textAlign: "left",
                fontWeight: false,
                fontStyle: false,
                textTransform: "lowercase",
                textDecoration: "none",
                lineHeight: 1,
                opacity: 1
            },
            position: {
                left: 30,
                top: 30
            },
            animation: {
                name: "",
                delay: "0.5s",
                duration: "1s",
                playAnimation: false
            },
            size: {
                width: width ? width : 280,
                height: height ? height : 150
            },
            isSelected: true,
            src: val.url,
            meta: "",
            mute: true
        },
        slideIndex
    }
    dispatch({ type: "ADD_MEDIA_LAYER", payload: data })
}

export const onDeleteMediaa = (formData, mediaType, fetchFiles, Swal) => (dispatch, getState) => {
    commonAxios("delete-media-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'));
                mediaType === "image" ?
                    fetchFiles("images") :
                    fetchFiles("video")
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            Swal.close()
        })
}


export const onDeleteMediaAudio = (formData, type, fetchFiles, Swal) => (dispatch, getState) => {
    commonAxios("delete-media-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'));
                fetchFiles(type)
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            if (Swal) {
                Swal.close()
            }
        }).catch((error) => {
            console.log(error)
            if (Swal) {
                Swal.close()
            }
            dispatch(setAlert(error.msg, 'danger'));
        })
}


export const updateBgg = (file, index, type, hex) => (dispatch) => {
    let data = { file, index }
    if (type === "image") {
        dispatch({ type: 'UPDATE_BG_IMAGE', payload: data })
    }
    else {
        dispatch({ type: "UPDATE_BG_VIDEO", payload: data })
    }

}

export const updateMediaLayer = (val, slideIndex, layerIndex, type) => (dispatch) => {
    if (type === "image") {
        val = val.url
        let data = { val, slideIndex, layerIndex }
        dispatch({ type: "UPDATE_LAYER_IMAGE", payload: data })
    } else {
        // if (val.width > 720 || val.height > 720) {
        //     val.width = Math.floor(val.width / 9)
        //     val.height = Math.floor(val.height / 9)
        // } else {
        //     val.width = Math.floor(val.width / 3)
        //     val.height = Math.floor(val.height / 3)
        // }

        let data = { val, slideIndex, layerIndex }
        dispatch({ type: "UPDATE_LAYER_VIDEO", payload: data })
    }
}

export const updateAnimation = (val, slideIndex, layerIndex, name) => (dispatch) => {
    let data = { val, slideIndex, layerIndex, name }
    dispatch({ type: "UPDATE_ANIMTION", payload: data })
}

export const removeTransition = (slideIndex) => (dispatch) => {
    dispatch({ type: "REMOVE_TRANSITION", payload: slideIndex })
}

export const addTransition = (name, index) => (dispatch) => {
    let data = { name, index }
    dispatch({ type: "ADD_TRANSITION", payload: data })
    dispatch(setAlert("Transition updated successfully !", "success"))
}
export const onSetPollyLang = (langCode, slideIndex) => (dispatch) => {
    let data = { langCode, slideIndex }
    dispatch({ type: "SET_LANG_CODE", payload: data })
}

export const onSetPollyVoice = (vCode, vId, vType, slideIndex) => (dispatch) => {
    let data = { vCode, vId, vType, slideIndex }
    dispatch({ type: "SET_VOICE_CODE", payload: data })
}

export const onEditTTSText = (text, slideIndex) => (dispatch) => {
    let data = { text, slideIndex }
    dispatch({ type: "SET_TTS_TEXT", payload: data })
}

export const updateTtsUrl = (val, index) => (dispatch) => {
    const data = { val, index }
    dispatch({ type: "UPDATE_GENERATED_TTS", payload: data })
    dispatch(setAlert('Voiceover applied successfully', 'success'));
    dispatch(addSlideDuration(val.duration))
}

// export const onSelectInnerBackground = (slideIndex, backgroundIndex) => (dispatch) => {
//     let data = { slideIndex, backgroundIndex }
//     dispatch({ type: "ON_SELECT_INNERBACK", payload: data })
// }

export const onChangeBackgroundInterval = (slideIndex) => (dispatch) => {
    dispatch({ type: "CHANGE_BG_INTERVAL", payload: slideIndex })
}

export const onUpdateCtaPos = (data) => (dispatch) => {
    dispatch({ type: "UPDATE_POSITION_PROMO", payload: data })
}
export const onEnableCtaData = (data) => (dispatch) => {
    dispatch({ type: "ENABLE_PROMO", payload: data })
}

export const onUpdateCta = (data) => (dispatch) => {
    dispatch({ type: "UPDATE_CTA_PROMO", payload: data })
}


import React, { useCallback, useRef } from "react";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import Navbar from "../Navbar";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { onFetchVideo, onRenderVideo } from "../../Actions/CampaignAction";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { Player } from "@remotion/player";
import Editor from "./Editor/Editor";
import General from "./RightPanel/General/General";
import Template from "./RightPanel/Template/Template";
import Media from "./RightPanel/Media/Media";
import Text from "./RightPanel/Text/Text";
import { useState } from "react";
import Setting from "./RightPanel/Settings/Setting";
import Preview from "./Editor/preview/Preview";
import { MdPreview } from "react-icons/md"
import Background from "./RightPanel/Background/Background";
import Transition from "./RightPanel/Transition/Transition";
import { Modal } from "react-bootstrap";
import modalCloseIcon from "../../images/modal-close.png";
import { BufferManager } from "./Editor/Buffer/BufferManager";
import { AbsoluteFill } from "remotion";



const EditVideo = () => {

    const playerRef = useRef(null);
    const [buffering, setBuffering] = useState(false);
    const pausedBecauseOfBuffering = useRef(false);

    const campaign = useSelector(state => state.campaign.data)
    const selectedTemp = useSelector((state) => state.campaign.data.templateId);

    const navigate = useNavigate()
    const location = useLocation();
    const cId = queryString.parse(location.search).cId;
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true)
    const [duration, setDuration] = useState(0)
    const [preLoader, setPreLoader] = useState(false)
    const [showPreview, setShowPreview] = useState(false)
    const [deactivateButton, setDeactivateButton] = useState(false)
    const [renderLoader, setRenderLoader] = useState(false)


    const handleClosePreviewModal = () => setShowPreview(false)

    const [active, setActive] = useState({
        music: false,
        transition: false
    })


    const handleActive = (type) => {
        if (type === "music" && active.music === false) {
            setActive({
                ...active,
                music: true
            })
        }
        else if (type === "transition" && active.transition === false) {
            setActive({
                ...active,
                transition: true
            })
        }
    }

    const handleRenderVideo = () => {
        let data = {
            id: cId,
        }
        setRenderLoader(true)
        dispatch(onRenderVideo(data, navigate, setRenderLoader));
    };

    const fetchVideo = () => {
        let data = {
            id: cId
        }
        dispatch(onFetchVideo(data, setLoader))
    };

    useEffect(() => {
        if (cId) {
            fetchVideo();
        }
    }, [cId])

    useEffect(() => {
        let introDuration = campaign.intro?.enable ? +campaign.intro.duration * 30 : 0
        let outroDuration = campaign.outro?.enable ? +campaign.outro.duration * 30 : 0
        let dur = 0 + introDuration + outroDuration
        let audioDelay = 30
        if (campaign.slides?.length > 0) {
            campaign.slides.forEach((element, index) => {
                dur += +element.duration * 30
                if (index === 0) {
                    dur += campaign?.intro?.enable === true ? audioDelay : 0
                } else if (index === campaign.slides?.length - 1) {
                    // 1 sec extra for proper voice over
                    dur += campaign?.outro?.enable === true ? 0 : audioDelay
                } else {
                    dur += audioDelay
                }
            });
            setDuration(dur)
        }
    }, [campaign])

    // removing bacon from editor

    let div = document.getElementById("beacon-container")

    useEffect(() => {
        if (div) {
            div.style.display = "none"
        }

        return () => {
            if (div) {
                div.style.display = "block"
            }
        }
    }, [div])


    const onBuffer = useCallback(() => {
        setBuffering(true);

        playerRef.current?.pause();
        pausedBecauseOfBuffering.current = true;
    }, []);

    const onContinue = useCallback(() => {
        setBuffering(false);

        // Play only if we paused because of buffering
        if (pausedBecauseOfBuffering.current) {
            pausedBecauseOfBuffering.current = false;
            playerRef.current?.play();
        }
    }, []);


    // Add this to your component rendering the <Player>
    const renderPoster = useCallback(() => {
        if (buffering) {
            return (
                <AbsoluteFill
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <i className="fa fa-spinner fa-spin" style={{ fontSize: "75px", color: "#e0712c" }} />
                </AbsoluteFill>
            );
        }

        return null;
    }, [buffering]);

    return (

        loader ?
            <div className="loader-sec">
                <div className="loader">
                </div>
            </div> :
            <>
                <TitleBar title="Editor" />
                <Navbar />

                <div className="siteWrap">

                    <div className="projectTitle-wrap">
                        <div className="container">
                            <div className="projectTitle my-projectTitle">
                                <div className="projectTitle-left">
                                    <h2>Edit Video</h2>
                                </div>
                                <div className="projectTitle-right my-projectTitle-right">
                                    <button className="demoLink" onClick={() => setShowPreview(true)}><MdPreview />Preview</button>
                                    <button
                                        className="demoLink "
                                        onClick={handleRenderVideo}
                                        style={{ background: deactivateButton ? "gray" : "" }}
                                        disabled={deactivateButton}
                                    >
                                        {renderLoader ?
                                            <>
                                                <i className="fa fa-spinner fa-spin mx-1" />
                                                Rendering
                                            </> :
                                            <>
                                                <i className="fa fa-film" aria-hidden="true"></i>
                                                Render
                                            </>
                                        }



                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="edit-wrap">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-8 col-lg-7 col-md-12">
                                    <div className="edit-wrap-left">
                                        <Preview
                                            campaign={campaign}
                                            logo={campaign.logo}
                                            watermark={campaign.watermark}
                                            preLoader={preLoader}
                                        />

                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-5 col-md-12 p-0">
                                    <div className={`${campaign.campaign.emotion.dimention === "9:16" ? "edit_wrap_right_9X16" : "edit_wrap_right_9X16_hide"} edit-wrap-right`}>
                                        <Tab.Container defaultActiveKey="first">
                                            <Nav variant="b" className="inTabNav">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first">General</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fifth">Text</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second">Templates</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="third">Media</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fourth">Background</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="sixth">Settings</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item onClick={() => handleActive("transition")}>
                                                    <Nav.Link eventKey="seventh">Transition</Nav.Link>
                                                </Nav.Item>
                                            </Nav>

                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <General
                                                        campaign={campaign}
                                                        setDeactivateButton={setDeactivateButton}
                                                    />
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="second">
                                                    <Template
                                                        selectedTemp={selectedTemp}
                                                        campaign={campaign}
                                                        setLoader={setLoader}
                                                        setPreLoader={setPreLoader}
                                                    />
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="third">
                                                    <Media slideData={campaign.slides} />
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="fourth">
                                                    <Background slideData={campaign.slides} />
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="fifth">
                                                    <Text campaign={campaign} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="sixth">
                                                    <Setting campaign={campaign} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="seventh">
                                                    <Transition
                                                        transActive={active.transition}
                                                        slideData={campaign.slides}
                                                    />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div >

                <Modal className={`${campaign.campaign.emotion.dimention === "9:16" ? "long_modal" : campaign.campaign.emotion.dimention === "16:9" ? "preview_modal" : "preview_modal"} VideoModal`} show={showPreview} onHide={handleClosePreviewModal} centered>
                    <Modal.Body>
                        <div onClick={handleClosePreviewModal} className="vidClose">
                            <img alt="" src={modalCloseIcon} />
                        </div>
                        <BufferManager onBuffer={onBuffer} onContinue={onContinue}>
                            <Player
                                ref={playerRef}
                                component={Editor}
                                inputProps={{
                                    slideData: campaign,
                                    multiplyNum: (campaign.campaign.emotion.dimention === "9:16" || campaign.campaign.emotion.dimention === "16:9") ? 1 : 1
                                }}
                                durationInFrames={duration}
                                compositionWidth={campaign.campaign.emotion.dimention === "9:16" ? 360 : campaign.campaign.emotion.dimention === "16:9" ? 640 : 640}
                                compositionHeight={campaign.campaign.emotion.dimention === "9:16" ? 640 : campaign.campaign.emotion.dimention === "16:9" ? 360 : 360}
                                fps={30}
                                controls={true}
                                style={{ margin: "auto", border: "1px solid #201f1f" }}
                                // Add these two props to the Player
                                showPosterWhenPaused
                                renderPoster={renderPoster}
                            />
                        </BufferManager>
                    </Modal.Body>
                </Modal >
                <Footer />
            </>
    )
}

export default EditVideo;
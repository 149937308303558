import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { useDispatch } from 'react-redux'
import { unselectLayers } from '../../../../Actions/CampaignAction'

const Background = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [backgroundData, setBackgroundData] = useState({
        type: "image",
        src: "",
        animation: ""
    })

    const layersUnselect = () => {
        const isLayerSelected = props.selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (isLayerSelected !== -1) {
            dispatch(unselectLayers(props.selectedSlideIndex))
        }
    }

    useEffect(() => {
        const selectedBackgroundData = props.background[0]
        if (selectedBackgroundData) {
            setBackgroundData({
                ...backgroundData,
                type: selectedBackgroundData.type,
                src: selectedBackgroundData.src,
                animation: selectedBackgroundData.animation
            })
            if (!props.selectedSlide.isPlaying) {
                setLoader(true)
            }
        }
    }, [props.background]);

    return (
        backgroundData.type === "video" ?
            <div
                style={{ width: "100%", height: "100%", position: 'absolute', top: 0, }}
                onClick={layersUnselect}
                className={props.selectedSlide.isPlaying ? backgroundData.animation : ""}
            >
                {loader ?
                    <div className="loader-center-temp1" style={props.mediaType === "bgImage" ? { background: 'linear-gradient( rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.7)50%)', width: "100%", height: "100%", position: 'absolute', zIndex: "2" } : {}}>
                        <i className="fa fa-spinner fa-spin mr-2" style={{ fontSize: "50px" }} />
                    </div> : ''}
                <ReactPlayer
                    url={backgroundData.src}
                    playing={true}
                    loop={true}
                    // className={` ${props.mediaType === "pImage" ? 'video-player' : ''}`}
                    onPlay={() => setLoader(false)}
                    muted
                    style={{ visibility: `${loader ? 'hidden' : 'visible'}` }}
                />
            </div> :
            backgroundData.type === "image" ?
                <div
                    style={{ width: "100%", height: "100%", position: 'absolute', top: 0, textAlign: "center" }}
                    onClick={layersUnselect}
                    className={props.selectedSlide.isPlaying ? backgroundData.animation : ""}
                >
                    {loader ?
                        <div className="loader-center-temp1" style={props.mediaType === "bgImage" ? { background: 'linear-gradient( rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.7)50%)', width: "100%", height: "100%", position: 'absolute', zIndex: "2" } : {}} >
                            <i className="fa fa-spinner fa-spin mr-2" style={{ fontSize: "50px" }} />
                        </div> : ''}
                    <img
                        className='editor-background-img'
                        src={backgroundData.src}
                        alt=''
                        onLoad={() => setLoader(false)}
                        style={{ visibility: `${loader ? 'hidden' : 'visible'}`, }}
                    />
                </div> :
                <div style={{ width: "100%", height: "100%", position: 'absolute', top: 0, background: `${backgroundData.src}` }} onClick={layersUnselect}>
                </div>
    )
}

export default Background
import React, { useState } from 'react';
import { BsTrash3 } from "react-icons/bs"
import SweetAlertTwo from '../SweetAlertTwo';
import { onDeleteSocials } from '../../Actions/SocialAction';
import { useDispatch } from 'react-redux';

const SocialConnected = (props) => {
    const dispatch = useDispatch()
    const [info, setInfo] = useState({
        id: -1,
        type: props.type,
        enable: false
    })

    const deleteIntegration = (id) => {
        setInfo({
            ...info,
            id: id,
            enable: true
        })
    }

    const handleCancle = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }

    const deleteAutoRes = (Swal) => {
        let data = {
            id: info.id,
        }
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
        let url = "delete-integration"
        if (props.type === "facebook" || props.type === "youtube") {
            url = "delete-social-network"
        }
        dispatch(onDeleteSocials(url, data, props.fetchIntegration, Swal))
    }


    return (
        <div className="col-sm-6 col-lg-4">
            <div className="integr-single connected">
                <div className="connection-box-main">
                    <div className="connection-img"><img src={props.icon} width="56px" alt="facebook" /></div>
                    <div className="connection-txt">
                        <div className="noConnect">
                            <div className="youbtube-connection">
                                <div className="youtube-content">
                                    <h6 className='text-capitalize'>{props.type}</h6>
                                    <p className='text-capitalize'>{props.data.username}</p>
                                    <p>Added on - {props.data.created}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span className="integr-del cursor-pointer" onClick={() => deleteIntegration(props.data.id)}><BsTrash3 /></span>
                <span className="tag-connected">Connected</span>
            </div>

            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#3085d6'
                cancelButtonColor='#d33'
                confirmButtonText='Yes, disconnect it!'
                confirmTitle='Disconnecting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={deleteAutoRes}
                onCancel={handleCancle}
            />
        </div>
    );
}

export default SocialConnected;

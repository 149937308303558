import { useCallback, useMemo, useRef, createContext } from "react";


export const BufferContext = createContext({
    // By default, do nothing if the context is not set, for example in rendering
    canPlay: () => { },
    needsToBuffer: () => { },
});




export const BufferManager = ({ children, onBuffer, onContinue }) => {
    const bufferState = useRef({});
    const currentState = useRef(false);

    const sendEvents = useCallback(() => {
        let previousState = currentState.current;
        currentState.current = Object.values(bufferState.current).some(Boolean);

        if (currentState.current && !previousState) {
            onBuffer();
        } else if (!currentState.current && previousState) {
            onContinue();
        }
    }, [onBuffer, onContinue]);

    const canPlay = useCallback(
        (id) => {
            bufferState.current[id] = false;
            sendEvents();
        },
        [sendEvents]
    );

    const needsToBuffer = useCallback(
        (id) => {
            bufferState.current[id] = true;
            sendEvents();
        },
        [sendEvents]
    );

    const bufferEvents = useMemo(() => {
        return {
            canPlay,
            needsToBuffer,
        };
    }, [canPlay, needsToBuffer]);

    return (
        <BufferContext.Provider value={bufferEvents} >
            {children}
        </BufferContext.Provider>
    );
};
import React from "react";
import img1 from "../../images/1.png"
import img2 from "../../images/2.png"
import img3 from "../../images/3.png"
import img4 from "../../images/4.png"
import img5 from "../../images/5.png"
import { useSelector } from "react-redux";
import { appName } from "../../Global/Global";


const DashboardAbout = () => {
    const rebrandData = useSelector(state => state.rebrand.data)
    return (
        <>
            <section className="featureSec">
                <div className="container-fluid">
                    <div className="featureSec-wrap">
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        {/* <h6>ULTIMATE SOLUTION TO DRIVE PERPETUAL LEADS</h6> */}
                                        <h2> <span className="add_color">Efficient </span>Video Ad Creation</h2>

                                        <ul className="dash-list">
                                            <li>{rebrandData ? rebrandData.name : appName} streamlines the process of creating engaging video ads.</li>
                                            <li>Its AI-driven system collects relevant business information and generates a script based on user-selected styles.</li>
                                            <li>This saves businesses significant time and effort, allowing them to produce impactful ads quickly and easily.   </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={img1} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        {/* <h6>AUTOMATIC AD CREATION</h6> */}
                                        <h2>Automated Script and Storyboard <span className="add_color">Generation</span> </h2>
                                        <ul className="dash-list">
                                            <li>The software automatically generates advert scripts and storyboards using AI technology.</li>
                                            {/* <li>Enables businesses to focus on their core competencies and higher-priority tasks while {rebrandData ? rebrandData.name : appName} handles routine or specialized tasks.</li> */}
                                            <li>This ensures a cohesive and engaging video narrative that's customized to the business's brand, eliminating the need for in-house scriptwriting or storyboarding expertise.</li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={img2} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        {/* <h6>INSTANT VIDEO LEAD GENERATION WEBSITE</h6> */}
                                        <h2>Multilingual <span className="add_color">Support</span> </h2>
                                        <ul className="dash-list">
                                            <li>{rebrandData ? rebrandData.name : appName} supports a variety of languages, which is crucial for businesses targeting global audiences.</li>
                                            <li>The platform offers AI-generated voice-overs in English, Spanish, French, and Hindi, ensuring that videos can be understood and enjoyed by a diverse range of viewers.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={img3} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        {/* <h6>INSTANT VIDEO LEAD GENERATION WEBSITE</h6> */}
                                        <h2> <span className="add_color">Full Customization</span> & Control</h2>
                                        <ul className="dash-list">
                                            <li>Users have complete control over the customization of their video ads.</li>
                                            <li>They can adjust text, colors, fonts, and add images or video clips as needed. </li>
                                            <li>This flexibility allows businesses to ensure their adverts align perfectly with their brand identity.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={img4} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        {/* <h6>INSTANT VIDEO LEAD GENERATION WEBSITE</h6> */}

                                        <h2>Easy Publishing and  <span className="add_color">Integration</span> </h2>
                                        <ul className="dash-list">
                                            <li>With {rebrandData ? rebrandData.name : appName}, publishing your video ads is a breeze. Users can upload their ads directly to YouTube and Facebook from within the platform, or download them for offline use.</li>
                                            <li>The software also integrates seamlessly with various other apps and platforms, making it easy to incorporate video ads into any marketing strategy.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={img5} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default DashboardAbout;
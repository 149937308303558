
import React, { useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import BgImageMedia from './BgImageMedia';
import BgVideoMedia from './BgVideoMedia';
import BusinessGraphic from './BusinessGraphic';
import UploadMedia from './UploadMedia';
import BgColor from './BgColor';

const Background = ({ slideData }) => {

    const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1")
    const selectedSlide = slideData.find(({ isSelected }) => isSelected === "1")

    return (
        <div className="innertab-scroll" style={{ padding: "0 0px" }}>
            <div className="mediaList">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav variant="b" className="inTabNav">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Image</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Video</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="color-tab">Color</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="third">Library</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="fourth">Business Graphic</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content >
                        <Tab.Pane eventKey="first">
                            <BgImageMedia
                                selectedSlideIndex={selectedSlideIndex}
                                selectedSlide={selectedSlide}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <BgVideoMedia
                                selectedSlideIndex={selectedSlideIndex}
                                selectedSlide={selectedSlide}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="color-tab">
                            <BgColor
                                selectedSlideIndex={selectedSlideIndex}
                                selectedSlide={selectedSlide}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <UploadMedia
                                selectedSlideIndex={selectedSlideIndex}
                                selectedSlide={selectedSlide}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">
                            <BusinessGraphic
                                selectedSlideIndex={selectedSlideIndex}
                                selectedSlide={selectedSlide}
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>

            </div>
        </div>
    )
}

export default Background
import React from 'react'
import { AbsoluteFill, Video, Img, Sequence } from 'remotion';
import 'animate.css/animate.min.css';
import { Animation } from 'remotion-animation';

const LogoLayer = ({ index, src, layer, multiplyNum }) => {
    return (
        <Sequence from={Number(layer.animation.delay.toString().replace("s", "")) * 30 + 30}>
            <AbsoluteFill>
                <Animation style={{ position: "absolute", left: layer.position.left * multiplyNum, top: layer.position.top * multiplyNum }} duration={Number(layer.animation.duration.toString().replace("s", ""))} animateName={layer.animation.name.replace("animate__", "")} >
                    < Img
                        style={{ height: layer.size.height * multiplyNum, width: layer.size.width * multiplyNum }}
                        src={src}
                    />
                </Animation>
            </AbsoluteFill >
        </Sequence>
    )
}

export default LogoLayer

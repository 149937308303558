import React, { useCallback } from 'react'
import { useState } from 'react';
import { AbsoluteFill, Sequence, Series } from 'remotion';
import TransitionSeries from 'remotion-transition-series/lib/TransitionSeries';
import SlideBackground from './layers/SlideBackground';
import AudioLayer from './layers/AudioLayer';
import Intro from './layers/Intro';
import VideoLayer from './layers/VideoLayer';
import ShapeLayer from './layers/ShapeLayer';
import TextLayer from './layers/TextLayer';
import Promo from './layers/Promo';
import Logo from './layers/Logo';
import { SlidingDoors } from './Transitions/SlidingDoors';
import { CircularWipe } from './Transitions/CircularWipe';
import Dissolve from './Transitions/Dissolve';
import { FadeThroughColor } from './Transitions/FadeThroughColor';
import { LinearWipe } from './Transitions/LinearWipe';
import { Pan } from './Transitions/Pan';
import { Slide } from './Transitions/Slide';
import { useMemo } from 'react';
import { useEffect } from 'react';
import LogoLayer from './layers/LogoLayer';

const Editor = ({ slideData, multiplyNum = 1 }) => {

    const [transitionDuration, setTransitionDuration] = useState(30);
    const [audioDelay, setAudioDelay] = useState(30);
    const [repeatBackground, setRepeatBackground] = useState([]);
    const [totalDuration, setTotalDuration] = useState(5000);



    const fetchData = useCallback(async () => {

        if (slideData.slides?.length > 0) {
            let dur = +slideData.duration * 30
            slideData.slides.forEach((element, index) => {
                if (index === 0) {
                    dur += slideData?.intro?.enable === true ? audioDelay : 0
                } else if (index === slideData.slides?.length - 1) {
                    // 1 sec extra for proper voice over
                    dur += slideData?.outro?.enable === true ? 0 : audioDelay
                } else {
                    dur += audioDelay
                }
            });
            setTotalDuration(dur)
        }

        // repeat background code
        let arr = []
        slideData.slides.forEach((slide) => {
            let backgroundDuration = +slide.background.length * 6;
            let repeatCount = 1;
            while (backgroundDuration < +slide.duration + 30) {
                backgroundDuration += +slide.background.length * 6;
                repeatCount++;
            }
            arr.push(repeatCount)
        })
        setRepeatBackground(arr)

    }, [slideData]);


    const renderTransitionComponent = useCallback((transition, props) => {
        switch (transition) {
            case "CircularWipeIn":
                return <CircularWipe {...props} direction="in" />;
            case "CircularWipeOut":
                return <CircularWipe {...props} direction="out" />;
            case "Dissolve":
                return <Dissolve  {...props} />;
            case "FadeThroughColor":
                return <FadeThroughColor {...props} />;
            case "LinearWipe60":
                return <LinearWipe {...props} angle={60} />;
            case "LinearWipe90":
                return <LinearWipe {...props} angle={90} />;
            case "LinearWipe120":
                return <LinearWipe {...props} angle={120} />;
            case "LinearWipe240":
                return <LinearWipe {...props} angle={240} />;
            case "LinearWipe300":
                return <LinearWipe {...props} angle={300} />;
            case "PanLeft":
                return <Pan {...props} direction="left" />;
            case "PanRight":
                return <Pan {...props} direction="right" />;
            case "PanUp":
                return <Pan {...props} direction="up" />;
            case "PanDown":
                return <Pan {...props} direction="down" />;
            case "SlideLeft":
                return <Slide {...props} direction="left" />;
            case "SlideRight":
                return <Slide {...props} direction="right" />;
            case "SlideUp":
                return <Slide {...props} direction="up" />;
            case "SlideDown":
                return <Slide {...props} direction="down" />;
            case "SlidingDoorsOpen":
                return <SlidingDoors {...props} direction="open" angle={0} />;
            case "SlidingDoorsOpen60":
                return <SlidingDoors {...props} direction="open" angle={60} />;
            case "SlidingDoorsOpen90":
                return <SlidingDoors {...props} direction="open" angle={90} />;
            case "SlidingDoorsOpen120":
                return <SlidingDoors {...props} direction="open" angle={120} />;
            case "SlidingDoorsClose":
                return <SlidingDoors {...props} direction="close" angle={0} />;
            case "SlidingDoorsClose60":
                return <SlidingDoors {...props} direction="close" angle={60} />;
            case "SlidingDoorsClose90":
                return <SlidingDoors {...props} direction="close" angle={90} />;
            case "SlidingDoorsClose120":
                return <SlidingDoors {...props} direction="close" angle={120} />;
            default:
                return <SlidingDoors {...props} direction="close" angle={0} />;
        }
    }, [])

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const memoizedContent = useMemo(() => {
        return (
            <AbsoluteFill>
                {slideData?.backgroundAudio?.source && slideData?.backgroundAudio?.source !== "" ?
                    <Sequence from={slideData.intro.enable === true ? +slideData.intro.duration * 30 + audioDelay : 0} durationInFrames={totalDuration} >
                        <AudioLayer src={slideData.backgroundAudio.source} volume={+slideData.backgroundAudio.volume / 100} loop={slideData.backgroundAudio.isLoop} />
                    </Sequence>
                    : ""
                }
                <TransitionSeries>
                    {slideData?.intro?.enable === true && slideData?.intro?.src !== "" ?
                        <React.Fragment >
                            <TransitionSeries.Sequence durationInFrames={+slideData.intro.duration * 30 + transitionDuration} >
                                <Intro src={slideData.intro.src} duration={+slideData.intro.duration * 30} />
                            </TransitionSeries.Sequence>
                            <TransitionSeries.Transition
                                durationInFrames={transitionDuration}
                                transitionComponent={(props) => renderTransitionComponent(slideData.slides[0].transitionName, props)}
                            />
                        </React.Fragment> : <></>
                    }
                    {
                        slideData.slides?.length > 0 ?
                            slideData.slides.map((slide, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {/* if your slide duration is 5sec. remotion takes 4 sec slide duration and 1 sec transition that why always use transitionDuration variable for extra 1 sec  */}
                                        <TransitionSeries.Sequence durationInFrames={slideData.intro.enable === true ? +slide.duration * 30 + audioDelay + transitionDuration : index === 0 ? +slide.duration * 30 + transitionDuration : +slide.duration * 30 + audioDelay + transitionDuration} >
                                            <Series>
                                                {
                                                    [...Array(repeatBackground[index])].map((ele, repeatIndex) => {
                                                        return (
                                                            <React.Fragment key={repeatIndex} >
                                                                {
                                                                    slide?.background?.length > 0 ?
                                                                        slide.background.map((background, bgIndex) => {
                                                                            return (
                                                                                <React.Fragment key={bgIndex} >
                                                                                    <Series.Sequence durationInFrames={30 * 6}>
                                                                                        <SlideBackground animation={background.animation} src={background.src} duration={30 * 6} type={background.type} />
                                                                                    </Series.Sequence>
                                                                                </React.Fragment>
                                                                            )
                                                                        })
                                                                        : ""
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </Series>
                                            {
                                                slide?.audio?.enable === true && slide?.audio?.src !== "" ?
                                                    <Sequence from={slideData.intro.enable === true ? audioDelay : index === 0 ? 0 : audioDelay}>
                                                        <AudioLayer src={slide.audio.src} volume={+slide.audio.volume / 100} loop={false} />
                                                    </Sequence>
                                                    : ""
                                            }
                                            {
                                                slide.layers?.length > 0 ?
                                                    slide.layers.map((layer, layerIndex) => {
                                                        return (
                                                            <React.Fragment key={layerIndex}>
                                                                {
                                                                    layer.type === "video" || layer.type === "image" ?
                                                                        <VideoLayer index={index} src={layer.src} layer={layer} multiplyNum={multiplyNum} />
                                                                        : layer.type === "text" ?
                                                                            <TextLayer index={index} layer={layer} multiplyNum={multiplyNum} />
                                                                            : layer.type === "shape" ?
                                                                                <ShapeLayer index={index} layer={layer} multiplyNum={multiplyNum} />
                                                                                : layer.type === "logo" || layer.type === "graph" ?
                                                                                    <LogoLayer index={index} src={layer.src} layer={layer} multiplyNum={multiplyNum} />
                                                                                    : ""

                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })

                                                    : ""
                                            }
                                            {/* {slideData?.logo?.enable === true && slideData?.logo?.src !== "" ?
                                                <Logo src={slideData.logo.src} type={"logo"} position={slideData.logo.pos} multiplyNum={multiplyNum} />
                                                : ""
                                            } */}
                                            {slideData?.watermark?.enable === true && slideData?.watermark?.src !== "" ?
                                                <Logo src={slideData.watermark.src} type={"watermark"} position={slideData.watermark.pos} multiplyNum={multiplyNum} />
                                                : ""
                                            }
                                            {slideData?.promo?.enable === true && slideData?.promo?.data !== "" ?
                                                <Promo src={slideData.promo.data} type={"logo"} position={slideData.promo.position} multiplyNum={multiplyNum} />
                                                : ""
                                            }
                                        </TransitionSeries.Sequence >

                                        <TransitionSeries.Transition
                                            durationInFrames={transitionDuration}
                                            transitionComponent={(props) => renderTransitionComponent(slide.transitionName, props)}
                                        />
                                    </React.Fragment >
                                )

                            })
                            : <></>
                    }

                    {slideData?.outro?.enable === true && slideData?.outro?.src !== "" ?
                        (
                            <TransitionSeries.Sequence durationInFrames={+slideData.outro.duration * 30} >
                                <Intro src={slideData.outro.src} duration={+slideData.outro.duration * 30} />
                            </TransitionSeries.Sequence>
                        ) : <></>
                    }

                </TransitionSeries>
            </AbsoluteFill >
        )
    }, [slideData, repeatBackground, totalDuration]);

    return memoizedContent
}

export default Editor

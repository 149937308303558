import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PreviewMain from './PreviewMain';
import { ReactComponent as UndoIcon } from "../../../../images/undo.svg";
import { ReactComponent as RedoIcon } from "../../../../images/redo.svg";
import { ReactComponent as LayerBackIcon } from "../../../../images/layer-back.svg";
import { ReactComponent as LayerFrontIcon } from "../../../../images/layer-front.svg";
import { ReactComponent as DuplicateIcon } from "../../../../images/duplicate.svg";
import { ReactComponent as DeleteIcon } from "../../../../images/delete.svg";
import { layerDuplicate, onRedo, onUndo, removeLayer, saveVideo, updateLastAction, updateLayerPos, updatePresent } from '../../../../Actions/CampaignAction';
import SlideActions from './SlideActions';
import VideoTiles from './VideoTiles';


const Preview = ({ preLoader, campaign, logo, watermark }) => {
    const dispatch = useDispatch()
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")

    const totalslides = campaign.slides.length
    let actionArr = ["UNDO", "REDO", "ADD_VIDEO_DATA", "UPDATE_SLIDES_DATA", "END_ANIMATION", "ONPLAY_SLIDE", "UPDATE_WATERMARK_HW", "UPDATE_LOGO_HW"]

    const [allowCall, setAllowCall] = useState(true)
    const [playStatus, setPlayStatus] = useState(false)
    const [undoActive, setUndoActive] = useState(false)
    const [redoActive, setRedoActive] = useState(false)
    const [second, setSecond] = useState(0)
    const [timer, setTimer] = useState({
        h: 0,
        m: 0
    });

    const [time, setTime] = useState({
        h: 0,
        m: 0,
        s: 0
    })


    const deleteLayer = () => {
        dispatch(removeLayer(selectedSlide, selectedSlideIndex))
    }


    const duplicateLayer = () => {
        if (selectedSlide.layers.length > 0) {
            let layerData = selectedSlide.layers.find(({ isSelected }) => isSelected === true)
            const layerDataIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
            if (layerDataIndex !== -1) {
                const val = { ...layerData }
                dispatch(layerDuplicate(selectedSlideIndex, val, layerDataIndex))
            }
        }
    }
    const handleLayerUpDown = (type) => {
        const layerDataIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (layerDataIndex !== -1) {
            if (type === "down") {
                if (layerDataIndex > 0) {
                    dispatch(updateLayerPos(selectedSlideIndex, layerDataIndex, "up"))
                }
            } else {
                if (selectedSlide.layers.length - 1 > layerDataIndex) {
                    dispatch(updateLayerPos(selectedSlideIndex, layerDataIndex, "down"))
                }
            }
        }
    }

    const handleUndo = () => {
        let id = selectedSlide.id
        if (!undoActive) {
            dispatch(onUndo(id))
        }
    }
    const handleRedo = () => {
        let id = selectedSlide.id
        if (!redoActive) {
            dispatch(onRedo(id))
        }
    }



    useEffect(() => {
        const handleKey = (event) => {
            if (event.keyCode === 46) {
                deleteLayer()
            }
            if (event.keyCode === 90 && (event.ctrlKey || event.metaKey)) {
                handleUndo()
            }
            if (event.keyCode === 89 && (event.ctrlKey || event.metaKey)) {
                handleRedo()
            }
        }
        document.addEventListener("keydown", handleKey)
        return () => document.removeEventListener("keydown", handleKey)
    }, [selectedSlide])

    useEffect(() => {
        if (campaign.undoRedo.length > 0) {
            const undoRedoObj = campaign.undoRedo.find(({ slideId }) => parseInt(slideId) === parseInt(selectedSlide.id))
            if (undoRedoObj) {
                if (undoRedoObj.past.length > 0) {
                    setUndoActive(false)
                }
                else {
                    setUndoActive(true)
                }
                if (undoRedoObj.future.length > 0) {
                    setRedoActive(false)
                }
                else {
                    setRedoActive(true)
                }
            }
        }
    }, [campaign.undoRedo, selectedSlideIndex])

    useEffect(() => {
        if (actionArr.includes(campaign.lastAction)) {
            dispatch(updateLastAction(selectedSlide))
        } else {
            dispatch(updatePresent(selectedSlide))
        }
    }, [selectedSlide])

    useEffect(() => {
        if (selectedSlide) {
            const data = selectedSlide.duration ? selectedSlide.duration : 0
            setTime({
                ...time,
                s: parseInt(data % 60),
                m: Math.floor(data % 3600 / 60),
                h: parseInt(data / 3600)
            })
        }
    }, [selectedSlide.duration])

    useEffect(() => {
        if (second > 59) {
            setTimer({
                ...timer,
                m: timer.m + 1
            })
            setSecond(0)
        }
        if (timer.m > 59) {
            setTimer({
                ...timer,
                h: timer.h + 1,
                m: 0
            })
        }
    }, [second])


    //Save Slide 

    useEffect(() => {
        if (campaign && allowCall) {
            dispatch(saveVideo(campaign, setAllowCall))
        }
    }, [campaign])

    return (
        <>
            <div className={campaign.campaign.emotion.dimention === "9:16" ? "canvas_9X16_height_scroll" : campaign.campaign.emotion.dimention === "16:9" ? "canvas_16X9_height_scroll" : "canvas_16X9_height_scroll"}>
                <div className={campaign.campaign.emotion.dimention === "9:16" ? "canvas_9x16_editor" : campaign.campaign.emotion.dimention === "16:9" ? "canvas_16X9_editor" : "canvas_16X9_editor"}>
                    <ul className="controls d-flex flex-wrap justify-content-center align-items-center">
                        <li className="control-btn pr-3" >
                            <a
                                id="delete"
                                className={`cursor-pointer this-hover  ${undoActive ? "control-btn disable" : ""}`}
                                data-toggle="tooltip"
                                title="Undo"
                                onClick={handleUndo}
                            >
                                <UndoIcon />
                            </a>
                        </li>
                        <li className="control-btn pr-3 mr-3 border-right" >
                            <a
                                id="delete"
                                className={`text-white cursor-pointer this-hover  ${redoActive ? "control-btn disable" : ""}`}
                                data-toggle="tooltip"
                                title="Redo"
                                onClick={handleRedo}
                            >
                                <RedoIcon />
                            </a>
                        </li>
                        <li className="control-btn pr-3 mr-3 border-right" >
                            <a
                                id="duplicate"
                                className="text-white cursor-pointer this-hover"
                                data-toggle="tooltip"
                                title="Duplicate"
                                onClick={duplicateLayer}
                            >
                                <DuplicateIcon />
                            </a>
                        </li>

                        <li className="control-btn pr-3">
                            <a
                                id="layer-back"
                                className="text-white cursor-pointer this-hover"
                                data-toggle="tooltip"
                                title="Layer Back"
                                onClick={() => handleLayerUpDown("down")}
                            >
                                <LayerBackIcon />
                            </a>
                        </li>
                        <li className="control-btn pr-3 mr-3 border-right">
                            <a
                                id="layer-front"
                                className="text-white cursor-pointer this-hover"
                                data-toggle="tooltip"
                                title="Layer Front"
                                onClick={() => handleLayerUpDown("up")}
                            >
                                <LayerFrontIcon />
                            </a>
                        </li>

                        <li className="control-btn" >
                            <a
                                id="delete"
                                className="text-white cursor-pointer this-hover"
                                data-toggle="tooltip"
                                title="Delete"
                                onClick={deleteLayer}
                            >
                                <DeleteIcon />
                            </a>
                        </li>


                    </ul>
                    <div className="canvasEditor-main">
                        <PreviewMain
                            slideData={campaign.slides}
                            selectedSlide={selectedSlide}
                            selectedSlideIndex={selectedSlideIndex}
                            logo={logo}
                            watermark={watermark}
                            preLoader={preLoader}
                            promo={campaign.promo}
                        />

                    </div>
                    <div className="videoControll">
                        <div className="row">
                            <div className="col-4">
                                <div className="videoControll-txt">
                                    <span>{selectedSlideIndex + 1} / {totalslides}</span>

                                </div>
                            </div>
                            <div className="col-4">
                                <div className="videoControll-nav">
                                    <ul>
                                        <SlideActions
                                            selectedSlideIndex={selectedSlideIndex}
                                            campaign={campaign}
                                            totalslides={totalslides}
                                            selectedSlide={selectedSlide}
                                            setSecond={setSecond}
                                            setPlayStatus={setPlayStatus}
                                            playStatus={playStatus}
                                            timer={timer}
                                            setTimer={setTimer}
                                        />
                                    </ul>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="videoControll-right">
                                    {playStatus ?
                                        <span>
                                            {
                                                timer.h < 10 ? '0' + timer.h : timer.h
                                            }
                                            :
                                            {
                                                timer.m < 10 ? '0' + timer.m : timer.m
                                            }
                                            :
                                            {
                                                second < 10 ? '0' + second : second
                                            }
                                        </span> :
                                        <span>
                                            {time.h > 9 ? "" : "0"}{time.h}:{time.m > 9 ? "" : "0"}{time.m}:{time.s > 9 ? "" : "0"}{time.s}
                                        </span>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <div className={`${campaign.campaign.emotion.dimention === "9:16" ? "editorReel_9x16_hide" : "editorReel_9x16_block"} editorReel`}>
                                    <VideoTiles
                                        slideData={campaign.slides}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div >
            </div >

            <div className="container">
                <div className="row">
                    <div className="col-md-12 p-0">
                        <div className={`${campaign.campaign.emotion.dimention === "9:16" ? "editorReel_9x16_editor" : "editorReel_9x16_hide"} editorReel`}>
                            <VideoTiles
                                slideData={campaign.slides}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};



export default Preview;

import React from 'react'
import { AbsoluteFill, Video } from 'remotion';

const Intro = ({ src, duration }) => {
    return (
        <AbsoluteFill>
            < Video
                src={src}
                // startFrom={0}
                // endAt={duration}
                volume={1}
                // playbackRate={2} //speed
                muted={false}
                loop={false}
            />
        </AbsoluteFill>
    )
}

export default Intro

import React, { useEffect } from 'react'
import { AbsoluteFill, Sequence } from 'remotion';
import WebFont from 'webfontloader';
import 'animate.css/animate.min.css';
import { Animation } from 'remotion-animation';


const TextLayer = ({ index, layer, multiplyNum }) => {

    function increaseSize(size) {
        if (size) {
            let matches = size.match(/(\d+)/);
            let actualSize = parseInt(matches[0]) * multiplyNum;
            return `${actualSize}px`
        }
    }

    let html = layer.src;

    let mystyle = {
        position: `absolute`,
        background: `${layer.style.background}`,
        opacity: `${layer.style.opacity}`,
        border: `${layer.style.border}`,
        borderWidth: `${increaseSize(layer.style.border)}`,
        boxShadow: `${layer.style.boxShadow}`,
        objectFit: `${layer.style.objectFit}`,
        left: `${+layer.position.left * multiplyNum}px`,
        top: `${+layer.position.top * multiplyNum}px`,
        width: `${+layer.size.width * multiplyNum}px`,
        height: `${+layer.size.height * multiplyNum}px`,
        textAlign: `${layer.style.textAlign}`,

        borderTopRightRadius: `${layer.style.borderTopRightRadius === undefined ? '' : increaseSize(layer.style.borderTopRightRadius)}`,
        borderBottomRightRadius: `${layer.style.borderBottomRightRadius === undefined ? '' : increaseSize(layer.style.borderBottomRightRadius)}`,
        borderTopLeftRadius: `${layer.style.borderTopLeftRadius === undefined ? '' : increaseSize(layer.style.borderTopLeftRadius)}`,
        borderBottomLeftRadius: `${layer.style.borderBottomLeftRadius === undefined ? '' : increaseSize(layer.style.borderBottomLeftRadius)}`,
        borderRadius: `${layer.style.borderRadius?.includes("%") ? layer.style.borderRadius : increaseSize(layer.style.borderRadius)}`,
    }

    let spanStyle = {
        color: `${layer.style.color}`,
        fontSize: `${+layer.style.fontSize * multiplyNum}px`,
        fontFamily: `${layer.style.fontFamily}`,
        fontWeight: `${layer.style.fontWeight}`,
        textTransform: `${layer.style.textTransform}`,
        textDecoration: `${layer.style.textDecoration}`,
        fontFamily: `${layer.style.fontFamily}`,
        fontStyle: `${layer.style.fontStyle}`,
        lineHeight: `${layer.style.lineHeight}`,
        textShadow: `${layer.style.textShadow}`,
    }


    // if (layer.textToHighlight !== null && layer.textToHighlight !== undefined) {
    //     layer.textToHighlight.forEach((curElem) => {
    //         html = html.replaceAll(curElem.text, `<span style="color:${curElem.color}; background:${curElem.background};">${curElem.text}</span>`);
    //     })
    // }

    if (layer.innerCss && layer.innerCss != null && layer.innerCss != undefined) {
        spanStyle = {
            ...spanStyle,
            // display: `${layer.innerCss.display}`,
            display: "block",
            lineHeight: `${layer.innerCss.lineHeight}`,
            padding: `${layer.innerCss.padding}`,
            background: `${layer.innerCss.background}`,
            webkitBoxDecorationBreak: `clone`,
            boxDecorationBreak: `clone`
        }
    }

    useEffect(() => {
        WebFont.load({
            google: {
                families: [`${layer.style.fontFamily}`]
            }
        });
    }, [layer.style.fontFamily])

    return (
        <Sequence from={Number(layer.animation.delay.toString().replace("s", "")) * 30 + 30}>
            <AbsoluteFill >
                <Animation style={mystyle} duration={Number(layer.animation.duration.toString().replace("s", ""))} animateName={layer.animation.name.replace("animate__", "")} >
                    <span style={spanStyle} dangerouslySetInnerHTML={{ __html: html }} ></span>
                </Animation>
            </AbsoluteFill >
        </Sequence>
    )
}

export default TextLayer

import React, { useState, useEffect } from "react";

import { Modal } from "react-bootstrap";
import modalCloseIcon from "../../../../../images/modal-close.png";

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import AudioLibrary from "./AudioLibrary";
import YourAudio from "./YourAudio";
import TextToSpeech from "./TextToSpeech";
import { useDispatch } from "react-redux";
import { onGetLibraryAudio, onGetUserUploadData, onFetchVoice, onFetchLang } from "../../../../../Actions/EditorAction";
import { useSelector } from 'react-redux';
import { updateTtsUrl } from "../../../../../Actions/CampaignAction";
import Recording from "./Recording";

const AudioModal = ({ show, handleClose, selectedSlide, selectedSlideIndex, setDeactivateButton }) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)
    const [memberShip, setMemberShip] = useState([])
    const [pollyLanguages, setPollyLanguages] = useState({ data: [] });
    const [pollyVoices, setPollyVoices] = useState({ data: [] });
    const [currentUrl, setCurrentUrl] = useState('');
    const [userAudio, setUserAudio] = useState([]);
    const [loader, setLoader] = useState({
        audioLibrary: false,
        userAudio: false
    })
  
    const [tab, setTab] = useState("first")
    const [selectedMedia, setSelectedMedia] = useState(false)

    const handleChangeVoiceAudio = (data) => {
        setSelectedMedia(data)
    }


    const fetchPollyLanguages = () => {
        dispatch(onFetchLang(pollyLanguages, setPollyLanguages, memberShip))
    }

    const fetchPollyVoices = (value) => {
        dispatch(onFetchVoice(value, pollyVoices, setPollyVoices))
    }

    const fetchUserAudio = () => {
        let data = {
            type: "music"
        }
        dispatch(onGetUserUploadData(data, setUserAudio, loader, setLoader))
    }

    const handleSave = () => {
        dispatch(updateTtsUrl(selectedMedia, selectedSlideIndex))
        handleClose()
    }

  

    useEffect(() => {
        if (selectedSlideIndex !== -1) {
            fetchPollyVoices(selectedSlide.audio.languageCode);
        }
    }, [selectedSlideIndex])

    useEffect(() => {
        fetchUserAudio()

    }, [])

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    useEffect(() => {
        if (memberShip.length > 0) {
            fetchPollyLanguages();
        }
    }, [memberShip])

    useEffect(() => {
        return () => {
            setTab("first")
        }
    }, [show])

    return (
        <Modal className="VideoModal BackgroundAudioModal" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose myvidClose">
                    <img alt="" src={modalCloseIcon} />
                </div>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav variant="b" className="inTabNav">
                        <Nav.Item onClick={() => setTab("first")}>
                            <Nav.Link eventKey="first">Text to Speech</Nav.Link>
                        </Nav.Item>
                        <Nav.Item onClick={() => setTab("second")}>
                            <Nav.Link eventKey="second">Your Audio</Nav.Link>
                        </Nav.Item>
                        <Nav.Item onClick={() => setTab("third")}>
                            <Nav.Link eventKey="third">Record Audio</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>
                        <Tab.Pane eventKey="first" >
                            <TextToSpeech
                                pollyLanguages={pollyLanguages}
                                pollyVoices={pollyVoices}
                                fetchPollyVoices={fetchPollyVoices}
                                selectedSlide={selectedSlide}
                                selectedSlideIndex={selectedSlideIndex}
                                setDeactivateButton={setDeactivateButton}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="second" >
                            <YourAudio
                                currentUrl={currentUrl}
                                setCurrentUrl={setCurrentUrl}
                                loader={loader}
                                setLoader={setLoader}
                                userAudio={userAudio}
                                fetchUserAudio={fetchUserAudio}
                                handleChangeBgAudio={handleChangeVoiceAudio}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="third" >
                            <Recording
                                handleChangeBgAudio={handleChangeVoiceAudio}
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
                {tab !== "first"?
                    <div className="btnTab text-end">
                        <button
                            className="create-modal-btn"
                            onClick={handleSave}
                        >Use</button>
                        <button className="create-modal-btn ml-2"
                            onClick={handleClose}
                        >Cancel</button>
                    </div> : ""}
            </Modal.Body>
        </Modal >
    )
}

export default AudioModal

import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from "sweetalert2";
import { onGenerateTts } from "../../../../../Actions/EditorAction";
import { onEditTTSText, onGenerateTtsAll, onSetPollyLang, onSetPollyVoice, updateTtsUrl } from '../../../../../Actions/CampaignAction';
import { useSelector } from 'react-redux';

let ttsAudio = false;
const TextToSpeech = ({ selectedSlide, selectedSlideIndex, pollyLanguages, pollyVoices, fetchPollyVoices, setDeactivateButton }) => {

    const dispatch = useDispatch();
    const campaign = useSelector(state => state.campaign.data)
    const [currentTts, setCurrentTts] = useState('');
    const [ttsStatus, setTtsStatus] = useState(true);
    const [generateData, setGenerateData] = useState({
        url: "",
        duration: 0
    })
    const [loader, setLoader] = useState(false);
    const [voiceId, setVoiceId] = useState("")

    const getLanguage = (e) => {
        setTtsStatus(true)
        dispatch(onSetPollyLang(e.target.value, selectedSlideIndex))
        fetchPollyVoices(e.target.value);
    }

    const getVoices = (e) => {
        const data = pollyVoices.data.find(({ id }) => id === e.target.value)
        dispatch(onSetPollyVoice(data.voice_id, e.target.value, data.type, selectedSlideIndex))
        setTtsStatus(true)
    }

    const onEditTts = (e) => {
        dispatch(onEditTTSText(e.target.value, selectedSlideIndex))
        setTtsStatus(true)
    }

    const playTts = (url) => {
        if (ttsAudio !== false) {
            ttsAudio.pause();
        }
        if (currentTts === url) {
            ttsAudio.pause();
            setCurrentTts('')
        } else {
            ttsAudio = new Audio(url);
            ttsAudio.play();

            ttsAudio.onended = function () {
                setCurrentTts('')
            }
            setCurrentTts(url);
        }
    }

    const generateTts = () => {
        if (selectedSlide.audio.text !== "" && selectedSlide.audio.languageCode !== "" && selectedSlide.audio.voiceId !== "") {
            setLoader(true);
            let engineVal
            if (selectedSlide.audio.engine === "Neural") {
                engineVal = selectedSlide.audio.engine.replace("Neural", "neural")
            }
            else {
                engineVal = selectedSlide.audio.engine.replace("Standard", "standard")
            }
            let data = {
                text: selectedSlide.audio.text,
                language_id: selectedSlide.audio.languageCode,
                voice_id: selectedSlide.audio.voiceId,
                engine: engineVal
            }
            dispatch(onGenerateTts(data, generateData, setGenerateData, setLoader, setTtsStatus))

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please, add text, language & voice in TTS',
                confirmButtonColor: "#e0712c"
            })
        }
    }


    const handleChangeVoiceOver = () => {
        dispatch(updateTtsUrl(generateData, selectedSlideIndex))
    }

    const handleGenerateAll = (e) => {
        setDeactivateButton(true)
        setTtsStatus(true)
        if (e.target.checked) {
            let engineVal
            if (selectedSlide.audio.engine === "Neural") {
                engineVal = selectedSlide.audio.engine.replace("Neural", "neural")
            }
            else {
                engineVal = selectedSlide.audio.engine.replace("Standard", "standard")
            }
            let data = {
                text: selectedSlide.audio.text,
                language_id: selectedSlide.audio.languageCode,
                voice_id: selectedSlide.audio.voiceId,
                engine: engineVal
            }
            dispatch(onGenerateTtsAll(campaign.slides, data, setDeactivateButton))
        }
    }

    useEffect(() => {
        if (selectedSlide) {
            let data = pollyVoices.data.find(({ voice_id }) => voice_id === selectedSlide.audio.voiceId)
            if (data) {
                setVoiceId(data.id)
            }
        }
    }, [pollyVoices, selectedSlide])


    useEffect(() => {
        return () => {
            if (ttsAudio !== false) {
                ttsAudio.pause();
            }
        }
    }, [])


    return (
        <div className="tab-outer">
            <div className="fontStyle-block-wrap">
                <div className="row">
                    <div className="col-lg-4">
                        <select
                            name=""
                            id=""
                            className="inPut"
                            onChange={(e) => getLanguage(e)}
                            value={selectedSlide.audio.languageCode}
                        >
                            <option value="">Select Language</option>
                            {
                                pollyLanguages.data.length > 0 ?
                                    pollyLanguages.data.map((item, index) => {
                                        return (
                                            <option key={index} value={item.code}>{item.name}</option>
                                        )
                                    })
                                    : ''
                            }
                        </select>
                    </div>
                    <div className="col-lg-4">
                        <select
                            name=""
                            id=""
                            className="inPut cursor-pointer text-hover-effect"
                            value={voiceId}
                            onChange={(e) => getVoices(e)}
                        >
                            <option value="">Select Audio</option>
                            {
                                pollyVoices.data.length > 0 ?
                                    pollyVoices.data.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}>{item.voice_id} ({item.gender}), {item.type}</option>
                                        )
                                    })
                                    : ''
                            }
                        </select>
                    </div>
                </div>
                <textarea
                    placeholder="Add Text Here"
                    className="inPut mt-2"
                    style={{ height: 200 }}
                    onChange={(e) => onEditTts(e)}
                    value={selectedSlide.audio.text}
                ></textarea>
                <div className="row p-0 mt-3 gx-0">
                    <div className="voice-check-box pt-1">
                        {/* <label htmlFor='addTtsAll' className='d-flex' style={{ display: "contents" }} >
                            <input
                                type="checkbox"
                                id='addTtsAll'
                                checked={addAllText}
                                onChange={handleAddAllText}
                                style={{ width: '28px', height: "19px" }}
                            />
                            <span className='align-self-center' style={{ fontSize: '13px' }}>Load all layers text from the slide</span>
                        </label> */}
                        {ttsStatus ? "" :
                            <label htmlFor='addTtsAll' className='d-flex'>
                                <input
                                    type="checkbox"
                                    id='addTtsAll2'
                                    name='addTtsAll'
                                    onChange={handleGenerateAll}
                                    style={{ width: '40px', height: "19px", }}
                                />
                                <span className='align-self-center text-light' style={{ fontSize: '13px' }}>Use the same language & voice to generate voiceover for all the slides</span>
                            </label>
                        }

                    </div>
                </div>
                <div className="tab-btn">
                    {ttsStatus ?
                        <button className="demoLink alt mt-0 justify-content-center" onClick={() => generateTts()}>
                            {loader ?
                                <>
                                    Generating
                                    <i className="fa fa-spinner fa-spin mx-1" />
                                </> : "Generate"}
                        </button>
                        :
                        <button className="demoLink alt mt-0 justify-content-center"
                            onClick={() => playTts(generateData.url)}
                        >
                            <span className="mr-1">
                                <i className={`fas ${currentTts === generateData.url ? 'fa-pause-circle' : 'fa-play-circle'}`} />
                            </span>
                            Preview
                        </button>
                    }
                    <button className="demoLink alt mt-0 ml-3 justify-content-center"
                        disabled={ttsStatus}
                        style={{ background: ttsStatus ? "gray" : "" }}
                        onClick={() => handleChangeVoiceOver()}
                    >
                        Use
                    </button>
                </div>
            </div>
        </div>
    )
}

export default TextToSpeech

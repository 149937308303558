import React from 'react'
import { AbsoluteFill, Sequence } from 'remotion';
import 'animate.css/animate.min.css';
import { Animation } from 'remotion-animation';


const ShapeLayer = ({ index, layer, multiplyNum }) => {

    function increaseSize(size) {
        if (size) {
            let matches = size.match(/(\d+)/);
            let actualSize = parseInt(matches[0]) * multiplyNum;
            return `${actualSize}px`
        }
    }

    const textWidth = layer.size.width * multiplyNum
    const textHeight = layer.size.height * multiplyNum
    const textLeft = layer.position.left * multiplyNum
    const textTop = layer.position.top * multiplyNum


    let mystyle = {
        position: "absolute",
        width: `${textWidth}px`,
        height: `${textHeight}px`,
        left: `${textLeft}px`,
        top: `${textTop}px`
    }
    let shapeStyle = {
        color: `${layer.style.color}`,
        background: `${layer.style.background}`,
        opacity: `${layer.style.opacity}`,
        boxShadow: `${layer.style.boxShadow}`,
        border: `${layer.style.border}`,
        borderWidth: `${increaseSize(layer.style.border)}`,
        objectFit: `${layer.style.objectFit}`,
        height: `100%`,
        width: `100%`,
        display: `inline-block`,
        borderTopRightRadius: `${layer.style.borderTopRightRadius === undefined ? '' : increaseSize(layer.style.borderTopRightRadius)}`,
        borderBottomRightRadius: `${layer.style.borderBottomRightRadius === undefined ? '' : increaseSize(layer.style.borderBottomRightRadius)}`,
        borderTopLeftRadius: `${layer.style.borderTopLeftRadius === undefined ? '' : increaseSize(layer.style.borderTopLeftRadius)}`,
        borderBottomLeftRadius: `${layer.style.borderBottomLeftRadius === undefined ? '' : increaseSize(layer.style.borderBottomLeftRadius)}`,
        borderRadius: `${layer.style.borderRadius?.includes("%") ? layer.style.borderRadius : increaseSize(layer.style.borderRadius)}`,
        clipPath: `${layer.style.clipPath}`
    }


    return (
        <Sequence from={Number(layer.animation.delay.toString().replace("s", "")) * 30 + 30}>
            <AbsoluteFill>
                <Animation style={mystyle} duration={Number(layer.animation.duration.toString().replace("s", ""))} animateName={layer.animation.name.replace("animate__", "")} >
                    <span style={shapeStyle}></span>
                </Animation>
            </AbsoluteFill>
        </Sequence>
    )
}

export default ShapeLayer

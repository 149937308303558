import React, { useMemo } from 'react'
import { AbsoluteFill, Video, Img, interpolate, useCurrentFrame, spring, useVideoConfig } from 'remotion';

const SlideBackground = ({ animation, src, duration, type }) => {

    const frame = useCurrentFrame();
    const { fps } = useVideoConfig();

    let mystyle = {
        scale: "",
        translate: "",
    }

    if (animation === "zoominheader") {
        mystyle.scale = String(
            interpolate(frame, [0, duration], [1, 1.5], {
                extrapolateLeft: "clamp",
                extrapolateRight: "clamp",
            })
        )
    } else if (animation === "zoomoutheader") {
        mystyle.scale = String(
            interpolate(frame, [0, duration], [1.5, 1], {
                extrapolateLeft: "clamp",
                extrapolateRight: "clamp",
            })
        )
    } else if (animation === "zoomright") {
        const left = spring({
            fps,
            frame,
            config: {
                damping: 200,
            },
            durationInFrames: duration,
        });

        const x = interpolate(left, [0, duration / 30], [0, -400]);
        mystyle.translate = `${x}px ${0}px`
        mystyle.scale = String(
            interpolate(frame, [0, duration], [1.5, 1.5], {
                extrapolateLeft: "clamp",
                extrapolateRight: "clamp",
            })
        )

    }
    else if (animation === "zoomleft") {
        const right = spring({
            fps,
            frame,
            config: {
                damping: 200,
            },
            durationInFrames: duration,
        });

        const x = interpolate(right, [0, duration / 30], [0, 400]);
        mystyle.translate = `${x}px ${0}px`
        mystyle.scale = String(
            interpolate(frame, [0, duration], [1.5, 1.5], {
                extrapolateLeft: "clamp",
                extrapolateRight: "clamp",
            })
        )

    }
    else {

    }


    return (
        <AbsoluteFill >
            {type === "video" ?
                <Video
                    style={{ ...mystyle, objectFit: "contain", height: "100%", width: "100%" }}
                    src={src}
                    volume={1}
                    playbackRate={2} //speed
                    quality="draft"
                    muted={true}
                    loop={true}
                    onError={(event) => {
                        // Handle image loading error here
                        console.log("error")
                    }}
                />
                :
                type === "image" ?
                    < Img
                        style={{ ...mystyle, objectFit: "contain", height: "100%", width: "auto" }}
                        src={src}
                        onError={(event) => {
                            // Handle image loading error here
                            console.log("error")
                        }}
                    />
                    :
                    <AbsoluteFill style={{ background: src }} />
            }
        </AbsoluteFill >
    )
}

export default SlideBackground

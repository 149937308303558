import React, { useState, useEffect } from 'react';
import { GiPauseButton } from 'react-icons/gi'
import { BsMicFill, BsPlayFill, BsStopFill } from 'react-icons/bs'
import { useDispatch } from 'react-redux';
import useRecorder from '../../../../MyRecording';
import { getUploadData, onDeleteMedia, uploadRecording } from '../../../../../Actions/CampaignAction';
import AudioPlayer from './AudioPlayer';

let newIntervalId, music = false
const Recording = ({ handleChangeBgAudio }) => {
    let [audioURL, status, recorder, startRecording, stopRecording, pauseRecording, resumeRecording, setAudioURL] = useRecorder();

    const [play1, setPlay1] = useState(false);

    const dispatch = useDispatch()
    const [play, setPlay] = useState(false)
    const [recordingData, setRecordingData] = useState([])
    const [second, setSecond] = useState(0)
    const [t, setT] = useState(false)
    const [audioDuration, setAudioDuration] = useState(0)
    const [currentUrl, setCurrentUrl] = useState('');
    const [loader, setLoader] = useState({
        uploadLoader: false,
        fetchLoader: false,
        playRecording: false
    })

    const [timer, setTimer] = useState({
        h: 0,
        m: 0
    });

    useEffect(() => {
        if (recorder !== null) {
            setT(true)
        }
    }, [recorder])

    const handleClick = (type) => {
        if (type === "start") {
            startRecording()
        }
        if (type === "pause") {
            pauseRecording()
            setT(false)
            clearInterval(newIntervalId);
            return;
        }
        if (type === "resume") {
            resumeRecording()
            setT(true)

        }
        if (type === "stop") {
            stopRecording()
            clearInterval(newIntervalId);
            let totalTime = timer.h * 3600 + timer.m * 60 + second
            setAudioDuration(totalTime)
            setSecond(0)
            setTimer({
                ...timer,
                h: 0,
                m: 0
            })
            return;
        }

    };
    useEffect(() => {
        if (t) {
            newIntervalId = setInterval(() => {
                setSecond(prevSec => prevSec + 1)
            }, 1000);
        }
    }, [t])


    useEffect(() => {
        if (second > 59) {
            setTimer({
                ...timer,
                m: timer.m + 1
            })
            setSecond(0)
        }
        if (timer.m > 59) {
            setTimer({
                ...timer,
                h: timer.h + 1,
                m: 0
            })
        }
    }, [second])

    // let clearPause
    const handlePlay = () => {
        if (audioURL) {
            setLoader({
                ...loader,
                playRecording: true
            })
            const file = URL.createObjectURL(audioURL)
            music = new Audio(file)
            music.play()
                .then(r => {
                    setLoader({
                        ...loader,
                        playRecording: false
                    })
                    setPlay(true)
                }
                )
                .catch(err => console.log(err));
        }
    }

    useEffect(() => {
        if (play) {
            setTimeout(() => {
                setPlay(false)
            }, (audioDuration + 1) * 1000)
        }
    }, [play])

    const handlePause = () => {
        music.pause()
        setPlay(false)
    }

    const removeMedia = (id, Swal) => {
        let formData = new FormData();
        formData.append('id', id);
        dispatch(onDeleteMedia(formData, fetchRecording, Swal))
    }

    const handleUpload = async () => {
        if (audioURL !== '') {
            setLoader({
                ...loader,
                uploadLoader: true
            })

            const fileName = new File([audioURL], "audio.mp3");
            const formData = new FormData();
            formData.append('upload_type', "recording")
            formData.append('file', fileName)
            dispatch(uploadRecording(formData, fetchRecording, setAudioURL, loader, setLoader))

        }
    }

    const fetchRecording = () => {
        const data = { type: 'recording' }
        setLoader({
            ...loader,
            fetchLoader: true
        })
        dispatch(getUploadData(data, setRecordingData, loader, setLoader))
    }

    useEffect(() => {
        fetchRecording()
    }, [])

    return (
        <div className="innertab">
            <div className="innertab-scroll alt" style={{ height: 500 }}>
                <div className="voice-record">
                    <div className='row'>
                        <div className="col-12">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="voice-record-time text-center">
                                <span className="mb-2 text-white">
                                    {
                                        timer.h < 10 ? '0' + timer.h : timer.h
                                    }
                                    :
                                    {
                                        timer.m < 10 ? '0' + timer.m : timer.m
                                    }
                                    :
                                    {
                                        second < 10 ? '0' + second : second
                                    }
                                </span>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div className="record-img rounded-circle text-center ">
                                    {status.start ?
                                        !status.pause ?
                                            <GiPauseButton
                                                fontSize={30}
                                                color="#000"
                                                className="cursor-pointer"
                                                onClick={() => handleClick("pause")}
                                            /> : <BsPlayFill
                                                fontSize={30}
                                                color="#000"
                                                className="cursor-pointer"
                                                onClick={() => handleClick("resume")}
                                            /> :
                                        <BsMicFill
                                            fontSize={30}
                                            color="#000"
                                            onClick={() => handleClick("start")}
                                            className="cursor-pointer"

                                        />
                                    }
                                </div>
                                <div className="record-img rounded-circle text-center text-dark">
                                    {status.start ?
                                        < BsStopFill
                                            fontSize={30}
                                            color="red"
                                            onClick={() => handleClick("stop")}

                                            className="cursor-pointer"
                                        /> : <BsStopFill
                                            fontSize={30}
                                            color="#000"
                                        />
                                    }
                                </div>
                            </div>
                            <div className="voice-block-bottom mt-4">
                                <div className="row">
                                    <div className="col-6">
                                        {play ? <button
                                            className="demoLink btn-block justify-content-center"
                                            onClick={handlePause}
                                        >
                                            Pause
                                        </button> :
                                            <button
                                                className="demoLink btn-block justify-content-center"
                                                onClick={handlePlay}
                                                style={audioURL === "" ? { background: "gray" } : {}}
                                            >
                                                Play {loader.playRecording ? <i className="fa fa-spinner fa-spin" /> : ""}
                                            </button>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <button
                                            className="demoLink btn-block justify-content-center"
                                            style={audioURL === "" ? { background: "gray" } : {}}
                                            onClick={handleUpload}
                                        >
                                            {loader.uploadLoader ?
                                                <> Uploading  <i className="fa fa-spinner fa-spin mx-1" /></> : 'Upload'
                                            }

                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='mt-3' style={{ height: 200 }}>
                        <div className='mediaList'>
                            <ul className='recording-wrapper'>
                                {recordingData.length > 0 ?
                                    recordingData.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <AudioPlayer
                                                    play={play1}
                                                    setPlay={setPlay1}
                                                    url={item.url}
                                                    title={item.name + ` ${index}`}
                                                    currentUrl={currentUrl}
                                                    setCurrentUrl={setCurrentUrl}
                                                    handleChangeAudio={handleChangeBgAudio}
                                                    type={"upload"}
                                                    id={item.id}

                                                    removeMedia={removeMedia}
                                                />
                                            </React.Fragment>
                                        )
                                    }) : ''}
                                {
                                    loader.fetchLoader ?
                                        <div className="col-md-12 mt-2">
                                            <h4 className='text-center'><i className="fa fa-spinner fa-spin " style={{ color: '#e0712c' }} /></h4>
                                        </div>
                                        : ''
                                }
                            </ul>
                        </div>
                    </div>
                </div>

            </div>

        </div >

    );
}

export default Recording;

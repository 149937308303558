import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"

export const onFetch = (setData, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-smtp", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data.reverse())
            }
            else {
                setData([])
            }
            setLoader(false)
        })
        .catch((err) => {
            console.log(err)
            setLoader(false)

        })

}

export const onTestOutreach = (data, setTested, loader, setLoader) => (dispatch, getState) => {
    commonAxios("check-smtp", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTested(true)
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                testLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                testLoader: false
            })
        })
}

export const onAddOutreach = (ep, data, loader, setLoader, handleClose, fetchOutreach) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchOutreach()
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            handleClose()
            setLoader({
                ...loader,
                addLoader: false
            })
        }).catch((err) => {
            console.log(err)
            handleClose()
            setLoader({
                ...loader,
                addLoader: false
            })
        })
}

export const onDeleteOutreach = (state, fetchOutreach, Swal) => (dispatch, getState) => {
    let data = {
        id: state.id
    }
    commonAxios("delete-smtp", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchOutreach()
                dispatch(setAlert(res.msg, "success"))
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            Swal.close()
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onSendMail = (data, setLoader) => (dispatch, getState) => {
    commonAxios("send-email", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
        })
}


//Reel Merge Actions 

export const fetchVideosToMerge = (setState, loader, setLoader, resolution) => (dispatch, getState) => {
    commonAxios("fetch-all-render", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data]
                arr = arr.filter(curElem => {
                    return curElem.dimension === resolution
                })
                setState(arr)
            } else {
                setState([])
            }
            setLoader({
                ...loader,
                dataLoader: false
            })
        }).catch((error) => {
            setLoader({
                ...loader,
                dataLoader: false
            })
            console.log(error)
        })
}

export const onCreateMerge = (mergeData, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-merge", mergeData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate('/projects');
                dispatch(setAlert(res.msg, 'success'));
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setLoader({
                ...loader,
                mergeLoader: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                mergeLoader: false
            });
            console.log(err)
        })
}
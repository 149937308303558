import React, { useState } from "react";
import SocialConnectModal from "./SocialConnectModal";
const ConnectReelapps = (props) => {
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    return (
        <>
            <div className="integr-single" id="youtube">
                <div className="connection-box-main">
                    <div className={`connection-img disable-img`}>
                        <img src={props.icon} width="56px" alt="youtube" />
                    </div>
                    <div className="connection-txt">
                        <div className="noConnect">
                            <div className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                <div className="youtube-content">
                                    <h6>{props.name}</h6>
                                    <p className="m-0">Connect {props.name}</p>
                                </div>
                            </div>
                            <div className="facebook-connect-account">
                                <button className="btn-change7" onClick={handleShow}>Connect</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SocialConnectModal
                show={show}
                handleClose={handleClose}
                icon={props.icon}
                name={props.name}
                type={props.type}
            />
        </>
    )
}

export default ConnectReelapps;
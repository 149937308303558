import React, { useState } from 'react'
import { FiTrash2 } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { onDeleteMedia, updateIntroOutro } from '../../../../Actions/CampaignAction';
import HoverVideoPlayer from "react-hover-video-player";
import SweetAlertTwo from '../../../SweetAlertTwo';

const ModalTab = (props) => {
    const dispatch = useDispatch()

    const [info, setInfo] = useState({
        id: false,
        enable: false,
        type: ""
    })

    const handleCancel = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false,
            type: ""
        })
    }
    const selectIntro = (curElem) => {
        let data = {
            url: '',
            nameData: '',
            thumbData: ''
        }
        if (props.tabType === "videoReel") {
            data.url = curElem.prview_video_url
            data.nameData = curElem.project_name
            data.thumbData = curElem.preview_image_url
        }
        else {
            data.url = curElem.url
            data.nameData = curElem.name
            data.thumbData = curElem.poster
        }

        props.handleClose()
        props.setLoader(true)
        if (props.type === "intro") {
            dispatch(updateIntroOutro('UPDATE_INTRO', data, props.setLoader))
        }
        else {
            dispatch(updateIntroOutro('UPDATE_OUTRO', data, props.setLoader))
        }
    }

    const openDeletePopUp = (e, id, type) => {
        e.stopPropagation()
        setInfo({
            ...info,
            id: id,
            type: type,
            enable: true
        })
    }

    const onConfirm = (Swal) => {
        let formData = new FormData();
        formData.append('id', info.id);
        dispatch(onDeleteMedia(formData, props.fetchIR, Swal))
        setInfo({
            ...info,
            id: -1,
            enable: false,
            type: ""
        })
    }



    return (
        <div
            className="col-md-4 col-6"
            key={props.index}
            onClick={() => selectIntro(props.curElem, props.tabType)}
        >
            <div className="credit-video-card" style={{ border: "none" }}>
                <div>
                    <>
                        <HoverVideoPlayer
                            className="cursor-pointer my-1"
                            videoSrc={props.url}
                            pausedOverlay={
                                <img src={props.thumbnail} alt="" width="100%" height="100%" style={{ objectFit: "cover" }} />
                            }
                            loadingOverlay={
                                <div ><i className="fa fa-spinner fa-spin hover-loader-center" style={{color : "#1478d3"}} /></div>
                            }
                        />
                        {props.tabType === "videoReel" ? <></> :
                            <FiTrash2
                                className='remove-media'
                                onClick={(e) => openDeletePopUp(e, props.curElem.id, props.type)}
                            />
                        }
                    </>
                </div>

            </div>

            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#b32f3d'
                cancelButtonColor='#2a2c3a'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={onConfirm}
                onCancel={handleCancel}
            />

        </div>
    )
}

export default ModalTab
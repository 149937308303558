import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updatePassword } from "../../Actions/AuthAction";

const ProfilePassword = () => {

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })
    const [userDetails, setUserDetails] = useState({
        password: '',
        new_password: '',
        confirm_password: '',
    });

    const onInputChange = (e) => {
        setPasswordMsg({ ...passwordMsg, msg: "" })
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (userDetails.new_password) {
            if (pattern.test(userDetails.new_password)) {
                if (userDetails.new_password === userDetails.confirm_password) {
                    setLoader(true);
                    dispatch(updatePassword(userDetails, setLoader));
                } else {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is not matched!", validation: true })
                }

            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        } else {
            setLoader(true);
            dispatch(updatePassword(userDetails, setLoader));
        }

    }
    return (
        <>


            <div className="profileBlock">
                <h2 className="text-center">Update Password</h2>
                <form className="" method="post" onSubmit={(e) => onFormSubmit(e)}>
                    <div className="form-group form-group-alt">
                        <label className="form-text text-muted">Current Password</label>
                        <input
                            type="password"
                            className="form-control"
                            placeholder="**********"
                            name="password"
                            required
                            onChange={(e) => onInputChange(e)}

                        />
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group form-group-alt">
                                <label className="form-text text-muted">New Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="**********"
                                    name="new_password"
                                    required
                                    onChange={(e) => onInputChange(e)}

                                />

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group form-group-alt">
                                <label className="form-text text-muted">Confirm New Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="**********"
                                    name="confirm_password"
                                    required
                                    onChange={(e) => onInputChange(e)}

                                />

                            </div>
                        </div>
                    </div>
                    <p style={{ fontSize: 13, margin: "5px" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>
                    <div className="alert alert-info my-3 text-center py-2" role="alert">
                        Updating your password will log you out for re-login
                    </div>
                    <button type="submit" className="fbtn btn-block demoLink mt-0" disabled={loader}> {loader ? <>Updating <i className="fa fa-spinner fa-spin mr-2" /> </> : 'Update'}</button>

                </form>
            </div>
        </>
    )
}

export default ProfilePassword;
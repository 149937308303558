import React from "react";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import Navbar from "../Navbar";

import { Link } from "react-router-dom";

import {FiEdit} from "react-icons/fi"


const AddVideoStep2 = () => {

    return (
        <>
            <TitleBar title="Create Add Video" />
            <Navbar />

            <div className="siteWrap">
                <div className="projectTitle-wrap">
                    <div className="container">
                        <div className="projectTitle">
                            <div className="projectTitle-left">
                                <h2>Create Add Video</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="add-video-wrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="frame-vid"><iframe width="100%" height="380" src="https://www.youtube.com/embed/cHBqwj0Ed_I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>

                                <div>
                                   <button type="submit" className="fbtn demoLink">Publish</button>
                                   <button type="submit" className="fbtn demoLink ml-3 mr-3">Share</button>
                                   <Link to="/edit-vid"><button type="submit" className="fbtn demoLink">Edit</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            

            <Footer />
        </>
    )
}

export default AddVideoStep2;
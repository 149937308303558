import React, { useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import facebookIcon from "../../images/logo-facebook.svg"
import { useDispatch, useSelector } from "react-redux";
import { saveFacebook } from "../../Actions/SocialAction";


const ConnectFacebook = (props) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [loader, setLoader] = useState(false);

    const [details, setDetails] = useState({
        name: false,
        userId: false,
        accessToken: false,
        openPopup: false,
        buttonText: "Connect",
        email: false,
        pages: [],
    });


    const responseFacebook = async (response) => {
        if (response.status !== "unknown") {
            setLoader(true);
            const data = {
                user_id: auth.user.id,
                title: response.name,
                token: response.accessToken,
                fb_id: response.userID,
                category: response.userID,
            };
            await dispatch(saveFacebook(data, props.fetchIntegration));
            setDetails({ ...details, openPopup: false });
            setLoader(false);
        }
    }

    return (
        <>
            <div className="integr-single" id="facebook">

                <div className="connection-box-main">
                    <div className={`connection-img disable-img`}>
                        <img src={facebookIcon} width="56px" alt="facebook" />
                    </div>

                    <div className="connection-txt">
                        {
                            <div className="noConnect">
                                <div className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                    <div className="youtube-content">
                                        <h6>Facebook</h6>
                                        <p className="m-0">Connect Facebook Pages & groups</p>
                                    </div>
                                </div>
                                <div className="facebook-connect-account">
                                    <FacebookLogin
                                        appId="780210132546000" //APP ID NOT CREATED YET
                                        fields="name,email"
                                        scope="ads_management,business_management,pages_manage_ads,pages_read_engagement,pages_show_list"
                                        callback={(e) => responseFacebook(e)}
                                        render={renderProps => (
                                            <button onClick={renderProps.onClick} className="btn-change7">Connect</button>
                                        )}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default ConnectFacebook;
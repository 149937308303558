import React, { useEffect } from 'react'
import Navbar from "../../Navbar";
import TitleBar from "../../TitleBar";
import { useLocation, useNavigate } from 'react-router-dom';
import { onFetchScrapeData } from '../../../Actions/CampaignAction';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

const Scrape = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('cId');

    const [scrapeData, setScrapeData] = useState({
        siteData: [],
        gmapsReviews: [],
        gmapsPhotos: [],
        gmapsVideos: [],
    });

    const handleSubmit = () => {
        navigate(`/editor?cId=${id}`)
    }
    const handleBack = () => {
        navigate("/projects")
    }

    const fetchScrapeData = () => {
        let data = {
            cId: id
        }
        dispatch(onFetchScrapeData(data, setScrapeData))
    }

    useEffect(() => {
        if (id) {
            fetchScrapeData()
        }
    }, [])

    return (
        <>
            <TitleBar title="Scrape" />
            <Navbar />
            <section className="siteWrap">
                <div className="Project_Wrap">
                    <div className="container-fulid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="scrape_page_tittle" >
                                    <i className="fa-sharp fa-solid fa-arrow-left" style={{ color: "#fff" }} onClick={handleBack}></i>
                                    <h2>Scrape</h2>
                                </div>
                                <hr style={{ opacity: "1", color: "#e0712c", margin: " 10px 120px" }} />
                            </div>
                        </div>

                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="scrape_main_wrap">
                                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-Data-tab" data-bs-toggle="pill" data-bs-target="#pills-Data" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Scraped Data</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-images-tab" data-bs-toggle="pill" data-bs-target="#pills-images" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Scarped images</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-videos-tab" data-bs-toggle="pill" data-bs-target="#pills-videos" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Scarped videos</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-Reviews-tab" data-bs-toggle="pill" data-bs-target="#pills-Reviews" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Gmaps Reviews</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-Gmaps-images-tab" data-bs-toggle="pill" data-bs-target="#pills-Gmaps-images" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Gmaps images</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-Gmaps-video-tab" data-bs-toggle="pill" data-bs-target="#pills-Gmaps-video" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Gmaps videos</button>
                                        </li>
                                    </ul>

                                    <div class="tab-content" id="pills-tabContent">
                                        <div class="tab-pane fade show active" id="pills-Data" role="tabpanel" aria-labelledby="pills-Data-tab" tabindex="0">
                                            <div className="row">
                                                {
                                                    scrapeData.siteData?.length > 0 ?
                                                        scrapeData.siteData.map((curElem, index) => {
                                                            return (
                                                                <div className="col-md-6">
                                                                    <div className='scrap_data_wrap' >
                                                                        <div className="scrape_wrap_heading">
                                                                            <p>{curElem.title}</p>
                                                                            <p>{curElem.baseURL}</p>
                                                                        </div>
                                                                        <div className='scrape_wrap_data'>

                                                                            <div className="data_wrap">
                                                                                <small className="text-sm italic mt-2 ">Fontfamilies: {curElem.fontFamily}</small>
                                                                                <div className="scrape_content_wrap">
                                                                                    <span>{curElem.answer}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="data_link">
                                                                                <div className="d-grid ">
                                                                                    <button type="button" className="btn" onClick={() => window.open(curElem.baseURL, '_blank')}>Go to link</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="pills-images" role="tabpanel" aria-labelledby="pills-images-tab" tabindex="0">
                                            <div className="row">
                                                {
                                                    scrapeData.siteData[0]?.images?.length > 0 ?
                                                        scrapeData.siteData[0]?.images.map((curElem) => {
                                                            return (
                                                                <div className="col-md-3 p-0">
                                                                    <div className="scrape_image_wrap">
                                                                        <img src={curElem} />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="pills-videos" role="tabpanel" aria-labelledby="pills-videos-tab" tabindex="0">
                                            <div className="row">
                                                {
                                                    scrapeData?.videoSrcs?.length > 0 ?
                                                        scrapeData.videoSrcs.map((curElem) => {
                                                            return (
                                                                <div className="col-md-3 mb-3">
                                                                    <div className="scrape_video_wrap">
                                                                        <video controls src={curElem} ></video>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-Reviews" role="tabpanel" aria-labelledby="pills-Reviews-tab" tabindex="0">
                                            <div className="row">
                                                {
                                                    scrapeData?.gmapsReviews?.length > 0 ?
                                                        scrapeData.gmapsReviews.map((curElem) => {
                                                            return (
                                                                <div className="col-md-12 mb-2">
                                                                    <div className="card" style={{ marginBottom: "20px", border: "none", background: "transparent" }}>
                                                                        <div className="scrape_Reviews_wrap">
                                                                            <div className="card-body" style={{ background: "#e0712c", borderRadius: "10px" }}>
                                                                                <div className='Reviews_head_wrap' >
                                                                                    <h6>{curElem.user}</h6>
                                                                                    <h6>{curElem.date}</h6>
                                                                                </div>
                                                                                <div className='Reviews_data_wrap' >
                                                                                    <p>{curElem.review}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="pills-Gmaps-images" role="tabpanel" aria-labelledby="pills-Gmaps-images-tab" tabindex="0">
                                            <div className="row">
                                                {
                                                    scrapeData?.gmapsPhotos?.length > 0 ?
                                                        scrapeData?.gmapsPhotos.map((curElem) => {
                                                            return (
                                                                <div className="col-md-3 p-0">
                                                                    <div className="scrape_image_wrap">
                                                                        <img src={curElem} />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="pills-Gmaps-video" role="tabpanel" aria-labelledby="pills-Gmaps-video-tab" tabindex="0">
                                            <div className="row">
                                                {
                                                    scrapeData?.gmapsVideos?.length > 0 ?
                                                        scrapeData?.gmapsVideos.map((curElem) => {
                                                            return (
                                                                <div className="col-md-4 mb-4">
                                                                    <div className="scrape_video_wrap">
                                                                        <video controls src={curElem} ></video>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="demoLink" onClick={handleSubmit}>Next</button>
                    </div>
                </div>
            </section >
        </>
    )
}

export default Scrape
